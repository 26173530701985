import { createSlice } from '@reduxjs/toolkit';
import { cryptoColor } from 'common/utils/assets';
import {
  ASSET_CLASSES,
  ASSET_SECURITY_CLASSES,
  Asset,
  AssetClass,
  AssetSecurityClass,
  Crypto,
  Security,
} from 'types/assets';
import { AssetEntitiesState, initialState } from './initialState';
import { cryptoAdapter, securityAdapter } from './adapters';
import {
  fetchAssetDetailFailure,
  fetchAssetDetailRequest,
  fetchAssetDetailSuccess,
  fetchAssets,
  fetchAssetsFailure,
  fetchAssetsSuccess,
  fetchCryptoDetailsSuccess,
  fetchSingleAssetSuccess,
} from './actions';
import { ASSETS_SLICE_NAME } from 'common/const/slices';
import { loadMarketOverviewAssetsSuccess } from '../common/actions';

const upsertOneByCategory = (
  state: AssetEntitiesState,
  assetClass: AssetClass,
  assetSecurityClass: AssetSecurityClass | undefined,
  code: string,
  payload: Partial<Asset>,
) => {
  if (assetClass === ASSET_CLASSES.CRYPTO) {
    const cryptoPayload = payload as Partial<Crypto>;
    const stateCrypto = state.crypto.entities[code];

    // If there is no crypto info in the state we need to return early.
    if (!stateCrypto) return;

    const crypto = { ...stateCrypto, code, ...cryptoPayload };

    cryptoAdapter.upsertOne(state.crypto, crypto);
    return;
  }

  if (!assetSecurityClass) return;

  const securityPayload = payload as Partial<Security>;

  const stateSecurity = state.securities.entities[code];

  const security: Security = {
    categories: [],
    fetchingStatus: { metadata: undefined },
    displayName: '',
    wkn: null,
    isin: null,
    country: null,
    sector: null,
    class: ASSET_CLASSES.SECURITY,
    securityClass: ASSET_SECURITY_CLASSES.STOCK, // All above will be overwritten if present.
    ...stateSecurity,
    code,
    ...securityPayload,
  };

  securityAdapter.upsertOne(state.securities, security);
};

const upsertManyByCategory = (
  state: AssetEntitiesState,
  assetClass: AssetClass,
  assetSecurityClass: AssetSecurityClass | undefined,
  assets: Asset[],
  replaceAll: boolean,
) => {
  if (assetClass === ASSET_CLASSES.CRYPTO) {
    const cryptoWithAdditionalData = assets.map((asset: Asset): Crypto => {
      const prevStateCryptoDetails = cryptoAdapter
        .getSelectors()
        .selectById(state.crypto, asset.code.toLowerCase())?.cryptoDetails;

      // cryptoDetails which are fetched with /assets contain field displayName - data from there is not full, some fields are null, like displayName
      // if prevState had cryptoDetails fetched from /crypto-details - it will contain displayName = string
      // so we use it instead of empty cryptoDetails to not fetch /crypto-data each time we fetch /assets
      const cryptoDetails = prevStateCryptoDetails?.displayName
        ? prevStateCryptoDetails
        : asset?.cryptoDetails;

      return {
        ...asset,
        color: cryptoColor(asset.code.toLowerCase()),
        symbol: asset.code.toUpperCase(),
        pair: `${asset.code.toLowerCase()}eur`,
        cryptoDetails,
      };
    });

    if (replaceAll) {
      cryptoAdapter.setAll(state.crypto, cryptoWithAdditionalData);
    } else {
      cryptoAdapter.upsertMany(state.crypto, cryptoWithAdditionalData);
    }
  }

  if (!assetSecurityClass) return;

  if (replaceAll) {
    securityAdapter.setAll(state.securities, assets);
  } else {
    securityAdapter.upsertMany(state.securities, assets);
  }
};

const slice = createSlice({
  name: ASSETS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleAssetSuccess, (state, { payload }) => {
        if (!payload) return;

        upsertOneByCategory(state, payload.class, payload.securityClass, payload.code, payload);
      })
      .addCase(fetchAssets, (state) => {
        state.areAssetsLoading = true;
      })
      .addCase(fetchAssetsSuccess, (state, { payload }) => {
        state.areAssetsLoading = false;

        upsertManyByCategory(state, payload.class, payload.securityClass, payload.assets, !payload.reference);
      })
      .addCase(fetchAssetsFailure, (state) => {
        state.areAssetsLoading = false;
      })
      .addCase(fetchAssetDetailRequest, (state) => {
        state.isMasterDataLoading = true;
      })
      .addCase(fetchAssetDetailSuccess, (state, { payload }) => {
        state.isMasterDataLoading = false;

        const assetDetail: Partial<Asset> = {
          displayName: payload.detail.name,
          detail: payload.detail,
          detailLastFetched: new Date().getTime(),
        };

        upsertOneByCategory(state, payload.class, payload.securityClass, payload.code, assetDetail);
      })
      .addCase(fetchAssetDetailFailure, (state) => {
        state.isMasterDataLoading = false;
      })
      .addCase(fetchCryptoDetailsSuccess, (state, { payload }) => {
        cryptoAdapter.updateMany(
          state.crypto,
          payload.map(({ code, ...rest }) => ({
            id: code.toLowerCase(),
            changes: { cryptoDetails: { ...rest } },
          })),
        );
      })
      .addCase(loadMarketOverviewAssetsSuccess, (state, { payload }) => {
        const firstElement = payload.assets.length ? payload.assets[0] : undefined;

        if (!firstElement) return;

        const allAssetClassesSame = payload.assets.every(
          (asset: Asset) =>
            asset.class === firstElement.class && asset.securityClass === firstElement.securityClass,
        );

        if (allAssetClassesSame) {
          upsertManyByCategory(state, firstElement.class, firstElement.securityClass, payload.assets, false);
        } else {
          payload.assets.forEach((element) => {
            upsertOneByCategory(state, element.class, element.securityClass, element.code, element);
          });
        }
      });
  },
});

export default slice.reducer;
