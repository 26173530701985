import React, { ReactElement } from 'react';
import ModalTemplate from 'common/components/Modal/index';
import {
  ErrorImg,
  Content,
  ContentWrapper,
  Text,
  ErrorHeaderText,
} from 'common/components/TradingRulesCommonComponents/TradingRulesCreationStyles/errorStyles';

type Props = {
  infoText: string | ReactElement;
  headerText?: string | undefined;
  isOpen: boolean;
  actions: JSX.Element;
};

const StopOrderFailModal = ({ infoText, headerText, isOpen, actions }: Props): ReactElement => (
  <ModalTemplate defaultOpen={isOpen} actions={actions} header={<></>} persist>
    <Content>
      <ContentWrapper>
        <ErrorImg />

        {headerText && <ErrorHeaderText>{headerText}</ErrorHeaderText>}

        <Text className="primary-color-text">{infoText}</Text>
      </ContentWrapper>
    </Content>
  </ModalTemplate>
);

StopOrderFailModal.defaultProps = {
  headerText: undefined,
};

export default StopOrderFailModal;
