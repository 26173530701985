/* eslint-disable @typescript-eslint/no-unsafe-return */
import { createSelector } from 'reselect';
import { MoneyState } from 'store/types/money';

export const getMoneyState = ({ money }: { money: MoneyState }) => money;

const getSelectedAccount = createSelector(getMoneyState, (money) =>
  money.accounts.reduce((acc, account, index) => (account.bankAccount.isPrimary ? index : acc), 0),
);
export const getAllAcounts = createSelector(getMoneyState, (money) => money.accounts);

export const getWithdrawalAccount = createSelector(
  getSelectedAccount,
  getAllAcounts,
  (selectedAccount, accounts) => (accounts.length > 0 ? accounts[selectedAccount] : undefined),
);

export const getDepositAccount = ({ money }: { money: MoneyState }) => money.depositAccount;

export const getIsAnyDepositsMade = ({ money }: { money: MoneyState }): boolean =>
  money.depositAccount?.anyDeposits ?? false;

export const getFetchingDepositInfo = ({ money }: { money: MoneyState }) => money.fetchingDepositInfo;

export const getOrderYears = ({ money }: { money: MoneyState }) => money.infoReport.years;

export const getLastSent = ({ money }: { money: MoneyState }, year: number) => {
  if (year in money.infoReport.lastSent) {
    return money.infoReport.lastSent[year];
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};

export const getCryptoAddresses = ({ money }: { money: MoneyState }) => money.cryptoAddresses;

export const getCryptoDepositInfo = ({ money }: { money: MoneyState }, crypto: string) =>
  money.cryptoAddresses[crypto];

export const getBankStatementAvailableMonths = ({ money }: { money: MoneyState }) =>
  money.bankStatement.availableMonths;

export const getLastTransactionHistorySent = ({ money }: { money: MoneyState }, year: number) => {
  if (year in money.transactionHistory.lastSent) {
    return money.transactionHistory.lastSent[year];
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday;
};
