import { Option } from 'types/moneyTransfer';
import { IntentResponse } from 'types/cryptoDepositTravelRule';

export const DEPOSIT_WARNING_REQUIRED_CRYPTOS = ['eth', 'pol'];

export const DEPOSIT_IMPORTANT_TEXT_EXCLUSIONS = ['eth', 'xrp'];

export const CentralizedOrSelfHostedOptions: Option[] = [
  {
    id: 'centralized',
    text: 'typeOfWallet.options.exchange',
    isSelfHostedWallet: false,
  },
  {
    id: 'selfHosted',
    text: 'typeOfWallet.options.selfHosted',
    isSelfHostedWallet: true,
  },
];

export const INITIAL_CRYPTO_WITHDRAW_DATA = {
  currency: '',
  volume: '0',
  walletAddress: '',
  walletAddressTag: null,
  password: '',
  type: '',
};
export const CRYPTOS_TO_SHOW_INFO_ON_PASSWORD_STEP = ['eth'];

export const CRYPTOS_TO_SHOW_INFO_ON_AMOUNT_STEP = ['pol'];

export const INITIAL_CRYPTO_DEPOSIT_TRAVEL_RULE_DATA: Partial<IntentResponse> = {
  assetCode: '',
  volume: 0,
  address: '',
  tag: undefined,
};

export const DEPOSIT_INTENT_ROUTE = '/crypto/deposit/intent';
export const WITHDRAW_INTENT_ROUTE = '/crypto/withdraw/intent';
export enum IntentType {
  DEPOSIT,
  WITHDRAW,
}

export const FAQ_LINK_ROUTE = 'sections/23152209034909-Transfer-of-Funds-Regulation';
