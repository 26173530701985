/* eslint-disable @typescript-eslint/no-unsafe-return */

import { RootEpic } from 'types/common';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { isOfType, isPresent } from 'safetypings';
import {
  CANCEL_TOP_UP,
  cancelTopUpFailure,
  cancelTopUpSuccess,
  CONFIRM_TOP_UP_REQUEST,
  confirmTopUpSuccess,
  DELETE_LAST_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD_SUCCESS,
  deletePaymentMethod,
  deletePaymentMethodFailure,
  deletePaymentMethodSuccess,
  FETCH_PAYMENT_METHODS,
  FETCH_TOP_UP_LIMITS,
  fetchPaymentMethods,
  fetchPaymentMethodsFailure,
  fetchPaymentMethodsSuccess,
  fetchTopUpLimits,
  fetchTopUpLimitsFailure,
  fetchTopUpLimitsSuccess,
  INIT_TOP_UP_REQUEST,
  INIT_TOP_UP_REQUEST_FAILURE,
  initTopUpRequestFailure,
  initTopUpRequestSuccess,
  setTopUpsFeature,
  TOP_UP_FAILURE,
  TOP_UP_SUCCESS,
} from 'store/actions/topUps';
import { AUTH, AUTO_LOGIN } from 'store/actions/auth';
import { getAccessToken, getUserRoles } from 'store/selectors/auth';
import { InitTopUpResponse, PaymentMethodType, TopUpLimits, TopUpType } from 'types/topUps';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { handleAjaxError } from 'store/epics/auth';
import { from, Observable, of } from 'rxjs';
import { getDeviceData } from 'common/utils/seonSdk';
import I18n from 'i18next';
import { getIsFeatureTopUpsEnabled, getSavedPaymentMethods } from 'store/selectors/topUps';
import { getFeatureTopUpsEnabled } from 'store/selectors/environment';
import { API_USER_ROLES } from 'common/const/user';
import { AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import { invalidatePortfolio } from 'store/slices/portfolio/actions';
import { loadInitialActivityItems } from 'store/slices/activity/actions';
import { invalidateTopUpData } from 'store/actions/forms';
import { getIsEligibleForTopUps } from 'store/selectors/settings';

const setTopUpsFeatureEpic: RootEpic = (action$: Observable<AnyAction>, state$) =>
  state$.pipe(
    map((state) => ({
      isEnabledInConfigEnv: getFeatureTopUpsEnabled(state),
      isBetaUser: getUserRoles(state)?.includes(API_USER_ROLES.BETA_USER),
      isEligible: getIsEligibleForTopUps(state),
    })),
    distinctUntilChanged((prev, curr) => {
      return (
        prev.isEnabledInConfigEnv === curr.isEnabledInConfigEnv &&
        prev.isBetaUser === curr.isBetaUser &&
        prev.isEligible === curr.isEligible
      );
    }),
    switchMap(({ isEnabledInConfigEnv, isBetaUser, isEligible }) => {
      const enableFeature =
        isEnabledInConfigEnv === true || (isEnabledInConfigEnv === 'BETA_ONLY' && isBetaUser);

      return of(
        setTopUpsFeature({
          enable: enableFeature && isEligible,
        }),
      );
    }),
  );

const initTopUpRequestEpic: RootEpic = (action$: Observable<AnyAction>, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(INIT_TOP_UP_REQUEST)),
    pluck('payload'),
    switchMap(({ request, onSuccess, retry }) =>
      from(getDeviceData()).pipe(
        switchMap(({ deviceData }) =>
          state$.pipe(
            map(getAccessToken),
            filter(isPresent),
            take(1),
            switchMap((accessToken) =>
              ajax({
                method: 'POST',
                url: api.getBaseUrlForTrading(accessToken).concat('/top-ups'),
                withCredentials: true,
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
                body: { ...request, deviceData },
              }).pipe(
                pluck('response'),
                mergeMap((response: InitTopUpResponse) => {
                  if (onSuccess) onSuccess(response);

                  if (retry)
                    return of(
                      notificationBannerActions.notifySuccess({
                        message: I18n.t('common.twofa.resentCode'),
                      }),
                      initTopUpRequestSuccess(response),
                    );

                  return of(initTopUpRequestSuccess(response));
                }),
              ),
            ),
            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(
              handleAjaxError(
                action$,
                initTopUpRequestFailure,
                {},
                { message: I18n.t('error.genericNetwork') },
              ),
            ),
          ),
        ),
      ),
    ),
  );

const confirmTopUpRequestEpic: RootEpic = (action$, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(CONFIRM_TOP_UP_REQUEST)),
    pluck('payload'),
    switchMap(({ request, onSuccess, onFail }) =>
      from(getDeviceData()).pipe(
        switchMap(({ deviceData }) =>
          state$.pipe(
            map(getAccessToken),
            filter(isPresent),
            take(1),
            switchMap((accessToken) =>
              ajax({
                method: 'PATCH',
                url: api.getBaseUrlForTrading(accessToken).concat('/top-ups'),
                withCredentials: true,
                headers: {
                  ...api.getCommonHeaders(),
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
                body: { ...request, deviceData },
              }).pipe(
                pluck('response'),
                map((response: TopUpType) => {
                  if (onSuccess) onSuccess(response);
                  return confirmTopUpSuccess(response);
                }),
              ),
            ),
            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError(handleAjaxError(action$, initTopUpRequestFailure)),
            tap(({ type }) => {
              if (type === INIT_TOP_UP_REQUEST_FAILURE && onFail) {
                onFail();
              }
            }),
          ),
        ),
      ),
    ),
  );

const cancelTopUpEpic: RootEpic = (action$: Observable<AnyAction>, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(CANCEL_TOP_UP)),
    switchMap(({ payload }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            method: 'POST',
            url: api.getBaseUrlForTrading(accessToken).concat('/top-ups/cancel'),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: payload,
          }).pipe(mapTo(cancelTopUpSuccess())),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, cancelTopUpFailure)),
      ),
    ),
  );

const deletePaymentMethodsEpic: RootEpic = (action$: Observable<AnyAction>, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType(DELETE_PAYMENT_METHOD)),
    switchMap(({ payload }) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            method: 'DELETE',
            url: api.getBaseUrlForTrading(accessToken).concat('/top-ups/payment-methods'),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
            body: { paymentMethodId: payload.id },
          }).pipe(mapTo(deletePaymentMethodSuccess())),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, deletePaymentMethodFailure)),
      ),
    ),
  );

const fetchPaymentMethodsEpic: RootEpic = (action$: Observable<AnyAction>, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType([FETCH_PAYMENT_METHODS, DELETE_PAYMENT_METHOD_SUCCESS])),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForTrading(accessToken).concat('/top-ups/payment-methods'),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response: PaymentMethodType[]) => {
              return fetchPaymentMethodsSuccess(response);
            }),
          ),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, fetchPaymentMethodsFailure)),
      ),
    ),
  );

const fetchTopUpLimitsEpic: RootEpic = (action$: Observable<AnyAction>, state$, { ajax, api }) =>
  action$.pipe(
    filter(isOfType([FETCH_TOP_UP_LIMITS])),
    switchMap(() =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          ajax({
            url: api.getBaseUrlForTrading(accessToken).concat('/top-ups/limits'),
            withCredentials: true,
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            map((response: TopUpLimits) => fetchTopUpLimitsSuccess(response)),
          ),
        ),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, fetchTopUpLimitsFailure)),
      ),
    ),
  );

const deleteLastPaymentMethodsEpic: RootEpic = (action$: Observable<AnyAction>, state$) =>
  action$.pipe(
    filter(isOfType(DELETE_LAST_PAYMENT_METHOD)),
    switchMap(() =>
      state$.pipe(
        map(getSavedPaymentMethods),
        take(1),
        switchMap((paymentMethods) => {
          return of(deletePaymentMethod({ id: paymentMethods[0]?.paymentMethodId ?? '0' }));
        }),
      ),
    ),
  );

// TODO: We probably don't need to fetch fetchTopUpLimits and fetchPaymentMethods after login but specifically when needed
const watchPortfolio: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(isOfType([AUTH.SUCCESS, AUTO_LOGIN])),
    switchMap(() =>
      state$.pipe(
        map(getIsFeatureTopUpsEnabled),
        filter((isFeatureTopUpsEnabled) => isFeatureTopUpsEnabled),
        take(1),
        switchMap(() => of(fetchTopUpLimits(), fetchPaymentMethods())),
      ),
    ),
  );

const watchTopUpSuccess: RootEpic = (action$) =>
  action$.pipe(
    filter(isOfType([TOP_UP_SUCCESS])),
    switchMap(() =>
      of(invalidatePortfolio(), loadInitialActivityItems(), fetchTopUpLimits(), fetchPaymentMethods()),
    ),
  );

const watchTopUpStatus: RootEpic = (action$) =>
  action$.pipe(
    filter(isOfType([TOP_UP_SUCCESS, TOP_UP_FAILURE])),
    mergeMap(() => of(invalidateTopUpData())),
  );

export default combineEpics(
  setTopUpsFeatureEpic,
  initTopUpRequestEpic,
  confirmTopUpRequestEpic,
  cancelTopUpEpic,
  deletePaymentMethodsEpic,
  fetchPaymentMethodsEpic,
  fetchTopUpLimitsEpic,
  deleteLastPaymentMethodsEpic,
  watchPortfolio,
  watchTopUpSuccess,
  watchTopUpStatus,
);
