import { ActionType } from 'safetypings';
import * as assetsActions from 'store/actions/assets';
import { ValuesType } from 'utility-types';
import { Dictionary } from '@reduxjs/toolkit';
import { TimePeriod } from './currency';

export type AssetsAction = ActionType<typeof assetsActions>;
// import { ChartValue } from './charts';
// import { PortfolioHolding } from './portfolio';

export const ASSET_CLASSES = {
  CURRENCY: 'Currency',
  CRYPTO: 'Crypto',
  SECURITY: 'Security',
} as const;

export type AssetClass = ValuesType<typeof ASSET_CLASSES>;

export const ASSET_SECURITY_CLASSES = {
  STOCK: 'Stock',
  ETF: 'Etf',
  ETC: 'Etc',
  ETN: 'Etn',
} as const;

export type AssetSecurityClass = ValuesType<typeof ASSET_SECURITY_CLASSES>;

export const ASSET_TAGS = {
  CRYPTO: 'crypto',
  STOCK: 'stock',
  ETF: 'etf',
  COMMODITY: 'commodity',
  LONG_SHORT: 'long/short',
} as const;

export type AssetTag = ValuesType<typeof ASSET_TAGS>;

export const ASSET_CATEGORY = {
  COMMODITY: 'Commodity',
  METAL: 'Metal',
  LONG_SHORT: 'Long/Short',
} as const;

export type AssetCategory = ValuesType<typeof ASSET_CATEGORY>;

export type AssetReference = {
  code: string;
  value: number;
};

export type CryptoDetails = {
  minWithdrawalAmount: number;
  decimalPlaces: number;
  isDepositEnabled: boolean;
  isWithdrawEnabled: boolean;
};

export type AssetChartType<T> = {
  [timePeriod in TimePeriod]?: {
    items: T[];
    isFetching: boolean;
    lastUpdated: number;
    validTill: string;
  };
};

// export type AssetChart = AssetChartType<ChartValue>;
// export type AssetSmallChart = AssetChartType<ChartValue>;
export type AssetChart = any;
export type AssetSmallChart = {
  [key: string]: number;
  x: number;
  y: number;
}[];

export interface AssetChartApiValue {
  x: number;
  y: number;
  epoch: number;
}

export interface AssetFetchingStatus {
  hasData: boolean;
  isFetching: boolean;
  lastFetch: number;
}

export type AssetClassInfo = {
  class: AssetClass;
  /**
   * This property is used only for securities.
   * If the `class` property is not of type `Security` this property will be null.
   * @type {AssetSecurityClass | null}
   */
  securityClass: AssetSecurityClass | undefined;
};

export type AssetDetail = {
  name: string;
  shortName: string;
  isin: string;
  assetType: AssetClass;
  wkn: string;
  firstPriceOfTheDay: number;
  lastPriceOfPreviousTradingDay: number;
  priceEarningRatio: number;
  dividendYield: number;
  headOfficeCountry: string;
  dividendPerShare: number | null;
  sector: string;
  marketCapitalisation: number;
  earningsByYear: {
    additionalProp1: number;
    additionalProp2: number;
    additionalProp3: number;
  };
  ebitdaByYear: {
    additionalProp1: number;
    additionalProp2: number;
    additionalProp3: number;
  };
  ebitByYear: {
    additionalProp1: number;
    additionalProp2: number;
    additionalProp3: number;
  };
  detailedCompanyProfile: string;
  totalExpenseRatio: number;
  etpClass: string;
  issuerName: string;
  typeAppropriationEarnings: string;
  kidLink: string;
};

export type AssetPrice = {
  ask: number;
  mid: number;
  bid: number;
};

export interface PriceDatapoint {
  count: number;
  price: number;
  time: string;
}

export interface DailyPriceChange {
  newestPrice: PriceDatapoint;
  previousPrice: PriceDatapoint;
  period: string;
  timestamp: string;
  validTill: string;
  percentChange: number;
  valueChange: number;
}

type AssetBase = {
  code: string;
  // This is not used anymore. Remove after a while.
  // category: AssetCategory;
  sector: string | null;
  country: string | null;
  isin: string | null;
  wkn: string | null;
  displayName: string;
  fetchingStatus: {
    metadata?: AssetFetchingStatus;
  };
  reference?: AssetReference;
  detail?: AssetDetail;
  detailLastFetched?: number;
  lastPrice?: AssetPrice;
  dailyPriceChange?: DailyPriceChange;
  smallChart?: AssetSmallChart;
  chart?: AssetChart;
  // holdings?: PortfolioHolding;
  holdings?: any;
  lastFetchLastPrice?: number;
  lastFetchDailyPriceChange?: number;
  categories: AssetCategory[];
  isDelisted?: boolean; // TODO: check if this is compatible with BE prop in the future
} & AssetClassInfo;

export type Crypto = {
  cryptoDetails?: CryptoDetails;
  color?: string;
  symbol?: string;
  pair?: string;
} & AssetBase;

export type Security = AssetBase;

export type Asset = Crypto | Security;

export type CryptoAssets = Record<string, Crypto>;

export type AssetReturnType<T> = T extends 'Crypto'
  ? Crypto | undefined
  : T extends 'Security'
    ? Security | undefined
    : undefined;

export type AssetsReturnType<T> = T extends 'Crypto'
  ? Crypto[]
  : T extends 'Security'
    ? Security[]
    : undefined;

export type AssetDictionaryReturnType<T> = T extends 'Crypto'
  ? Dictionary<Crypto>
  : T extends 'Security'
    ? Dictionary<Security>
    : undefined;

export const ASSET_ORDER_TYPES = {
  MOST_TRADED: 'MostTraded',
  HIGHEST_TRADE_VOLUME: 'HighestTradeVolume',
  WINNER: 'Winner',
  LOSER: 'Loser',
};

export type MarketOverviewOrderBy = 'MostTraded' | 'HighestTradeVolume' | 'Winner' | 'Loser';

export type AssetFilter = 'assetCategory' | 'industry' | 'country' | 'index' | 'issuers';
export type AssetFilterDrilldownCategory = Exclude<AssetFilter, 'assetCategory'>;

export type MarketOverviewFilterType = {
  assetTag: AssetTag;
  searchText: string;
  order: MarketOverviewOrderBy;
  searchAssetCodes: string[];
  // Only user visible assets are stored here. Used for optimized price, price change and price history fetching.
  visibleAssetCodes: string[];
  reference: number | undefined;
};

export interface AssetListEntity {
  category: AssetClass;
  code: string;
  displayName: string;
}

export interface CostTransparencyFromApi {
  sumCost: number;
  sumCostRv: number;
  relativeSumCost: number;
  relativeSumCostRv: number;
  externalBuy: number;
  externalPa: number;
  externalSell: number;
  financialContributionBuy: number;
  financialContributionSell: number;
  financialContributionPa: number;
  asset: string;
  isin: string;
  completeAmount: number;
  transactionDate: string;
  marketMaker: string;
  relativeSumBuy: number;
  relativeSumPa: number;
  relativeSumSell: number;
  externalBuyRv: number;
  externalPaRv: number;
  externalSellRv: number;
  relativeSumBuyRv: number;
  relativeSumPaRv: number;
  relativeSumSellRv: number;
  nominal: number;
  orderProvisionBuy: number;
  orderProvisionSell: number;
  orderDiscountBuy: number;
  orderDiscountSell: number;
  orderRefundBuy: number;
  orderRefundSell: number;
  platformContribution: number;
  partnerName: string;
  feeBuy: number;
  feeSell: number;
}

export interface CostTransparency {
  completePrice: number;
  externalSellRv: number;
  externalBuyRv: number;
  relativeSumPaRv: number;
  relativeSumBuyRv: number;
  externalSell: number;
  externalPa: number;
  externalBuy: number;
  relativeSumCostRv: number;
  sumCostRv: number;
  price: number;
  provider: string;
  buyAmount: number;
  buyExternalFee: number;
  buyThirdPartyFee: number;
  yearlyAmount: number;
  yearlyExternalFee: number;
  yearlyThirdPartyFee: number;
  sellAmount: number;
  sellExternalFee: number;
  sellThirdPartyFee: number;
  totalCostFirstYear: number;
  totalCostFirstYearPercent: number;
  totalCostPerYear: number;
  totalCostPerYearPercent: number;
  totalCostSellingYear: number;
  totalCostSellingYearPercent: number;
  feeBuy: number;
  feeSell: number;
  orderDiscountBuy: number;
  orderDiscountSell: number;
  orderRefundBuy: number;
  orderRefundSell: number;
}

export interface CostTransparencyPayload {
  isin: string;
  quantity: number;
  price: number;
  type: 'buy' | 'sell';
}

export interface MarketOverviewAsset {
  name: string;
  code: string;
  assetClass: AssetClass;
  assetSecurityClass: AssetSecurityClass | undefined;
  chartData: any[];
  priceChange: number;
  priceChangePercentage: number;
  price: number;
}
