/* eslint-disable */
import { v4 as uuid } from 'uuid';
import { loginRetryDelays, errorTooManyRetriesMessageCodes, BETA_DOMAINS } from '../const/auth';
import * as keychain from 'services/auth';
import { differenceInMinutes } from 'date-fns';

export const getClientId = (): string => {
  const { error, response } = keychain.loadDeviceId();
  if (error) {
    // No device id assigned yet, generate and store it
    const deviceId = uuid() as string;
    keychain.storeAuthTokens({
      deviceId,
    });

    return deviceId;
  } else {
    return response?.deviceId ?? '';
  }
};

export const setWindowParams = (key: string, value: any): void => (window[key] = value);

/**
 * Determines the delay time before the next login retry attempt based on the number of previous attempts.
 *
 * @param {number} reps - The number of previous retry attempts.
 * @returns {number} The delay time in milliseconds.
 */
export const getLoginRetryDelayTime = (reps: number) => {
  // in loginRetryDelays find the value of reps that
  const delay = Object.values(loginRetryDelays).find((item) => item.maxReps && item.maxReps >= reps)?.delay;
  return delay || loginRetryDelays.max.delay;
};

export const getTooManyRetriesMessageCode = (blockedUntil: string): string => {
  const blockedUntilDate = new Date(blockedUntil);
  const now = new Date();

  if (differenceInMinutes(blockedUntilDate, now) <= 1) {
    return errorTooManyRetriesMessageCodes.smallBlock;
  }

  if (differenceInMinutes(blockedUntilDate, now) <= 10) {
    return errorTooManyRetriesMessageCodes.mediumBlock;
  }

  return errorTooManyRetriesMessageCodes.bigBlock;
};

export const isBetaUser = (username) =>
  BETA_DOMAINS.some((domain) => new RegExp(`@${domain}$`).test(username));
