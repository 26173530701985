import styled from 'styled-components';
import { Theme } from 'types/theme';

export const Layout = styled.div`
  margin: 0 auto;
  max-width: 502px;
  padding: 44px 32px;
  margin-top: 32px;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.secondary};
  position: relative;
`;

export const Headline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`;

export const Title = styled.h3.attrs({ className: 'strong-weight' })``;

export const CheckboxTextWrapper = styled.div.attrs({ className: 'small-text' })`
  white-space: pre-line;
  text-align: left;

  ${({ theme, error }: { theme: Theme; error: boolean }) =>
    error &&
    `
      color: ${theme.color.foreground.critical} !important;
      & > * {
        color: ${theme.color.foreground.critical} !important;
      }
  `}
`;

export const MainText = styled.div.attrs({ className: 'small-text' })`
  white-space: pre-line;
  padding-top: 22px;
`;
