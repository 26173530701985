/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { numberFromDecimalString } from 'common/utils/formatting';
import { useModalProvider } from 'providers/Modal/useModalProvider';
import StakingIntroModal from 'common/components/Modal/Staking/StakingIntroModal';
import { DEFAULT_MODAL_PROPS } from 'common/const/modals';
import StakingFinishModal from 'common/components/Modal/Staking/StakingFinishModal';
import { paths } from 'common/urls';
import StakingConfirmModal from 'common/components/Modal/Staking/StakingConfirmModal';
import { stakeOrUnstakeOrderTypes } from 'common/const';
import UnstakingConfirmModal from 'common/components/Modal/UnstakingConfirmModal';
import UnstakingFinishModal from 'common/components/Modal/UnstakingFinishModal';
import { getIsFeatureSpecificTncNotAccepted } from 'store/selectors/termsAndConditions';
import { CONSENT_TYPES } from 'common/const/consent';
import { parseISOUTCStringToUTC } from 'common/utils/date';
import { useAsset } from 'common/hooks/useAsset';
import {
  getStakeUnstakeState,
  selectStakingInfoAllCryptos,
  selectIsStakingInfoLoading,
  getStakingNextRewardInfoForAllCryptos,
} from 'store/slices/staking/selectors';
import {
  resetStakeUnstakeState,
  stakeUnstakeFlowRequest,
  stakingInfoRequest,
  stakingNextRewardRequest,
} from 'store/slices/staking/actions';
import { extractStakingOverviewItem } from 'scenes/StakingOverview/helpers';
import { getAsset } from 'store/slices/assets/selectors';
import { getAvailableCrypto } from 'store/slices/portfolio/selectors';
import { ASSET_CLASSES } from 'types/assets';
import { ParsedStakingOverviewItem, StakeOrUnstakeOrderType } from 'types/staking';
import { State } from 'store/types/store';

type Props = {
  assetCode: string;
  type: StakeOrUnstakeOrderType;
  setPageTitleCallback?: (title: string) => void;
  formSubmitCallback?: (props?: any) => void;
  confirmCallback?: (props?: any) => void;
};

const useStakingFlow = ({
  assetCode,
  type,
  formSubmitCallback,
  confirmCallback,
  setPageTitleCallback,
}: Props) => {
  const { t } = useTranslation();
  const asset = useAsset(assetCode, ASSET_CLASSES.CRYPTO, undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalProvider = useModalProvider();
  const cryptoAsset = useSelector((state: State) => getAsset(state, ASSET_CLASSES.CRYPTO, assetCode));
  const stakingInfo = useSelector(selectStakingInfoAllCryptos);
  const isStakingInfoLoading = useSelector(selectIsStakingInfoLoading);
  const cryptoInstruments = useSelector(getAvailableCrypto);
  const { isLoading: isStakeUnstakeLoading, isSuccess: isStakeUnstakeSuccess } =
    useSelector(getStakeUnstakeState);
  const isStakingFeatureSpecificTncNotAccepted = useSelector((state: any) =>
    getIsFeatureSpecificTncNotAccepted(state, CONSENT_TYPES.ACTIVE_STAKING),
  );
  const nextRewardForAllCryptos =
    type === stakeOrUnstakeOrderTypes.Stake ? useSelector(getStakingNextRewardInfoForAllCryptos) : null;

  const crypto = cryptoInstruments.find((item) => item.entity.toLowerCase() === assetCode);

  const [amount, setAmount] = useState<string>('');

  const parsedStakingInfo: ParsedStakingOverviewItem | undefined =
    crypto &&
    extractStakingOverviewItem(
      crypto,
      cryptoAsset?.cryptoDetails?.decimalPlaces,
      stakingInfo[crypto.entity.toLowerCase()],
    );

  const additionalSummaryData = [];
  if (nextRewardForAllCryptos && nextRewardForAllCryptos[assetCode]?.date) {
    additionalSummaryData.push({
      label: t('staking.rewardDate'),
      value: format(
        parseISOUTCStringToUTC(nextRewardForAllCryptos && nextRewardForAllCryptos[assetCode]?.date),
        t('l10n.dateFormat'),
      ),
    });
  }

  useEffect(() => {
    dispatch(stakingInfoRequest());
    dispatch(stakingNextRewardRequest({ assetCode }));

    return () => {
      // reset stakeUnstake state on component unmount
      dispatch(resetStakeUnstakeState());
    };
  }, []);

  useEffect(() => {
    if (type !== stakeOrUnstakeOrderTypes.Stake) return;

    if (isStakingFeatureSpecificTncNotAccepted) return;

    modalProvider.addModal({
      component: <StakingIntroModal assetCode={assetCode} {...DEFAULT_MODAL_PROPS} />,
    });
  }, [isStakingFeatureSpecificTncNotAccepted]);

  const onConfirm = () => {
    dispatch(stakeUnstakeFlowRequest({ type, assetCode, volume: numberFromDecimalString(amount) }));

    if (confirmCallback) confirmCallback();
  };

  const onProceed = () => {
    if (amount) {
      switch (type) {
        case stakeOrUnstakeOrderTypes.Stake:
          modalProvider.addModal({
            component: (
              <StakingConfirmModal
                onConfirm={onConfirm}
                assetCode={assetCode}
                amount={amount}
                {...DEFAULT_MODAL_PROPS}
              />
            ),
          });
          break;
        case stakeOrUnstakeOrderTypes.Unstake:
          modalProvider.addModal({
            component: (
              <UnstakingConfirmModal
                entity={assetCode}
                onConfirm={onConfirm}
                amount={amount}
                displayName={asset?.displayName ?? ''}
                {...DEFAULT_MODAL_PROPS}
              />
            ),
          });
          break;
        default:
          return;
      }
    }

    if (formSubmitCallback) formSubmitCallback();
  };

  useEffect(() => {
    if (isStakeUnstakeLoading === false && isStakeUnstakeSuccess !== undefined) {
      switch (type) {
        case stakeOrUnstakeOrderTypes.Stake:
          modalProvider.addModal({
            component: (
              <StakingFinishModal
                onProceed={() => navigate(paths.STAKING)}
                assetCode={assetCode}
                amount={amount}
                {...DEFAULT_MODAL_PROPS}
              />
            ),
          });
          break;
        case stakeOrUnstakeOrderTypes.Unstake:
          modalProvider.addModal({
            component: (
              <UnstakingFinishModal
                assetCode={assetCode}
                assetColor={asset?.color ?? ''}
                amount={amount}
                onProceed={() => navigate(paths.STAKING)}
                error={!isStakeUnstakeSuccess}
                {...DEFAULT_MODAL_PROPS}
              />
            ),
          });
          break;
        default:
          break;
      }
    }
  }, [isStakeUnstakeLoading, isStakeUnstakeSuccess]);

  useEffect(() => {
    if (setPageTitleCallback) {
      setTimeout(() => {
        setPageTitleCallback(t('mainScreenTitles.staking'));
      }, 0);
    }
  }, []);

  return {
    asset,
    amount,
    setAmount,
    parsedStakingInfo,
    onProceed,
    isStakingLoading: isStakeUnstakeLoading || isStakingInfoLoading,
    additionalSummaryData,
  };
};

export default useStakingFlow;
