/* eslint-disable @typescript-eslint/no-unsafe-return */
import { getIsUserInPaperTrading, getKycStatus } from 'store/selectors/auth';
import { BLOCKED_FEATURES_VALUES, kycStatuses } from 'common/const';
import { State } from 'store/types/store';
import { TermsAndConditionsData, TermsAndConditionsDataType } from 'types/termsAndConditions';
import { $Values } from 'utility-types';
import { ConsentNameType, ConsentPayloadType } from 'types/consent';
import { createSelector } from 'reselect';

// CRYPTO
export const getIsCryptoTermsAndConditionsDataInitiallyLoaded = (state: State): boolean =>
  state.termsAndConditions.crypto.isDataInitiallyLoaded;

export const getCryptoTermsAndConditionsData = (state: State): TermsAndConditionsDataType =>
  state.termsAndConditions.crypto.data;

export const getIsCryptoTermsAndConditionsClickedAway: (state: State) => boolean = (state) =>
  state.termsAndConditions.crypto.isTermsAndConditionsClickedAway;

export const getIsCryptoTermsAccepted = (state: State): boolean =>
  state.termsAndConditions.crypto.data.hasAcceptedLatestTerms;

export const getCryptoTermsAndConditionsBlockedFeatures = (state: State): string[] =>
  state.termsAndConditions.crypto.data.blockedFeatures || [];

export const getIsCryptoTradingDisabledWithoutAcceptingTandC = (state: State): boolean => {
  const isFeatureBlocked = (state.termsAndConditions.crypto.data.blockedFeatures || []).includes(
    BLOCKED_FEATURES_VALUES.TRADING,
  );

  return (
    !state.termsAndConditions.crypto.data.hasAcceptedLatestTerms &&
    isFeatureBlocked &&
    !getIsUserInPaperTrading(state)
  );
};

export const getIsCryptoFeatureDisabledWithoutAcceptingTandC =
  (state: State) =>
  (feature: $Values<typeof BLOCKED_FEATURES_VALUES>): boolean => {
    const isFeatureBlocked = (state.termsAndConditions.crypto.data.blockedFeatures || []).includes(feature);
    const isTradingFeatureBlocked = (state.termsAndConditions.crypto.data.blockedFeatures || []).includes(
      BLOCKED_FEATURES_VALUES.TRADING,
    );

    return (
      !state.termsAndConditions.crypto.data.hasAcceptedLatestTerms &&
      (isFeatureBlocked || isTradingFeatureBlocked) &&
      !getIsUserInPaperTrading(state)
    );
  };

export const getCryptoTermsAndConditionsFailedAttempts = (state: State) =>
  state.termsAndConditions.crypto.failedAttempts;

// STOCKS

export const getIsStocksTermsAndConditionsDataInitiallyLoaded = (state: State): boolean =>
  state.termsAndConditions.stocks.isDataInitiallyLoaded;

export const getStocksTermsAndConditionsData = (state: State): TermsAndConditionsData =>
  state.termsAndConditions.stocks.data;

export const getIsStocksTermsAccepted = (state: State): boolean =>
  state.termsAndConditions.stocks.data.hasAcceptedLatestTerms;

export const getIsStocksTermsAndConditionsClickedAway: (state: State) => boolean = (state) =>
  state.termsAndConditions.stocks.isTermsAndConditionsClickedAway;

export const getIsStocksTradingDisabledWithoutAcceptingTandC = (state: State): boolean => {
  const isFeatureBlocked = (state.termsAndConditions.stocks.data.blockedFeatures || []).includes(
    BLOCKED_FEATURES_VALUES.TRADING,
  );

  return (
    !state.termsAndConditions.crypto.data.hasAcceptedLatestTerms &&
    isFeatureBlocked &&
    !getIsUserInPaperTrading(state)
  );
};

// FEATURE SPECIFIC TnCs

export const getIsFeatureTermsAndConditionsShown = (state: State, consentName: ConsentNameType): boolean =>
  state.termsAndConditions.featureSpecificConsents[consentName]?.isPopupShown ?? false;

export const getIsFeatureSpecificTncNotAccepted = (state: State, consentName: ConsentNameType): boolean => {
  if (
    !state.termsAndConditions?.featureSpecificConsents[consentName]?.consent ||
    !state.termsAndConditions?.featureSpecificConsents[consentName]?.isDataInitiallyLoaded
  )
    return false;

  return (
    !state.termsAndConditions.featureSpecificConsents[consentName]?.consent.consentState ||
    state.termsAndConditions.featureSpecificConsents[consentName]?.consent.consentState !== 'Accepted'
  );
};

export const getIsFeatureSpecificTncInitiallyLoaded = (state: State, consentName: ConsentNameType): boolean =>
  state.termsAndConditions?.featureSpecificConsents[consentName]?.isDataInitiallyLoaded;

export const getFeatureSpecificTncConsent = (
  state: State,
  consentName: ConsentNameType,
): ConsentPayloadType | Record<string, never> | undefined =>
  state.termsAndConditions.featureSpecificConsents[consentName]?.consent;

export const getFeatureSpecificTncConsentFailedAttempts = (
  state: State,
  consentName: ConsentNameType,
): number => state.termsAndConditions.featureSpecificConsents[consentName]?.failedAttempts;

export const getBankMigrationTermsAndConditionsIsLoading = (state: State): boolean =>
  state.termsAndConditions.bankMigration.isLoading;

export const getBankMigrationTermsAndConditionsHasAcceptedLatestTerms = (state: State): boolean =>
  state.termsAndConditions.bankMigration.hasAcceptedLatestTerms;

export const getBankMigrationTermsAndConditionsIsSubmitting = (state: State): boolean =>
  state.termsAndConditions.bankMigration.isSubmitting;

export const getBankMigrationTermsAndConditionsIsInitiallyLoaded = (state: State): boolean =>
  state.termsAndConditions.bankMigration.isInitiallyLoaded;
