/* eslint-disable @typescript-eslint/no-unsafe-return */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import storageSession from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {
  actions as remotePersistActions,
  remotePersistInternalReducer,
  remotePersistReducer,
} from 'redux-remote-persist';

import authReducer from 'store/reducers/auth';
import forms from 'store/reducers/forms';
import currency from 'store/reducers/currency';
import orders from 'store/reducers/orders';
import misc from 'store/reducers/misc';
import navigation from 'store/reducers/navigation';
import notification from 'store/reducers/notification';
import money from 'store/reducers/money';
import inboxSlice from 'store/slices/inbox';
import stakingSlice from 'store/slices/staking';
import filtersSlice from 'store/slices/filters/reducer';
import config from 'store/reducers/config';
import configFeature from 'store/reducers/configFeature';
import deviceMonitoring from './reducers/deviceMonitoring';
import settings from 'store/reducers/settings';
import legalAgreements from 'store/reducers/legalAgreements';
import statusReducer from 'store/reducers/status';
import localSettings from './reducers/localSettings';
import tracking from './reducers/tracking';
import trackingConfig from './reducers/trackingConfig';
import termsAndConditions, { featureSpecificConsentState } from './reducers/termsAndConditions';
import assets from './reducers/assets';
import environment from './reducers/environment';
import stockEtfOrder from './reducers/stockEtfOrder';
import stockRaffleReducer from './reducers/stockRaffle';
import topUps from './reducers/topUps';
import { State } from './types/store';
import activitySlice from './slices/activity';
import experimentsReducer from './slices/experiments';
import experimentsRemoteDataReducer from './slices/experiments/remoteData';
import marketOverviewReducer from './slices/marketOverview/reducer';
import pricesReducer from './slices/prices/reducer';
import priceChangesReducer from './slices/priceChanges/reducer';
import miniPriceHistoryReducer from './slices/priceHistorySmallChart/reducer';
import priceHistoryReducer from './slices/priceHistory/reducer';
import portfolioReducer from './slices/portfolio/reducer';
import portfolioChartReducer from './slices/portfolioChart/reducer';
import taxExemptionReducer from './slices/taxExemption/reducer';
import devicesReducer from './slices/devices/reducer';
import lossTaxExemptionsReducer from './slices/lossTaxExemptions/reducer';
import priceAlertsReducer from './slices/priceAlerts/reducer';
import savingsPlansReducer from './slices/savingsPlans/reducer';
import tradingRulesReducer from './slices/tradingRules/reducer';
import newsReducer from './slices/news/reducer';
import referralReducer from './slices/referral/reducer';
import cryptoWithdrawal from './slices/cryptoWithdrawal/reducer';
import vaspSearch from './slices/vaspSearch/reducer';
import satoshiTest from './slices/satoshiTest/reducer';
import cryptoDepositTravelRule from './slices/cryptoDepositTravelRule/reducer';

import newsletterReducer from './slices/newsletter/reducer';
import {
  CRYPTO_WITHDRAWAL_SLICE_NAME,
  DEVICES_SLICE_NAME,
  LOSS_TAX_EXEMPTIONS_SLICE_NAME,
  MARKET_OVERVIEW_SLICE_NAME,
  PRICE_CHANGES_SLICE_NAME,
  PRICE_HISTORY_SMALL_CHART_SLICE_NAME,
  PRICE_HISTORY_SLICE_NAME,
  PORTFOLIO_SLICE_NAME,
  PORTFOLIO_CHART_SLICE_NAME,
  PRICE_ALERTS_SLICE_NAME,
  SAVINGS_PLANS_SLICE_NAME,
  TRADING_RULES_SLICE_NAME,
  NEWS_SLICE_NAME,
  PRICES_SLICE_NAME,
  REFERRAL_SLICE_NAME,
  NEWSLETTER_SLICE_NAME,
  TAX_EXEMPTION_SLICE_NAME,
  VASP_SEARCH_SLICE_NAME,
  SATOSHI_TEST_SLICE_NAME,
  CRYPTO_DEPOSIT_TRAVEL_RULE_SLICE_NAME,
} from 'common/const/slices';
import { CONSENT_TYPES } from 'common/const/consent';
import { FeatureSpecificConsentsState } from 'store/types/featureSpecificConsent';
import { ConsentNameType } from 'types/consent';

export const persistKeys = Object.freeze({
  status: 'status',
  auth: 'auth',
  forms: 'forms',
  navigation: 'navigation',
  alerts: 'alerts',
  localSettings: 'localSettings',
  tracking: 'tracking',
  termsAndConditions: 'termsAndConditions',
  deviceMonitoring: 'deviceMonitoring',
  topUps: 'topUps',
  filters: 'filters',
});

export const persistConfigs = Object.freeze({
  [persistKeys.status]: {
    key: persistKeys.status,
    storage: storageSession, // keep status only for a session
    stateReconciler: autoMergeLevel2, // merge two levels deep
  },
  [persistKeys.auth]: {
    key: persistKeys.auth,
    storage,
    stateReconciler: autoMergeLevel2, // merge two levels deep
    whitelist: ['user', 'tokens'],
    blacklist: ['emailConfirmation', 'emailChangeConfirmation'],
  },
  [persistKeys.forms]: {
    key: persistKeys.forms,
    storage,
    stateReconciler: autoMergeLevel2, // merge two levels deep
    whitelist: ['kycFormData', 'kycMeta'],
  },
  [persistKeys.navigation]: {
    key: persistKeys.navigation,
    storage,
    stateReconciler: autoMergeLevel2, // merge two levels deep
    whitelist: ['isPendingShowingIntro'],
  },
  [persistKeys.localSettings]: {
    key: persistKeys.localSettings,
    storage,
    stateReconciler: autoMergeLevel2, // merge two levels deep
    blacklist: ['showPassword', 'afterLoginFlag'],
  },
  [persistKeys.tracking]: {
    key: persistKeys.tracking,
    storage,
    stateReconciler: autoMergeLevel2, // merge two levels deep
    blacklist: ['flushRetryMode'],
  },
  [persistKeys.termsAndConditions]: {
    key: persistKeys.termsAndConditions,
    storage,
    // here we just transform the state to store only the consentAcceptance status
    transforms: [
      {
        in: (state) => {
          if (
            !(
              typeof state === 'object' &&
              Object.keys(state).every((key) => Object.values(CONSENT_TYPES).includes(key))
            )
          )
            return state;

          return Object.entries(state as FeatureSpecificConsentsState).reduce(
            (acc, [consentName, consentState]) => {
              acc[consentName] = consentState.consent.consentState;
              return acc;
            },
            {} as Record<ConsentNameType, string>,
          );
        },
        out: (state) => {
          if (
            !(
              typeof state === 'object' &&
              Object.keys(state).every((key) => Object.values(CONSENT_TYPES).includes(key))
            )
          )
            return state;

          return Object.entries(state as Record<ConsentNameType, string>).reduce(
            (acc, [consentName, consentAcceptance]) => {
              acc[consentName] = {
                ...featureSpecificConsentState,
                consent: { ...featureSpecificConsentState.consent, consentState: consentAcceptance },
              };

              return acc;
            },
            {} as FeatureSpecificConsentsState,
          );
        },
      },
    ],
    whitelist: ['isTermsAndConditionsClickedAway', 'featureSpecificConsents'],
  },
  [persistKeys.deviceMonitoring]: {
    key: persistKeys.deviceMonitoring,
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['deviceConsents'],
  },
  [persistKeys.topUps]: {
    key: persistKeys.topUps,
    storage,
    stateReconciler: autoMergeLevel2, // merge two levels deep
    whitelist: ['isTopUpInitialisationFailed', 'isTopUpInitialising'],
  },
});

// Remote persist
// WARNING: use all lower case keys! e.g. 'bisonapp-storereview' (remote supports only all lowercase)
export const remoteStorageKeys = Object.freeze({
  config: 'bisonapp-config',
  configFeature: 'bison-config-feature',
  trackingConfig: 'bison-tracking-config',
  settings: 'bisonapp-settings',
  legalAgreements: 'bison-legal-agreements',
  experimentsRemoteData: 'bison-abtest-groups',
});
export const remoteRehydrateSelectors = Object.freeze({
  [remoteStorageKeys.config]: (state: any) => state.config, // read-only
  [remoteStorageKeys.configFeature]: (state: any) => state.configFeature, // read-only
  [remoteStorageKeys.trackingConfig]: (state: any) => state.trackingConfig, // read-only
  [remoteStorageKeys.settings]: (state: any) => state.settings,
  [remoteStorageKeys.legalAgreements]: (state: any) => state.legalAgreements,
  [remoteStorageKeys.experimentsRemoteData]: (state: any) => state.experimentsRemoteData,
});
export const remotePersistSelectors = Object.freeze({
  [remoteStorageKeys.settings]: (state: any) => state.settings,
  [remoteStorageKeys.legalAgreements]: (state: any) => state.legalAgreements,
});

// wraps baseReducer to reduce RESET_REDUX_STATE action to inital state,
// while keeping _persist property which enables redux-persist to continue working
// (it needs _persist to update local storage)
const resetReducer = (baseReducer) => (state: any, action: any) =>
  action.type === remotePersistActions.RESET_REDUX_STATE
    ? baseReducer(undefined, action)
    : baseReducer(state, action);

const appReducer = combineReducers<State>({
  // redux-persist
  auth: persistReducer(persistConfigs.auth, resetReducer(authReducer)),
  forms: persistReducer(persistConfigs.forms, resetReducer(forms)),
  navigation: persistReducer(persistConfigs.navigation, resetReducer(navigation)),
  localSettings: persistReducer(persistConfigs.localSettings, localSettings),
  tracking: persistReducer(persistConfigs.tracking, tracking),
  termsAndConditions: persistReducer(persistConfigs.termsAndConditions, resetReducer(termsAndConditions)),
  deviceMonitoring: persistReducer(persistConfigs.deviceMonitoring, deviceMonitoring),
  topUps: persistReducer(persistConfigs.topUps, resetReducer(topUps)),

  // remote persist
  experimentsRemoteData: remotePersistReducer({ key: 'bison-abtest-groups' }, experimentsRemoteDataReducer), // read-only state
  config: remotePersistReducer({ key: 'bisonapp-config' }, config), // read-only state
  configFeature: remotePersistReducer({ key: remoteStorageKeys.configFeature }, resetReducer(configFeature)), // read-only state
  trackingConfig: remotePersistReducer({ key: 'bison-tracking-config' }, resetReducer(trackingConfig)), // read-only state
  settings: remotePersistReducer({ key: 'bisonapp-settings' }, resetReducer(settings)),
  legalAgreements: remotePersistReducer(
    { key: remoteStorageKeys.legalAgreements },
    resetReducer(legalAgreements),
  ),
  persist: remotePersistInternalReducer,

  // default reducer
  [activitySlice.name]: activitySlice.reducer,
  assets: resetReducer(assets),
  environment: resetReducer(environment),
  status: resetReducer(statusReducer),
  currency: resetReducer(currency),
  money: resetReducer(money),
  notification: resetReducer(notification),
  orders: resetReducer(orders),
  inbox: inboxSlice,
  filters: resetReducer(filtersSlice),
  // router: connectRouter(history),
  stockEtfOrder: resetReducer(stockEtfOrder),
  stockRaffle: resetReducer(stockRaffleReducer),
  experiments: experimentsReducer,
  staking: stakingSlice.reducer,
  [MARKET_OVERVIEW_SLICE_NAME]: resetReducer(marketOverviewReducer),
  [PRICES_SLICE_NAME]: pricesReducer,
  [PRICE_CHANGES_SLICE_NAME]: priceChangesReducer,
  [PRICE_HISTORY_SLICE_NAME]: priceHistoryReducer,
  [PRICE_HISTORY_SMALL_CHART_SLICE_NAME]: miniPriceHistoryReducer,
  [PORTFOLIO_SLICE_NAME]: resetReducer(portfolioReducer),
  [PORTFOLIO_CHART_SLICE_NAME]: resetReducer(portfolioChartReducer),
  [PRICE_ALERTS_SLICE_NAME]: resetReducer(priceAlertsReducer),
  [SAVINGS_PLANS_SLICE_NAME]: resetReducer(savingsPlansReducer),
  [TRADING_RULES_SLICE_NAME]: resetReducer(tradingRulesReducer),
  [REFERRAL_SLICE_NAME]: resetReducer(referralReducer),
  [NEWSLETTER_SLICE_NAME]: resetReducer(newsletterReducer),
  [NEWS_SLICE_NAME]: resetReducer(newsReducer),
  [DEVICES_SLICE_NAME]: resetReducer(devicesReducer),
  [TAX_EXEMPTION_SLICE_NAME]: resetReducer(taxExemptionReducer),
  [LOSS_TAX_EXEMPTIONS_SLICE_NAME]: resetReducer(lossTaxExemptionsReducer),
  [CRYPTO_WITHDRAWAL_SLICE_NAME]: resetReducer(cryptoWithdrawal),
  [VASP_SEARCH_SLICE_NAME]: resetReducer(vaspSearch),
  [SATOSHI_TEST_SLICE_NAME]: resetReducer(satoshiTest),
  [CRYPTO_DEPOSIT_TRAVEL_RULE_SLICE_NAME]: resetReducer(cryptoDepositTravelRule),
  misc,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;

// If we wanted to nest reducers:

// const rootPersistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['currency'],
// };
// export default persistReducer(rootPersistConfig, rootReducer);
