import { action, createRequestTypes } from 'store/actions/_common';
import {
  BankMigrationTermsAndConditionsSuceessPayload,
  TermsAndConditionsData,
  TermsAndConditionsDataType,
} from 'types/termsAndConditions';
import { AnyAction } from 'redux';
import { ConsentNameType, ConsentPayloadType } from 'types/consent';

interface Action {
  type: string;
}

// Crypto TNCs

export const FETCH_CRYPTO_TERMS_AND_CONDITIONS = createRequestTypes('FETCH_CRYPTO_TERMS_AND_CONDITIONS');
export const ACCEPT_CRYPTO_TERMS_AND_CONDITIONS = createRequestTypes('ACCEPT_CRYPTO_TERMS_AND_CONDITIONS');
export const CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY = 'CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY';
export const SHOW_SOFT_BLOCK_POP_UP = 'SHOW_SOFT_BLOCK_POP_UP';
export const CLOSE_SOFT_BLOCK_POP_UP = 'CLOSE_SOFT_BLOCK_POP_UP';

export const fetchCryptoTermsAndConditions = {
  request: (): Action => action(FETCH_CRYPTO_TERMS_AND_CONDITIONS.REQUEST),
  success: (_: any, response: TermsAndConditionsDataType): Action & { payload: TermsAndConditionsDataType } =>
    action(FETCH_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS, { payload: response }),
  failure: (_: any, error: string): Action & { error: string } =>
    action(FETCH_CRYPTO_TERMS_AND_CONDITIONS.FAILURE, { error }),
};

export const acceptCryptoTermsAndConditions = {
  request: (disableSuccessAndFailureNotifications = false): Action =>
    action(ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.REQUEST, {
      disableSuccessAndFailureNotifications,
    }),
  success: (
    _: any,
    response: TermsAndConditionsDataType,
    disableSuccessAndFailureNotifications = false,
  ): Action & { payload: TermsAndConditionsDataType } =>
    action(ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.SUCCESS, {
      payload: response,
      disableSuccessAndFailureNotifications,
    }),
  failure: (
    _: any,
    error: string,
    disableSuccessAndFailureNotifications = false,
  ): Action & { error: string } =>
    action(ACCEPT_CRYPTO_TERMS_AND_CONDITIONS.FAILURE, {
      error,
      disableSuccessAndFailureNotifications,
    }),
};

export const cryptoTermsAndConditionsClickedAway = (isTncClickedAway = true): AnyAction => ({
  type: CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY,
  payload: isTncClickedAway,
});

export const showSoftBlockPopUp = (): Action => ({
  type: SHOW_SOFT_BLOCK_POP_UP,
});

export const closeSoftBlockPopUp = (): Action => ({
  type: CLOSE_SOFT_BLOCK_POP_UP,
});

// Stocks TNCs

export const FETCH_STOCKS_TERMS_AND_CONDITIONS = createRequestTypes('FETCH_STOCKS_TERMS_AND_CONDITIONS');
export const ACCEPT_STOCKS_TERMS_AND_CONDITIONS = createRequestTypes('ACCEPT_STOCKS_TERMS_AND_CONDITIONS');
export const STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY = 'STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY';

export const fetchStocksTermsAndConditions = {
  request: (): Action => action(FETCH_STOCKS_TERMS_AND_CONDITIONS.REQUEST),
  success: (_: any, response: TermsAndConditionsData): Action & { payload: TermsAndConditionsData } =>
    action(FETCH_STOCKS_TERMS_AND_CONDITIONS.SUCCESS, { payload: response }),
  failure: (_: any, error: string): Action & { error: string } =>
    action(FETCH_STOCKS_TERMS_AND_CONDITIONS.FAILURE, { error }),
};

export const acceptStocksTermsAndConditions = {
  request: (): Action => action(ACCEPT_STOCKS_TERMS_AND_CONDITIONS.REQUEST),
  success: (_: any, response: TermsAndConditionsData): Action & { payload: TermsAndConditionsData } =>
    action(ACCEPT_STOCKS_TERMS_AND_CONDITIONS.SUCCESS, { payload: response }),
  failure: (_: any, error: string): Action & { error: string } =>
    action(ACCEPT_STOCKS_TERMS_AND_CONDITIONS.FAILURE, { error }),
};

export const stocksTermsAndConditionsClickedAway = (): AnyAction => ({
  type: STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY,
});

// Feature specific TnCs
export const FETCH_FEATURE_TERMS_AND_CONDITIONS = 'FETCH_FEATURE_TERMS_AND_CONDITIONS';
export const FETCH_FEATURE_TERMS_AND_CONDITIONS_SUCCESS = 'FETCH_FEATURE_TERMS_AND_CONDITIONS_SUCCESS';
export const FETCH_FEATURE_TERMS_AND_CONDITIONS_FAILURE = 'FETCH_FEATURE_TERMS_AND_CONDITIONS_FAILURE';

export const ACCEPT_FEATURE_TERMS_AND_CONDITIONS = 'ACCEPT_FEATURE_TERMS_AND_CONDITIONS';
export const ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS = 'ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS';
export const ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE = 'ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE';

export const DECLINE_FEATURE_TERMS_AND_CONDITIONS = 'DECLINE_FEATURE_TERMS_AND_CONDITIONS';
export const DECLINE_FEATURE_TERMS_AND_CONDITIONS_SUCCESS = 'DECLINE_FEATURE_TERMS_AND_CONDITIONS_SUCCESS';
export const DECLINE_FEATURE_TERMS_AND_CONDITIONS_FAILURE = 'DECLINE_FEATURE_TERMS_AND_CONDITIONS_FAILURE';

export const SHOW_FEATURE_TERMS_AND_CONDITIONS = 'SHOW_FEATURE_TERMS_AND_CONDITIONS';
export const RESET_FEATURE_TERMS_AND_CONDITIONS = 'RESET_FEATURE_TERMS_AND_CONDITIONS';

export const FETCH_FEATURE_TERMS_AND_CONDITIONS_TOPUPS = 'FETCH_FEATURE_TERMS_AND_CONDITIONS_TOPUPS';
export const FETCH_FEATURE_TERMS_AND_CONDITIONS_ACTIVE_STAKING =
  'FETCH_FEATURE_TERMS_AND_CONDITIONS_ACTIVE_STAKING';
export const NOTIFY_USER_AFTER_ACTIVE_STAKING_ACCEPTANCE = 'NOTIFY_USER_AFTER_ACTIVE_STAKING_ACCEPTANCE';

export const fetchFeatureTermsAndConditionsTopups = () => ({
  type: FETCH_FEATURE_TERMS_AND_CONDITIONS_TOPUPS,
});

export const fetchFeatureTermsAndConditionsActiveStaking = () => ({
  type: FETCH_FEATURE_TERMS_AND_CONDITIONS_ACTIVE_STAKING,
});

export const fetchFeatureTermsAndConditions = (consentName: ConsentNameType) => ({
  type: FETCH_FEATURE_TERMS_AND_CONDITIONS,
  payload: {
    consentName,
  },
});

export const fetchFeatureTermsAndConditionsSuccess = (
  payload: ConsentPayloadType,
  consentName: ConsentNameType,
) => ({
  type: FETCH_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
  payload: {
    consentName,
    consent: payload,
  },
});

export const fetchFeatureTermsAndConditionsFailure = (consentName: ConsentNameType) => ({
  type: FETCH_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
  payload: { consentName },
});

export const acceptFeatureTermsAndConditions = (
  consentName: ConsentNameType,
  disableNotification = false,
) => ({
  type: ACCEPT_FEATURE_TERMS_AND_CONDITIONS,
  payload: { consentName, disableNotification },
});

export const acceptFeatureTermsAndConditionsSuccess = (consentName: ConsentNameType) => ({
  type: ACCEPT_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
  payload: { consentName },
});

export const acceptFeatureTermsAndConditionsFailure = (consentName: ConsentNameType) => ({
  type: ACCEPT_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
  payload: { consentName },
});

export const declineFeatureTermsAndConditions = (consentName: ConsentNameType) => ({
  type: DECLINE_FEATURE_TERMS_AND_CONDITIONS,
  payload: { consentName },
});

export const declineFeatureTermsAndConditionsSuccess = (consentName: ConsentNameType) => ({
  type: DECLINE_FEATURE_TERMS_AND_CONDITIONS_SUCCESS,
  payload: { consentName },
});

export const declineFeatureTermsAndConditionsFailure = (consentName: ConsentNameType) => ({
  type: DECLINE_FEATURE_TERMS_AND_CONDITIONS_FAILURE,
  payload: { consentName },
});

export const showFeatureTermsAndConditions = (
  newValue: boolean,
  consentName: ConsentNameType,
): AnyAction => ({
  type: SHOW_FEATURE_TERMS_AND_CONDITIONS,
  payload: {
    newValue,
    consentName,
  },
});

export const resetFeatureTermsAndConditions = (consentName: ConsentNameType): AnyAction => ({
  type: RESET_FEATURE_TERMS_AND_CONDITIONS,
  payload: {
    consentName,
  },
});

export const notifyUserAfterActiveStakingAcceptance = (
  isConfirmGeneralTnCNeeded: boolean,
  isConfirmDirectiveNeeded: boolean,
): AnyAction => ({
  type: NOTIFY_USER_AFTER_ACTIVE_STAKING_ACCEPTANCE,
  payload: {
    isConfirmGeneralTnCNeeded,
    isConfirmDirectiveNeeded,
  },
});

// UPDATE FOR TERMS AND CONDITIONS (UNAUTHENTICATED)

export const FETCH_BANK_MIGRATION_TERMS_AND_CONDITIONS = createRequestTypes(
  'FETCH_BANK_MIGRATION_TERMS_AND_CONDITIONS',
);
export const ACCEPT_BANK_MIGRATION_TERMS_AND_CONDITIONS = createRequestTypes(
  'ACCEPT_BANK_MIGRATION_TERMS_AND_CONDITIONS',
);

export const fetchBankMigrationTermsAndConditions = {
  request: (externalUserId: string): Action =>
    action(FETCH_BANK_MIGRATION_TERMS_AND_CONDITIONS.REQUEST, { externalUserId }),
  success: (
    _: any,
    response: BankMigrationTermsAndConditionsSuceessPayload,
  ): Action & { payload: BankMigrationTermsAndConditionsSuceessPayload } =>
    action(FETCH_BANK_MIGRATION_TERMS_AND_CONDITIONS.SUCCESS, {
      payload: response,
    }),

  failure: (_: any, error: string): Action & { error: string } =>
    action(FETCH_BANK_MIGRATION_TERMS_AND_CONDITIONS.FAILURE, {
      error,
    }),
};

export const acceptBankMigrationTermsAndConditions = {
  request: (externalUserId: string): Action =>
    action(ACCEPT_BANK_MIGRATION_TERMS_AND_CONDITIONS.REQUEST, { externalUserId }),
  success: (_: any): Action => action(ACCEPT_BANK_MIGRATION_TERMS_AND_CONDITIONS.SUCCESS),
  failure: (_: any, error: string): Action & { error: string } =>
    action(ACCEPT_BANK_MIGRATION_TERMS_AND_CONDITIONS.FAILURE, {
      error,
    }),
};
