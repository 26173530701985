import { SpecificFlowDetails } from 'store/types/moneyTransfer';
import { RootEpic } from 'types/common';
import { AppReduxEpicMiddleware } from 'store/types/store';
import { catchError, filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { satoshiTestFailure, satoshiTestRequest, satoshiTestSuccess } from 'store/slices/satoshiTest/actions';
import { getAccessToken } from 'store/selectors/auth';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { Observable } from 'rxjs';
import { handleAjaxError } from 'store/epics/auth';
import { IntentType } from 'common/const/moneyTransfer';

export const getSatoshiTestEpic =
  ({ requestRoute, errorMessage, intentType }: SpecificFlowDetails & { intentType: IntentType }): RootEpic =>
  (action$, state$, { ajax, api }: AppReduxEpicMiddleware) =>
    action$.pipe(
      filter(satoshiTestRequest.match),
      filter(({ payload }) => intentType === payload.intentType),
      map(({ payload: { onSuccess, onFailure, intentId } }) => ({
        accessToken: getAccessToken(state$.value),
        params: { intentId },
        onSuccess,
        onFailure,
      })),
      filter(({ accessToken }) => !!accessToken),
      switchMap(({ accessToken, params, onSuccess, onFailure }) =>
        ajax({
          url: api
            .getBaseUrlForTrading(accessToken ?? '')
            ?.concat(requestRoute)
            .concat(queryArrayString(params)),
          method: 'GET',
          withCredentials: true, // include cookies
          headers: {
            ...api.getCommonHeaders(),
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }).pipe(
          pluck('response'),
          tap(onSuccess),
          map(satoshiTestSuccess),
          backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
          catchError((error: { response: { code: string } }, source: Observable<unknown>) => {
            onFailure();
            return handleAjaxError(
              action$,
              (err) => satoshiTestFailure(err),
              {},
              { message: errorMessage },
            )(error, source);
          }),
        ),
      ),
    );

export default getSatoshiTestEpic;
