import { State } from 'store/types/store';
import { VASP_SEARCH_SLICE_NAME } from 'common/const/slices';
import vaspAdapter from './adapter';

const vaspSearchState = (state: State) => state[VASP_SEARCH_SLICE_NAME];

const vaspSearchSelectors = vaspAdapter.getSelectors(vaspSearchState);

export const getFoundedVasps = vaspSearchSelectors.selectAll;

export const getIsVaspSearchLoading = (state: State) => vaspSearchState(state).isLoading;

export const getIsVaspSearchQuery = (state: State) => vaspSearchState(state).query;

export const getVaspSearchEndReached = (state: State) => vaspSearchState(state).isEndReached;

export const getVaspSearchPage = (state: State) => vaspSearchState(state).page;
