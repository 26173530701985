import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CRYPTO_WITHDRAWAL_SLICE_NAME } from 'common/const/slices';
import { ApiError } from 'types/error';
import {
  InitCryptoWithdrawalPayload,
  CryptoWithdrawOrder,
  IntentPostCryptoWithdrawalPayload,
} from 'types/cryptoWithdrawal';
import initialState from './initialState';

const cryptoWithdrawalSlice = createSlice({
  name: CRYPTO_WITHDRAWAL_SLICE_NAME,
  initialState,
  reducers: {
    intentPostRequest: (
      state,
      _action: PayloadAction<{
        payload: IntentPostCryptoWithdrawalPayload;
        onTravelRuleDemand: () => void;
        onTravelRuleDiscard: () => void;
        onFailure?: () => void;
      }>,
    ) => {
      state.isIntentLoading = true;
    },
    intentPostSuccess: (
      state,
      action: PayloadAction<{
        intentId: string;
        isWalletVerificationRequired: boolean;
      }>,
    ) => {
      state.isIntentLoading = false;
      state.intentId = action.payload.intentId;
      state.isWalletVerificationRequired = action.payload.isWalletVerificationRequired;
    },
    intentPostFailure: (state, action: PayloadAction<ApiError>) => {
      state.isIntentLoading = false;
      state.error = action.payload;
    },
    intentPatchRequest: (
      state,
      _action: PayloadAction<{
        isSelfHostedWallet: boolean;
        vaspId: string | null;
        intentId: string;
        onTravelRuleDemand: () => void;
        onTravelRuleDiscard: () => void;
        onFailure?: () => void;
      }>,
    ) => {
      state.isIntentLoading = true;
    },
    intentPatchSuccess: (
      state,
      action: PayloadAction<{ intentId: string; IsProofOfOwnershipRequired: boolean }>,
    ) => {
      state.isIntentLoading = false;
      state.intentId = action.payload.intentId;
      state.isProofOfOwnershipRequired = action.payload.IsProofOfOwnershipRequired;
    },
    intentPatchFailure: (state, action: PayloadAction<ApiError>) => {
      state.isIntentLoading = false;
      state.error = action.payload;
    },
    setSelectedVaspId: (state, action: PayloadAction<{ vaspId: string }>) => {
      state.vaspId = action.payload.vaspId;
    },
    eraseVaspId: (state) => {
      state.vaspId = null;
    },
    setIsSelfHosted: (state, action: PayloadAction<{ isSelfHostedWallet: boolean }>) => {
      state.isSelfHostedWallet = action.payload.isSelfHostedWallet;
    },
    initCryptoWithdrawRequest: (state, action: PayloadAction<InitCryptoWithdrawalPayload>) => {
      state.initializingCryptoWithdrawal = true;
      state.cryptoWithdrawInitPayload = action.payload;
    },
    initCryptoWithdrawSuccess: (state, action: PayloadAction<CryptoWithdrawOrder>) => {
      state.initializingCryptoWithdrawal = false;
      state.cryptoWithdrawOrder = action.payload;
    },
    initCryptoWithdrawFailure: (state, action: PayloadAction<ApiError>) => {
      state.initializingCryptoWithdrawal = false;
      state.error = action.payload;
    },
    resendTwoFaCode: () => {},
    submitCryptoWithdrawRequest: (
      state,
      action: PayloadAction<{
        code2fa: string;
        actionsWithdraw: {
          success?: () => void;
          finished?: () => void;
          failed?: () => void;
          wrongTwoFa?: () => void;
        };
        isResend?: boolean;
      }>,
    ) => {
      state.submittingCryptoWithdrawal = true;
      state.code2fa = action.payload.code2fa;
    },
    submitCryptoWithdrawSuccess: (state) => {
      state.submittingCryptoWithdrawal = false;
    },
    submitCryptoWithdrawFailure: (state, action: PayloadAction<ApiError>) => {
      state.submittingCryptoWithdrawal = false;
      state.code2fa = null;
      state.error = action.payload;
    },
    resendCryptoWithdrawConfirmationEmail: (state) => {
      state.submittingCryptoWithdrawal = true;
    },
    startCryptoWithdrawSession: () => initialState,
    endCryptoWithdrawSession: () => initialState,
    resetVaspId: (state) => {
      state.vaspId = null;
    },
  },
});

export default cryptoWithdrawalSlice;
