import React from 'react';
import ModalTemplate from 'common/components/Modal';
import TermsAndConditions from 'common/components/TermsAndConditions';
import { ModalProps } from 'types/modals';
import { PopupData } from 'types/termsAndConditions';

type Props = {
  agreeToTerms: () => void;
  popupData: PopupData | null;
  onClickAway: () => void;
  isPersisted?: boolean;
};

const BankTransferTermsAndConditionsModal = ({
  onClose,
  onClickAway,
  agreeToTerms,
  popupData,
  isPersisted,
}: ModalProps & Props) => {
  const onAgree = () => {
    agreeToTerms();
    onClose();
  };

  return (
    <ModalTemplate
      defaultOpen
      persist={isPersisted}
      onCancel={() => {
        onClose();
        onClickAway();
      }}
      actions={<></>}
    >
      <TermsAndConditions hasLayoutWrapper={false} onAccept={onAgree} popupData={popupData} />
    </ModalTemplate>
  );
};

export default BankTransferTermsAndConditionsModal;
