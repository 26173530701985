import { CryptoDepositTravelRuleState } from 'types/cryptoDepositTravelRule';

export const initialState: CryptoDepositTravelRuleState = {
  error: null,
  intent: null,
  isIntentLoading: false,
  isProofOfOwnershipRequired: null,
};

export default initialState;
