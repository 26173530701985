import cryptoDepositTravelRuleSlice from '.';

export const {
  intentPatchRequest,
  intentPatchFailure,
  intentPatchSuccess,
  intentPostFailure,
  intentPostRequest,
  intentPostSuccess,
  resetCryptoDepositTravelRule,
} = cryptoDepositTravelRuleSlice.actions;
