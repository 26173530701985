import { createSlice } from '@reduxjs/toolkit';
import { SCROLL_PAGE_SIZE } from 'common/const';
import { ACTIVITY_SLICE_NAME } from 'common/const/slices';
import { ActivityInitialState } from 'store/types/activity';
import { activityAdapter } from './adapter';
import {
  loadActivityItemsError,
  loadActivityItemsSuccess,
  loadInitialActivityItems,
  loadNextActivityItemsPage,
  setCryptoActivityFilter,
  setSubtypeActivityFilter,
} from './actions';

const activitySlice = createSlice({
  name: ACTIVITY_SLICE_NAME,
  initialState: activityAdapter.getInitialState({
    loading: false,
    fullyLoaded: false,
    filters: {
      subtype: undefined,
      crypto: undefined,
    },
    reference: undefined,
  } as ActivityInitialState),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadInitialActivityItems, (state) => {
        state.loading = true;
        state.fullyLoaded = false;
        state.reference = undefined;

        // Reset all the items since we are about to fetch new ones.
        activityAdapter.removeAll(state);
      })
      .addCase(loadNextActivityItemsPage, (state) => {
        if (state.fullyLoaded) return;

        state.loading = true;
      })
      .addCase(loadActivityItemsSuccess, (state, { payload }) => {
        state.loading = false;
        state.fullyLoaded = payload?.orders?.length !== SCROLL_PAGE_SIZE;
        state.reference = payload?.orders?.length ? payload.orders.slice(-1)?.pop()?.reference : undefined;

        activityAdapter.addMany(state, payload.orders);
      })
      .addCase(loadActivityItemsError, (state) => {
        state.loading = false;
      })
      .addCase(setCryptoActivityFilter, (state, { payload }) => {
        state.filters.crypto = payload.crypto;

        // if filters are changed then we need to remove items.
        activityAdapter.removeAll(state);
      })
      .addCase(setSubtypeActivityFilter, (state, { payload }) => {
        state.filters.subtype = payload.subtype;

        // if filters are changed then we need to remove items.
        activityAdapter.removeAll(state);
      });
  },
});

export default activitySlice;
