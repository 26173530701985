/**
 * Removes duplicate elements from an array.
 * @param arr The array from which to remove duplicates.
 * @returns An array with duplicate elements removed.
 */
export const removeDuplicates = <T>(arr: T[]): T[] => {
  return Array.from(new Set(arr));
};

/**
 * Converts an array of objects into a dictionary (object) where each key and value is determined
 * by a given property or function. By default, the entire object is used as the value.
 *
 * @template T - The type of objects in the array.
 * @template V - The type of the value in the resulting dictionary.
 * @param {T[]} arr - The array of objects to be converted.
 * @param {(keyof T | ((item: T) => string))} keyOrFn - The property of the object to be used as the key,
 *  or a function that returns a key based on the object.
 * @param {(keyof T | ((item: T) => V))} [valueOrFn] - (Optional) The property or function to determine the value for each key.
 *  Defaults to the full object if not provided.
 * @returns {{ [key: string]: V }} - A dictionary where keys are derived from the specified property or function and values are determined by the value function or property.
 */
export const arrayToDictionary = <T, V = T>(
  arr: T[],
  keyOrFn: keyof T | ((item: T) => string),
  valueOrFn?: keyof T | ((item: T) => V),
): { [key: string]: V } => {
  return arr.reduce(
    (acc, item) => {
      const keyValue = typeof keyOrFn === 'function' ? keyOrFn(item) : String(item[keyOrFn]);

      const value = valueOrFn ? (typeof valueOrFn === 'function' ? valueOrFn(item) : item[valueOrFn]) : item;

      acc[keyValue] = value as V;
      return acc;
    },
    {} as { [key: string]: V },
  );
};
