import { useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLoggedIn } from 'store/selectors/auth';
import { checkIfValidPrivateUrl } from 'common/utils/checkIfValidPrivateUrl';
import { paths } from 'common/urls';

const useDeeplink = () => {
  const [originalUrl, setOriginalUrl] = useState('');
  const [isDeepLinkUsageFinished, setIsDeepLinkUsageFinished] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(getLoggedIn);
  const isLoginPath = useMatch(paths.LOGIN);

  useEffect(() => {
    if (isLoginPath) return;

    if (originalUrl || isDeepLinkUsageFinished) return;

    if (!checkIfValidPrivateUrl(location.pathname)) return;

    setOriginalUrl(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (isLoginPath && originalUrl && isLoggedIn && !isDeepLinkUsageFinished) {
      navigate(originalUrl);
      setIsDeepLinkUsageFinished(true);
    }
  }, [isLoggedIn]);
};

export default useDeeplink;
