import React from 'react';
import { CheckboxText } from 'types/termsAndConditions';
import { Link } from './styles';

type Props = {
  items: CheckboxText[];
};

const CheckboxTextRenderer: React.FC<Props> = ({ items }) => (
  <>
    {items.map((item, index) => {
      const { text, url } = item;
      if (url) {
        return (
          <Link target="_blank" href={url} key={index}>
            {text}
          </Link>
        );
      }
      const isPunctuation = ['.', ',', '!', '?'].includes(text);
      return <span key={index}>{isPunctuation ? `${text} ` : ` ${text} `}</span>;
    })}
  </>
);

export default CheckboxTextRenderer;
