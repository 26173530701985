import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { getUser } from 'store/selectors/auth';
import { ConsentsPopupCollection } from 'types/consent';
import { SupportedLanguages, SupportedLanguagesAT } from 'types/termsAndConditions';

const consentFallback = (t: TFunction<'translation', undefined, 'translation'>, termsUrl: string) => ({
  title: t('bankMigrationConsent.title'),
  text: t('bankMigrationConsent.text'),
  checkbox: [
    { text: t('bankMigrationConsent.checkbox.a') },
    { text: t('bankMigrationConsent.checkbox.b'), url: termsUrl },
    { text: t('bankMigrationConsent.checkbox.c') },
    { text: t('bankMigrationConsent.checkbox.d'), url: termsUrl },
    { text: t('bankMigrationConsent.checkbox.e') },
  ],
});

const useConsentPopupText = (popupData: ConsentsPopupCollection | null, dataKey: 'bankMigration') => {
  const { t } = useTranslation();
  const locale = t('l10n.lang');
  const { address } = useSelector(getUser);

  if (locale) {
    let key = `${locale}_DE` as SupportedLanguages;
    if (address?.country === 'AT') {
      key = `${locale}_AT` as SupportedLanguagesAT;
    }
    if (dataKey === 'bankMigration') {
      return popupData?.bankMigration?.[key] ?? consentFallback(t, 'https://bisonapp.com/dokumente/agb');
    }
  }
  return null;
};

export default useConsentPopupText;
