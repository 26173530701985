import React from 'react';
import { Head, Name, Row, SmallText } from 'scenes/TransactionHistory/TransactionDetailModal/styles';
import AssetIcon from 'common/components/Icon/AssetIcon';
import ModalTemplate from 'common/components/Modal';
import { IconWrapper, Info, InfoWrap } from 'common/components/Modal/CryptoInfoWithIconModal/styles';
import { ASSET_CLASSES } from 'types/assets';
import { ModalProps } from 'types/modals';

type Props = {
  open: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
  headerSubTitle: React.ReactNode;
  displayName: string;
  currency: string;
  hideShortnameInTitle?: boolean;
} & ModalProps;
const CryptoInfoWithIconModal = ({
  headerSubTitle,
  children,
  open,
  actions,
  displayName,
  currency,
  hideShortnameInTitle = false,
  onClose,
}: Props) => (
  <ModalTemplate
    defaultOpen={open}
    onCancel={onClose}
    actions={actions}
    header={
      <Head>
        <IconWrapper>
          <AssetIcon code={currency} class={ASSET_CLASSES.CRYPTO} securityClass={undefined} />
        </IconWrapper>
        <Name>{`${displayName} ${!hideShortnameInTitle ? `(${currency.toUpperCase()})` : ''}`}</Name>
        {headerSubTitle}
      </Head>
    }
  >
    {children}
  </ModalTemplate>
);

export const ActionsTable = ({ rows }: { rows: { title: string; value: string }[] }) => (
  <InfoWrap>
    <Info>
      {rows.map(({ title, value }, index) => (
        <Row key={index}>
          <SmallText>{title}</SmallText>
          <SmallText>{value}</SmallText>
        </Row>
      ))}
    </Info>
  </InfoWrap>
);

export default CryptoInfoWithIconModal;
