import styled from 'styled-components';
import { Theme } from 'types/theme';
import { getTextStyles } from 'common/styles/global';
import Icon from 'common/components/Icon';
import ModalLink from 'common/components/ModalLink';
import { devices } from 'common/styles';

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 24px;
`;
export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`;
export const Name = styled.div.attrs({ className: 'primary-color-text xlarge-text strong-weight' })`
  margin-bottom: 8px;
`;
export const Status = styled.div.attrs({ className: 'small-text strong-weight' })`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.inactive};
  text-transform: uppercase;
`;
export const Body = styled.div`
  margin-top: 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const LargeText = styled.div`
    ${({
      theme,
      isSecondary,
      isNormalFontWeight,
    }: {
      theme: Theme;
      isSecondary?: boolean;
      isNormalFontWeight?: boolean;
    }) =>
      `${getTextStyles(isSecondary ? 'small' : 'xlarge', theme)}
  color: ${isSecondary ? theme.color.foreground.secondary : theme.color.foreground.primary};
  font-weight: ${
    isNormalFontWeight ? theme.typography.fontWeight.regular : theme.typography.fontWeight.strong
  };
  `}
}
`;
export const Line = styled.div`
  height: 1px;
  width: 100%;
  ${({ customMargin }: { customMargin?: number }) => `margin: ${customMargin ?? '4'}px 0`};
  background: ${({ theme }: { theme: Theme }) => theme.color.background.progressIndicator};
  opacity: 0.4;
`;
export const InfoWrap = styled.div`
  padding-top: ${({ withoutPadding }: { withoutPadding: boolean }) => (withoutPadding ? '0' : '63px')};
  width: 100%;
`;
export const Info = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.action};
  padding: 16px;
  border-radius: 4px;
`;
export const LegalText = styled.div.attrs({ className: 'small-text secondary-color-text' })`
  margin-top: auto;
  margin-bottom: 24px;
  text-align: left;
`;

export const TravelRuleWithdrawLegalText = styled.div.attrs({ className: 'xxsmall-text' })`
  padding-top: 16px;
  text-align: left;
`;

export const SupportLink = styled.a`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  text-decoration: underline;
`;

export const SmallText = styled.div.attrs<{ strong?: boolean }>(({ strong }) => ({
  className: `small-text ${strong ? 'strong-weight' : ''}`,
}))`
    ${({ theme, isSecondary }: { theme: Theme; isSecondary?: boolean }) =>
      `color: ${isSecondary ? theme.color.foreground.secondary : theme.color.foreground.primary};`}
}

`;

export const WalletValueText = styled(SmallText)`
  word-break: break-all;
  text-align: left;
  padding-left: 10px;
`;

export const CreditCardIcon = styled(Icon)`
  opacity: 0.24;
`;

export const InboxLink = styled(ModalLink)`
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;

export const TravelRuleButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 0;
    flex: 0 0 320px;
  }

  @media ${devices.maxMobileL} {
    & > * {
      flex: 0 0 100%;
    }
  }
`;
