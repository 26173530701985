import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VASP_SEARCH_SLICE_NAME } from 'common/const/slices';
import { ApiError } from 'types/error';
import { Vasp } from 'types/vaspSearch';
import initialState from './initialState';
import vaspAdapter from './adapter';

const cryptoWithdrawalSlice = createSlice({
  name: VASP_SEARCH_SLICE_NAME,
  initialState,
  reducers: {
    clearVasps: (state) => {
      vaspAdapter.removeAll(state);
    },
    getVaspsRequest: (state, action: PayloadAction<{ query: string }>) => {
      state.isLoading = true;
      state.query = action.payload.query;
      state.page = 1;
    },
    getVaspsSuccess: (state, action: PayloadAction<{ vasps: Vasp[]; totalPageCount: number }>) => {
      vaspAdapter.setAll(state, action.payload.vasps);
      state.isLoading = false;
      state.isEndReached = state.page === action.payload.totalPageCount;
    },
    getVaspsFailure: (state, action: PayloadAction<ApiError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    loadNextPageRequest: (state) => {
      state.page += 1;
      state.isLoading = true;
    },
    loadNextPageSuccess: (state, action: PayloadAction<{ vasps: Vasp[]; totalPageCount: number }>) => {
      vaspAdapter.addMany(state, action.payload.vasps);
      state.isLoading = false;
      state.isEndReached = state.page === action.payload.totalPageCount;
    },
    loadNextPageFailure: (state, action: PayloadAction<ApiError>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export default cryptoWithdrawalSlice;
