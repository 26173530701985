import { RootEpic } from 'types/common';
import { AppReduxEpicMiddleware } from 'store/types/store';
import { catchError, filter, map, mergeMap, pluck, switchMap } from 'rxjs/operators';
import { getAccessToken } from 'store/selectors/auth';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { handleAjaxError } from 'store/epics/auth';
import {
  getCode2fa,
  getCryptoWithdrawInitPayload,
  getCryptoWithdrawIntentId,
  getCryptoWithdrawOrder,
} from 'store/slices/cryptoWithdrawal/selectors';
import { combineEpics } from 'redux-observable';
import {
  initCryptoWithdrawFailure,
  initCryptoWithdrawRequest,
  initCryptoWithdrawSuccess,
  intentPatchFailure,
  intentPatchRequest,
  intentPatchSuccess,
  intentPostFailure,
  intentPostRequest,
  intentPostSuccess,
  resendCryptoWithdrawConfirmationEmail,
  resendTwoFaCode,
  submitCryptoWithdrawFailure,
  submitCryptoWithdrawRequest,
  submitCryptoWithdrawSuccess,
} from 'store/slices/cryptoWithdrawal/actions';
import { getDeviceData } from 'common/utils/seonSdk';
import { EMPTY, from, Observable, of } from 'rxjs';
import { Action, PayloadActionCreator } from '@reduxjs/toolkit';
import I18n from 'i18next';
import { errorCodes, makeErrorFromAjaxError } from 'common/apiErrors';
import { pageURLs } from 'common/urls';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import { CryptoWithdrawOrder, InitCryptoWithdrawalPayload } from 'types/cryptoWithdrawal';
import { getIntentPatchEpic, getIntentPostEpic } from 'store/epics/moneyTransfer';
import getSatoshiTestEpic from 'store/epics/satoshiTest';
import { IntentType, WITHDRAW_INTENT_ROUTE } from 'common/const/moneyTransfer';

const intentPostEpic = getIntentPostEpic({
  requestRoute: WITHDRAW_INTENT_ROUTE,
  actions: {
    request: intentPostRequest,
    success: intentPostSuccess,
    failure: intentPostFailure,
  },
  errorMessage: I18n.t('cryptoWithdraw.errors.generic'),
});

const intentPatchEpic = getIntentPatchEpic({
  requestRoute: WITHDRAW_INTENT_ROUTE,
  actions: {
    request: intentPatchRequest,
    success: intentPatchSuccess,
    failure: intentPatchFailure,
  },
  errorMessage: I18n.t('cryptoWithdraw.errors.generic'),
});

const satoshiTestEpic = getSatoshiTestEpic({
  requestRoute: '/crypto/withdraw/intent/satoshi-test',
  errorMessage: I18n.t('cryptoWithdraw.errors.generic'),
  intentType: IntentType.WITHDRAW,
});

// init and resend twoFa code epics creator
const createCryptoWithdrawEpic =
  ({
    requestAction,
    onSuccess,
    notify,
  }: {
    requestAction: PayloadActionCreator<InitCryptoWithdrawalPayload>;
    onSuccess: (data: CryptoWithdrawOrder) => Observable<Action>;
    notify: { message: string } | null;
  }): RootEpic =>
  (action$, state$, { ajax, api }) =>
    action$.pipe(
      filter(requestAction.match),
      switchMap(({ payload }) =>
        from(getDeviceData()).pipe(
          map(({ deviceData }) => {
            const orderPayload = getCryptoWithdrawInitPayload(state$.value);
            const onInitSuccess = payload?.onInitSuccess;
            const onInitError = payload?.onInitError;
            return {
              accessToken: getAccessToken(state$.value),
              payload: {
                deviceData,
                intentId: getCryptoWithdrawIntentId(state$.value),
                password: orderPayload?.password,
              },
              onInitSuccess,
              onInitError,
            };
          }),
          filter(({ accessToken }) => !!accessToken),
          switchMap(({ accessToken, payload: body, onInitSuccess, onInitError }) =>
            ajax({
              url: api.getBaseUrlForTrading(accessToken ?? '')?.concat('/crypto/withdraw'),
              method: 'POST',
              body,
              withCredentials: true, // include cookies
              headers: {
                ...api.getCommonHeaders(),
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
            }).pipe(
              pluck('response', 'data'),
              mergeMap((data: CryptoWithdrawOrder) => {
                if (onInitSuccess) onInitSuccess();
                return onSuccess(data);
              }),
              backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
              catchError((error: { response: { code: string } }, source: Observable<unknown>) => {
                if (onInitError) onInitError();
                return handleAjaxError(
                  action$,
                  (err: AjaxError) => initCryptoWithdrawFailure(makeErrorFromAjaxError(err)),
                  {},
                  notify,
                )(error, source);
              }),
            ),
          ),
        ),
      ),
    );

// Using the reusable function to create the two epics
const initCryptoWithdrawEpic: RootEpic = createCryptoWithdrawEpic({
  requestAction: initCryptoWithdrawRequest,
  onSuccess: (data: CryptoWithdrawOrder) => of(initCryptoWithdrawSuccess(data)),
  notify: null,
});

const resendCryptoWithdrawCodeEpic: RootEpic = createCryptoWithdrawEpic({
  requestAction: resendTwoFaCode,
  onSuccess: (data: CryptoWithdrawOrder) =>
    of(
      initCryptoWithdrawSuccess(data),
      notificationBannerActions.notifySuccess({
        message: I18n.t('cryptoWithdraw.resendCodeSuccess'),
      }),
    ),
  notify: { message: I18n.t('cryptoWithdraw.errors.resendCodeError') },
});

const watchErrorsForInitNotification: RootEpic = (action$) =>
  action$.pipe(
    filter(initCryptoWithdrawFailure.match),
    filter(
      ({ payload: error }) =>
        error?.detail === 'bch_legacy' ||
        error?.code === errorCodes.CRYPTOWITHDRAWINIT_ERR_WALLETADDRESSINVALID,
    ),
    map(({ payload: error }) => {
      if (error?.detail === 'bch_legacy') {
        const landingUrls = pageURLs();
        return notificationBannerActions.notifyError({
          message: I18n.t('cryptoWithdraw.errors.bchLegacy'),
          onClick: () => {
            window.open(landingUrls.cryptoWithdrawInfo, '_blank');
          },
        });
      }

      if (error?.code === errorCodes.CRYPTOWITHDRAWINIT_ERR_WALLETADDRESSINVALID) {
        return notificationBannerActions.notifyError({
          message: I18n.t('cryptoWithdraw.errors.walletAddressInvalid'),
        });
      }
    }),
  );

const submitCryptoWithdrawEpic: RootEpic = (action$, state$, { ajax, api }: AppReduxEpicMiddleware) =>
  action$.pipe(
    filter(submitCryptoWithdrawRequest.match),
    switchMap(({ payload: { actionsWithdraw, code2fa, isResend } }) =>
      from(getDeviceData()).pipe(
        map(({ deviceData }) => ({
          accessToken: getAccessToken(state$.value),
          actionsWithdraw,
          isResend,
          payload: {
            deviceData,
            code2fa,
            data: getCryptoWithdrawOrder(state$.value),
          },
        })),
        filter(({ accessToken }) => !!accessToken),
        switchMap(({ accessToken, payload, actionsWithdraw, isResend }) =>
          ajax({
            url: api.getBaseUrlForTrading(accessToken ?? '')?.concat('/crypto/withdraw'),
            method: 'PATCH',
            body: payload,
            withCredentials: true, // include cookies
            headers: {
              ...api.getCommonHeaders(),
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          }).pipe(
            pluck('response'),
            mergeMap(() => {
              if (actionsWithdraw?.success) actionsWithdraw.success();

              if (actionsWithdraw?.finished) actionsWithdraw.finished();

              notificationBannerActions.notifySuccess({
                message: I18n.t('cryptoWithdraw.resentMail'),
              });

              const emmitedActions: Action[] = [submitCryptoWithdrawSuccess()];

              if (isResend) {
                emmitedActions.push(
                  notificationBannerActions.notifySuccess({
                    message: I18n.t('cryptoWithdraw.resentMail'),
                  }),
                );
              }

              return from(emmitedActions);
            }),
            backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
            catchError((error: { response: { code: string } }, source: Observable<unknown>) => {
              if (error?.response?.code === errorCodes.CRYPTOWITHDRAWSUBMIT_ERR_INVALIDTOKEN) {
                if (actionsWithdraw?.wrongTwoFa) actionsWithdraw.wrongTwoFa();

                return EMPTY;
              }

              if (actionsWithdraw?.failed) actionsWithdraw.failed();

              return handleAjaxError(
                action$,
                submitCryptoWithdrawFailure,
                {},
                { message: I18n.t('home.currency.orderFail') },
              )(error, source);
            }),
          ),
        ),
      ),
    ),
  );

const resendCryptoWithdrawConfirmationEmailEpic: RootEpic = (action$, state$) =>
  action$.pipe(
    filter(resendCryptoWithdrawConfirmationEmail.match),
    map(() => getCode2fa(state$.value)),
    filter((code2fa) => typeof code2fa === 'string'),
    map((code2fa) =>
      submitCryptoWithdrawRequest({
        code2fa,
        actionsWithdraw: {
          success: undefined,
          finished: undefined,
          failed: undefined,
          wrongTwoFa: undefined,
        },
        isResend: true,
      }),
    ),
  );

export default combineEpics(
  intentPatchEpic,
  intentPostEpic,
  initCryptoWithdrawEpic,
  resendCryptoWithdrawCodeEpic,
  submitCryptoWithdrawEpic,
  watchErrorsForInitNotification,
  resendCryptoWithdrawConfirmationEmailEpic,
  satoshiTestEpic,
);
