import { ActionType } from 'safetypings';
import * as termsAndConditionsActions from 'store/actions/termsAndConditions';
import { ValuesType } from 'utility-types';
import { TNC_POPUP_TYPES } from 'common/const/termsAndConditions';

export type BlockedFeatures = string[] | null;

export type Terms = {
  version: string | null;
  name?: string | null;
  effectiveAt: string | null;
  termsUrl: string | null;
  risksUrl: string | null;
};

// Centralized type for supported languages
export type SupportedLanguagesGeneral = 'en_DE' | 'de_DE';
export type SupportedLanguagesAT = 'en_AT' | 'de_AT';

export type SupportedLanguages = SupportedLanguagesGeneral | SupportedLanguagesAT;

// Type for individual text in a checkbox
export type CheckboxText = {
  text: string;
  url?: string;
};

// Type for T&C / consents popups with title, text, and associated checkbox text
export type PopupData = {
  title: string;
  text: string;
  checkbox: CheckboxText[];
};

// Type for language-specific T&C popups
export type TnCData = Record<SupportedLanguages, PopupData>;

// Type for language-specific T&C checkbox text
export type ConsentData = Record<SupportedLanguages, { checkbox: CheckboxText[] }>;

// Collection for all popup data
export type TnCPopupCollection = {
  tncUpdate: TnCData; // Full T&C popups with title, text, and options
  kyc: ConsentData; // Only options for KYC
  staking: ConsentData; // Only options for staking
};

export type TermsAndConditionsData = {
  hasAcceptedLatestTerms: boolean;
  latestTerms: Terms;
  acceptedTerms: Terms;
  blockedFeatures: BlockedFeatures;
  warningStage: boolean;
};

export type TermsAndConditionsDataResponse = TermsAndConditionsData & {
  popupData?: string;
};

export type TermsAndConditionsDataType = TermsAndConditionsData & {
  popupData: TnCPopupCollection | null;
};

export type TermsAndConditionsActions = ActionType<typeof termsAndConditionsActions>;

export type TnCPopUpType = ValuesType<typeof TNC_POPUP_TYPES>;

export enum TermsAndConditionsType {
  STOCKS = 'stocks',
  CRYPTO = 'crypto',
}

export type BankMigrationTermsAndConditionsSuceessPayload = {
  confirmedAt: null | string;
  consentModel: string;
  consentState: null | 'Accepted';
  consentType: string;
};
