import { RootEpic } from 'types/common';
import { AppReduxEpicMiddleware } from 'store/types/store';
import { catchError, filter, map, pluck, switchMap } from 'rxjs/operators';
import { getAccessToken } from 'store/selectors/auth';
import { backoff } from 'common/utils/rxjs-operators';
import { AjaxError } from 'rxjs/ajax';
import { handleAjaxError } from 'store/epics/auth';
import {
  getVaspsRequest,
  getVaspsFailure,
  getVaspsSuccess,
  loadNextPageRequest,
  loadNextPageSuccess,
} from 'store/slices/vaspSearch/actions';
import { combineEpics } from 'redux-observable';
import { queryArrayString } from 'common/utils/rxjs-ajax';
import { getIsVaspSearchQuery, getVaspSearchPage } from 'store/slices/vaspSearch/selectors';
import { isOfType } from 'safetypings';
import { Vasp } from 'types/vaspSearch';
import { AnyAction } from 'redux';

const VASPS_SEARCH_PAGE_SIZE = 20;

const searchVaspEpic: RootEpic = (action$, state$, { ajax, api }: AppReduxEpicMiddleware) =>
  action$.pipe(
    filter<AnyAction, AnyAction>(isOfType([getVaspsRequest.type, loadNextPageRequest.type])),
    map(({ type: requestActionType }: { type: string }) => ({
      accessToken: getAccessToken(state$.value),
      payload: {
        pageSize: VASPS_SEARCH_PAGE_SIZE,
        page: getVaspSearchPage(state$.value),
        name: getIsVaspSearchQuery(state$.value),
      },
      requestActionType,
    })),
    filter(({ accessToken }) => !!accessToken),
    switchMap(({ accessToken, payload, requestActionType }) =>
      ajax({
        url: api
          .getBaseUrlForTrading(accessToken ?? '')
          ?.concat('/crypto/vasps')
          .concat(queryArrayString(payload)),
        withCredentials: true, // include cookies
        headers: {
          ...api.getCommonHeaders(),
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      }).pipe(
        pluck('response'),
        map((data: { vasps: Vasp[]; totalPageCount: number }) => {
          if (requestActionType === getVaspsRequest.type) {
            return getVaspsSuccess(data);
          }

          return loadNextPageSuccess(data);
        }),
        backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
        catchError(handleAjaxError(action$, getVaspsFailure)),
      ),
    ),
  );

export default combineEpics(searchVaspEpic);
