import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SATOSHI_TEST_SLICE_NAME } from 'common/const/slices';
import { ApiError } from 'types/error';
import { SatoshiTestData } from 'types/satoshiTest';
import { IntentType } from 'common/const/moneyTransfer';
import { scientificToDecimal } from 'common/utils/math';
import initialState from './initialState';

const satoshiTestSlice = createSlice({
  name: SATOSHI_TEST_SLICE_NAME,
  initialState,
  reducers: {
    satoshiTestRequest: (
      state,
      _action: PayloadAction<{
        onSuccess: () => void;
        onFailure: () => void;
        intentId: string;
        intentType: IntentType;
      }>,
    ) => {
      state.isLoading = true;
    },
    satoshiTestSuccess: (state, action: PayloadAction<SatoshiTestData<number>>) => {
      state.isLoading = false;
      state.data = action.payload?.volume
        ? {
            ...action.payload,
            volume:
              typeof action.payload.volume === 'number'
                ? scientificToDecimal(action.payload.volume)
                : action.payload.volume,
          }
        : null;
    },
    satoshiTestFailure: (state, action: PayloadAction<ApiError>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetSatoshiTest: () => initialState,
  },
});

export default satoshiTestSlice;
