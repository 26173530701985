import { ParseError, parsePhoneNumber } from 'libphonenumber-js';
import * as formatting from 'common/utils/formatting';
import { endOfDay, isBefore, isWithinInterval, startOfDay } from 'date-fns';
import { PriceHistoryItem } from 'store/types/priceHistory';

const floatPattern = new RegExp(/^(-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/);
const dotEndingPattern = new RegExp(/^(-|\+)?([0-9]+\.)$/);
export const parseFloatStrict = (value: string): number | undefined => {
  const { decimalSeparator, groupingSeparator } = formatting.separators();
  const delocalizedValue = value.split(groupingSeparator).join('').replace(decimalSeparator, '.');
  if (floatPattern.test(delocalizedValue)) return Number(delocalizedValue);
  if (dotEndingPattern.test(delocalizedValue)) return Number(delocalizedValue.replace('.', ''));
  return;
};

/*
  Randomly skews the given delta so that it falls in the given interval:
  delta -> [3/4 * delta, 5/4 * delta]
*/
export const withSkew = (delta: number): number => {
  const skew = Math.random() * 0.25 * delta;
  const sign = Math.random() <= 0.5 ? -1 : 1;
  return delta + sign * skew;
};

// checks if we're in active hours
export const isOpened = (activeHours: { from: () => any; to: () => any }): boolean => {
  const openTime = activeHours.from();
  const closeTime = activeHours.to();

  let openedIntervals = [[openTime, closeTime]];
  if (isBefore(closeTime, openTime)) {
    openedIntervals = [
      [startOfDay(new Date()), closeTime],
      [openTime, endOfDay(new Date())],
    ];
  }
  return openedIntervals.some((interval) =>
    isWithinInterval(new Date(), { start: interval[0], end: interval[1] }),
  );
};

/* Adds a space between groups of four letters */
export const formatIban = (s: string): string => {
  try {
    const parts = [];
    const step = 4;
    for (let i = 0; i < s.length; i += step) {
      parts.push(s.substr(i, step));
    }
    return parts.join(' ');
  } catch (e) {
    return s;
  }
};

export const formatMobileNumber = (mobileNumber: string): string => {
  let formattedNumber = mobileNumber;
  try {
    const parsedNumber = parsePhoneNumber(mobileNumber).format('INTERNATIONAL');
    if (parsedNumber) {
      formattedNumber = parsedNumber;
    }
  } catch (error) {
    if (!(error instanceof ParseError)) {
      // eslint-disable-next-line
      console.log(error);
    }
  }
  return formattedNumber;
};

export const findMinMaxPrice = (priceHistory: PriceHistoryItem[]): { min: number; max: number } => {
  return priceHistory.reduce(
    (acc, value) => {
      acc.min = value.y < acc.min ? value.y : acc.min;
      acc.max = value.y > acc.max ? value.y : acc.max;
      return acc;
    },
    { min: priceHistory[0].y, max: priceHistory[priceHistory.length - 1].y },
  );
};

// Exponential backoff time utility
export const expBackoffTime = (attempt: number, baseTime = 1000): number => baseTime * 2 ** attempt;

export const getPriceDiff = (chartData: any[]): { absolute: number; relative: number } => {
  if (!chartData || !chartData[0]) return { absolute: 0, relative: 0 };

  const firstItem = chartData[0];
  const lastItem = chartData[chartData.length - 1];

  const absDiff = lastItem.close - firstItem.close;

  return {
    absolute: absDiff,
    relative: (100 * absDiff) / firstItem.close,
  };
};

/**
 * Converts a number in scientific notation to its full decimal representation as a string.
 * If the number is not in scientific notation, it returns the number as is.
 *
 * @param num - The number to convert.
 * @returns A string representing the full decimal representation of the number.
 *
 * @example
 * scientificToDecimal(1.23e4); // "12300"
 * scientificToDecimal(5.678e-3); // "0.005678"
 * scientificToDecimal(123); // "123"
 * scientificToDecimal(Infinity); // "Infinity"
 * scientificToDecimal(NaN); // "NaN"
 */
export function scientificToDecimal(num: number): string {
  // Handle special cases like NaN and Infinity
  if (!Number.isFinite(num)) {
    return String(num); // Convert special values directly to a string
  }

  const numStr = String(num); // Convert the number to a string for processing

  // If the number is not in scientific notation, return it as is
  if (!numStr.includes('e')) {
    return numStr;
  }

  // Split the scientific notation into the base and exponent parts
  const [base, exponent] = numStr.split('e').map((part) => part);

  // Further split the base into integer and decimal parts
  const [integerPart, decimalPart = ''] = base.split('.');

  const exp = Number(exponent); // Parse the exponent to a number

  if (exp > 0) {
    // Positive exponent: Shift the decimal point to the right
    // Fill the missing zeros if needed
    const shift = exp - decimalPart.length;
    return integerPart + decimalPart.padEnd(exp, '0');
  } else {
    // Negative exponent: Shift the decimal point to the left
    // Prepend the required number of zeros
    const zeros = '0'.repeat(Math.abs(exp) - 1);
    return `0.${zeros}${integerPart}${decimalPart}`;
  }
}
