import { ApiError } from 'types/error';
import { action } from './_common';

export const FETCH_USER_KYC_STATUS_RENEW_INFO_REQUEST = 'FETCH_USER_KYC_STATUS_RENEW_INFO_REQUEST';
export const FETCH_USER_KYC_STATUS_RENEW_INFO_SUCCESS = 'FETCH_USER_KYC_STATUS_RENEW_INFO_SUCCESS';
export const FETCH_USER_KYC_STATUS_RENEW_INFO_FAILURE = 'FETCH_USER_KYC_STATUS_RENEW_INFO_FAILURE';
export const fetchUserKycStatusRenewInfoRequest = () => action(FETCH_USER_KYC_STATUS_RENEW_INFO_REQUEST);
export const fetchUserKycStatusRenewInfoSuccess = (renewRequired: boolean) =>
  action(FETCH_USER_KYC_STATUS_RENEW_INFO_SUCCESS, { renewRequired });
export const fetchUserKycStatusRenewInfoFailure = () => action(FETCH_USER_KYC_STATUS_RENEW_INFO_FAILURE);

export const START_KYC_RENEW_PROCESS = 'START_KYC_RENEW_PROCESS' as const;
export const START_KYC_RENEW_PROCESS_SUCCESS = 'START_KYC_RENEW_PROCESS_SUCCESS' as const;
export const START_KYC_RENEW_PROCESS_FAILURE = 'START_KYC_RENEW_PROCESS_FAILURE' as const;
export const startKycRenewProcess = (documentType: 'idCard' | 'passport') =>
  action(START_KYC_RENEW_PROCESS, { documentType });
export const startKycRenewProcessSuccess = (code: string) =>
  action(START_KYC_RENEW_PROCESS_SUCCESS, { code });
export const startKycRenewProcessFailure = (error: ApiError) =>
  action(START_KYC_RENEW_PROCESS_FAILURE, { error });
