/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  PendingOrder,
  StakeUnstakeFlowRequestPayload,
  StakingBlockedModalStatus,
  StakingChartPoint,
  StakingCryptoInfo,
  StakingReward,
} from 'types/staking';
import { shouldLoadStakingInfo } from 'common/utils/staking';
import { stakingRewardAdapter } from 'store/slices/staking/adapter';
import { initialState } from './initialState';

const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    stakingInfoRequest(state) {
      if (!shouldLoadStakingInfo(state.overview.lastFetchedAt)) return;

      state.overview.isLoading = true;
    },
    stakingInfoSuccess(state, action: PayloadAction<{ stakingInfo: Record<string, StakingCryptoInfo> }>) {
      const {
        payload: { stakingInfo, ...rest },
      } = action;
      const aprNormalized: Record<string, StakingCryptoInfo> = {};
      Object.entries(stakingInfo).forEach(([key, value]) => {
        aprNormalized[key.toLowerCase()] = value;
      });
      state.overview = {
        info: aprNormalized,
        ...rest,
        isLoading: false,
        lastFetchedAt: new Date().toISOString(),
      };
    },
    stakingInfoFailure(state) {
      state.overview.isLoading = false;
    },
    stakeUnstakeFlowRequest(state, action: PayloadAction<StakeUnstakeFlowRequestPayload>) {
      state.stakeUnstake = {
        ...action.payload,
        isLoading: true,
      };
    },
    stakeUnstakeFlowRequestSuccess(state) {
      state.stakeUnstake.isSuccess = true;
      state.stakeUnstake.isLoading = false;
    },
    stakeUnstakeFlowRequestFailure(state) {
      state.stakeUnstake.isSuccess = false;
      state.stakeUnstake.isLoading = false;
    },
    resetStakeUnstakeState(state) {
      state.stakeUnstake = initialState.stakeUnstake;
    },
    stakingRewardsHistoryRequest(state) {
      state.rewardsHistory.loading = true;
      state.rewardsHistory.fullyLoaded = false;
      state.rewardsHistory.reference = undefined;

      // Reset all the items since we are about to fetch new ones.
      stakingRewardAdapter.removeAll(state.rewardsHistory);
    },
    stakingRewardsHistorySuccess(
      state,
      action: PayloadAction<{ orders: StakingReward[]; hasMore: boolean }>,
    ) {
      state.rewardsHistory.loading = false;
      state.rewardsHistory.fullyLoaded = !action.payload?.hasMore;
      state.rewardsHistory.reference = action.payload?.orders?.at(-1)?.reference;

      stakingRewardAdapter.addMany(state.rewardsHistory, action.payload?.orders);
    },
    stakingRewardsHistoryFailure(state) {
      state.rewardsHistory.loading = false;
    },
    stakingChartRequest(state) {
      state.chart.isFetching = true;
    },
    stakingChartSuccess(state, action: PayloadAction<StakingChartPoint[]>) {
      state.chart.items = action.payload;
      state.chart.isFetching = false;
    },
    stakingChartError(state) {
      state.chart.isFetching = false;
    },
    updateLastPointOfChart(state, action: PayloadAction<StakingChartPoint>) {
      state.chart.items[state.chart.items.length - 1] = action.payload;
    },
    stakingNextRewardRequest(state, action: PayloadAction<{ assetCode: string }>) {
      if (shouldLoadStakingInfo(state.nextRewardInfo[action.payload.assetCode]?.lastFetchedAt || null)) {
        state.nextRewardInfo[action.payload.assetCode] = {
          ...state.nextRewardInfo[action.payload.assetCode],
          isLoading: true,
          isErrorOccurred: false,
          date: null,
          expectedRewardVolume: 0,
          lastFetchedAt: null,
        };
      }
    },
    stakingNextRewardSuccess(
      state,
      action: PayloadAction<{ assetCode: string; date: null | string; expectedRewardVolume: number }>,
    ) {
      state.nextRewardInfo[action.payload.assetCode] = {
        isLoading: false,
        date: action.payload.date,
        expectedRewardVolume: action.payload.expectedRewardVolume,
        isErrorOccurred: false,
        lastFetchedAt: new Date().toISOString(),
      };
    },

    stakingNextRewardFailure(state, action: PayloadAction<{ assetCode: string }>) {
      if (!action?.payload?.assetCode) return;

      if (!state.nextRewardInfo) return;

      if (!state.nextRewardInfo[action.payload.assetCode]) return;

      state.nextRewardInfo[action.payload.assetCode].isLoading = false;
      state.nextRewardInfo[action.payload.assetCode].isErrorOccurred = true;
    },
    setStakingBlockedModalStatus(state, action: PayloadAction<StakingBlockedModalStatus>) {
      state.stakingBlockedModalStatus.isOpened = action.payload.isOpened;
      state.stakingBlockedModalStatus.type = action.payload.type;
    },
    fetchPendingOrdersRequest(state) {
      state.pendingOrders.isLoading = true;
    },
    fetchPendingOrdersSuccess(state, action: PayloadAction<{ items: PendingOrder[] }>) {
      state.pendingOrders.isLoading = false;
      state.pendingOrders.items = action.payload.items;
    },
    fetchPendingOrdersFailure(state) {
      state.pendingOrders.isLoading = false;
    },
    invalidateStakingState(state) {
      state.chart = initialState.chart;
      state.stakeUnstake = initialState.stakeUnstake;
      state.rewardsHistory = initialState.rewardsHistory;
      state.nextRewardInfo = initialState.nextRewardInfo;
      state.stakingBlockedModalStatus = initialState.stakingBlockedModalStatus;
      state.pendingOrders = initialState.pendingOrders;
    },
  },
});

export default stakingSlice;
