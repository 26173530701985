import { BaseTokens, ColorTokens, Theme, ThemeImages } from 'types/theme';

export const baseTokens: BaseTokens = {
  typography: {
    fontFamily: {
      openSans: "'Open Sans', sans-serif",
    },
    fontWeight: {
      regular: '400',
      medium: '600',
      strong: '700',
    },
    heading: {
      weight: {
        weak: '400',
        regular: '700',
      },
      size: {
        '1': { fontSize: '30px', lineHeight: '41px' },
        '2': { fontSize: '28px', lineHeight: '40px' },
        '3': { fontSize: '24px', lineHeight: '34px' },
        '4': { fontSize: '20px', lineHeight: '27px' },
      },
    },
    text: {
      xxsmall: { fontSize: '12px', lineHeight: '16px' },
      xsmall: { fontSize: '13px', lineHeight: '18px' },
      small: { fontSize: '14px', lineHeight: '19px' },
      standard: { fontSize: '16px', lineHeight: '22px' },
      large: { fontSize: '17.5px', lineHeight: '24px' },
      xlarge: { fontSize: '18px', lineHeight: '25px' },
    },
  },
  border: {
    radius: {
      none: 0,
      standard: '5px',
      large: '10px',
      button: '4px',
    },
    width: {
      standard: '1px',
      large: '2px',
    },
  },
  opacity: {
    disabled: 0.2,
    hover: 0.7,
    none: 1,
    full: 0,
  },
  iconography: {
    sizes: {
      micro: '18px',
      tiniest: '22px',
      regular: '28px',
      tiny: '31px',
      small: '36px',
      standard: '48px',
      large: '56px',
      larger: '64px',
    },
  },
  assets: {
    button: {
      small: { height: '30px', width: '105px' },
      standard: { height: '40px', width: '130px' },
      large: { height: '48px', width: '130px' },
      modal: { height: '48px', width: '280px' },
    },
    input: {
      standard: { height: '48px' },
    },
  },
};

export const makeTheme = (base: BaseTokens, color: ColorTokens, image: ThemeImages): Theme => ({
  ...base,
  color,
  image,
});
