/**
 * Updates the timestamp and incrementally increases the attempt count
 * from a given popup metadata string.
 *
 * @param {string} popupMetadata - A string containing the timestamp and attempt count, separated by a single comma.
 * @returns {string} - A new string with the updated timestamp and incremented attempt count, or a default with attempt count set to 1 if input is invalid.
 *
 * @example
 * // Valid input:
 * updateClosedBankMigrationPopup("2023-06-13T22:00:00Z,4");
 * // Output:
 * // "2024-12-30T15:35:20.000Z,5"
 *
 * @example
 * // Invalid attempt count:
 * updateClosedBankMigrationPopup("2023-06-13T22:00:00Z,invalid");
 * // Output:
 * // "2024-12-30T15:35:20.000Z,1"
 *
 * @example
 * // Invalid input (more than one comma):
 * updateClosedBankMigrationPopup("2023-06-13T22:00:00Z,4,extra");
 * // Output:
 * // "2024-12-30T15:35:20.000Z,1"
 */
const updateClosedBankMigrationPopup = (popupMetadata: string): string => {
  // Validate input: Must contain exactly one comma
  const commaCount = (popupMetadata?.match(/,/g) ?? []).length;

  if (commaCount !== 1) {
    // Invalid input: Return a default with current timestamp and attempt count of 1
    return `${new Date().toISOString()},1`;
  }

  // Split the input string into date and attempt count
  const [_, attempts] = popupMetadata.split(',');

  // Validate that the second part is a valid number
  const updatedAttempts = attempts && !Number.isNaN(+attempts.trim()) ? +attempts.trim() + 1 : 1;

  // Get the current timestamp in ISO 8601 format
  const newTimestamp = new Date().toISOString();

  // Return the updated string
  return `${newTimestamp},${updatedAttempts}`;
};

export default updateClosedBankMigrationPopup;
