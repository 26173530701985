import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleAsset } from 'store/slices/assets/actions';
import { getAsset } from 'store/slices/assets/selectors';
import { State } from 'store/types/store';
import { AssetClass, AssetReturnType, AssetSecurityClass } from 'types/assets';
import useDelistedAsset from './useDelistedAsset';

export const useAsset = <T extends AssetClass>(
  code: string,
  assetClass: T,
  securityClass: AssetSecurityClass | undefined,
): AssetReturnType<T> => {
  const asset = useSelector((state: State) => getAsset(state, assetClass, code));
  const delistedAsset = useDelistedAsset(code, assetClass, securityClass)
  const dispatch = useDispatch();

  useEffect(() => {
    if (!delistedAsset?.isDelisted)
      dispatch(fetchSingleAsset({ code, class: assetClass, securityClass: securityClass }));
  }, [delistedAsset?.isDelisted]);

  return delistedAsset?.isDelisted? delistedAsset: asset;
};
