import React, { ReactElement, useEffect, useRef } from 'react';
import { ErrorContainer, Input, InputLabel, InputsWrapper, RelativeContainer } from './styles';
import { isNumeric } from '../../utils/functional';

type Props = {
  value: string;
  label: string;
  inputLength: number;
  setValue: (newValue: string) => void;
  error: undefined | string;
  eraseError?: () => void;
};

type PasteEvent = React.ClipboardEvent<HTMLInputElement>;

const getChangedInput = (
  input: string,
  index: number,
  newValueAtIndex: string,
  inputLength: number,
): string => {
  const newStrArray = Array(inputLength).fill(' ');
  input.split('').forEach((str, idx) => {
    newStrArray[idx] = str;
  });

  if (newValueAtIndex.trim().length === 1) {
    newStrArray[index] = newValueAtIndex.trim();

    return newStrArray.join('');
  }

  if (newValueAtIndex.length > 1) {
    newStrArray[index] = newValueAtIndex[newValueAtIndex.length - 1];

    return newStrArray.join('');
  }
  if (newValueAtIndex.length === 1) {
    newStrArray[index] = newValueAtIndex;

    return newStrArray.join('');
  }

  newStrArray[index] = ' ';

  return newStrArray.join('');
};

const SecurityCodeInput = ({
  value,
  label,
  inputLength,
  setValue,
  error,
  eraseError,
}: Props): ReactElement => {
  const array = Array(inputLength)
    .fill(null)
    .map((_, idx) => idx);

  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    inputRefs?.current[0]?.focus();
  }, []);

  useEffect(() => {
    if (value === '') {
      array.forEach((_item, index) => {
        inputRefs.current[index].value = ' ';
      });
      inputRefs.current[0]?.focus();
    }
  }, [value]);

  const handleChangeInput = (inputIndex: number, newValue: string) => {
    if (Number.isNaN(Number(newValue))) return;

    if (error && eraseError) eraseError();

    const computedValue = getChangedInput(value, inputIndex, newValue, inputLength);
    setValue(computedValue);

    let indexToMove = inputIndex;

    if (newValue !== '') {
      indexToMove = inputIndex + 1;
    }

    if (value[inputIndex] === ' ' && newValue === '') {
      indexToMove = inputIndex - 1;
    }

    inputRefs.current[indexToMove]?.focus();
  };

  const handlePastInput = (event: PasteEvent) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    if (!pastedText) {
      return;
    }
    const isConsistOfNumbers = isNumeric(pastedText);
    const isValidLength = pastedText.length === inputLength;
    if (isConsistOfNumbers && isValidLength) {
      setValue(pastedText);
      inputRefs.current[inputLength - 1]?.focus();
    }
  };

  return (
    <div>
      <InputLabel isErrorOccurred={!!error}>{label}</InputLabel>
      <RelativeContainer>
        <InputsWrapper>
          {array.map((idx) => (
            <Input
              data-test-id={`input2fa.${idx}`}
              onPaste={handlePastInput}
              key={idx}
              isErrorOccurred={!!error}
              isFilled={value[idx] !== ' ' && !!value[idx]}
              value={value[idx]}
              onChange={(e) => handleChangeInput(idx, e.target.value)}
              ref={(el) => {
                if (!el) return;

                inputRefs.current[idx] = el;
              }}
              type="tel"
            />
          ))}
        </InputsWrapper>
      </RelativeContainer>
      {error && <ErrorContainer>{error}</ErrorContainer>}
    </div>
  );
};

SecurityCodeInput.defaultProps = {
  eraseError: () => null,
};

export default SecurityCodeInput;
