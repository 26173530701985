import vaspAdapter from './adapter';

const initialState = vaspAdapter.getInitialState({
  query: '',
  isLoading: false,
  page: 1,
  isEndReached: false,
  error: undefined,
  frequentlyAskedVasps: [],
});

export default initialState;
