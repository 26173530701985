/* eslint-disable @typescript-eslint/no-unsafe-return */
import { isEqual } from 'lodash';
import { billFormFields, kycEnvironments, resolveCountry } from 'common/const';
import { getIsKycDone } from 'store/selectors/auth';
import { getKycEnvironment } from 'store/selectors/environment';
import { getAcceptedTermsAndConditionsForCountry } from 'store/selectors/legalAgreements';
import { isAustria } from 'common/utils/residency';
import { initialKycFormData } from 'store/reducers/forms';
import { RootState } from 'types/common';
import { AT_RELATED_FIELDS } from 'common/const/kycAtData';
import { State } from 'store/types/store';
import { InstantDepositDataType } from 'scenes/MoneyTransfer/Deposit/Instant/types';

export const getAcceptedTermsAndConditionsForCountryInKycFormSubmit = (state: State) =>
  state.forms.acceptedTermsAndConditionsForCountryInKycFormSubmit;

export const getKycCountry = (state: State) => state.forms.kycFormData.country?.trim();

export const getTermsAndConditionsCountry = (state: State): string => {
  const country = getIsKycDone(state)
    ? // If KYC is done, use a country user accepted T&C for (set on `/kycformsubmit`).
      // Note: Old users have this set as `undefined` (so DE basically).
      (getAcceptedTermsAndConditionsForCountry(state as RootState)?.toUpperCase() ??
      // fallback on country in KYC form form submit (if KYC gets confirmed in the same login
      // session and acceptedTermsAndConditionsForCountry isn't yet rehydrated from the backend)
      getAcceptedTermsAndConditionsForCountryInKycFormSubmit(state)?.toUpperCase())
    : // otherwise, use a country specified in KYC form
      getKycCountry(state)?.toUpperCase();

  return resolveCountry(country);
};

export const getFormData = (state: State, includeBillInfo: boolean) => {
  const kycEnvironment = getKycEnvironment();
  const KYC_SANDBOX = kycEnvironment === kycEnvironments.SANDBOX;
  const acceptedTermsAndConditionsForCountry = getTermsAndConditionsCountry(state);
  const formData = {
    ...state.forms.kycFormData,
    acceptedTermsAndConditionsForCountry,
    professionalField: state.forms.kycFormData.professionalField || null,
  };
  if (!includeBillInfo) {
    billFormFields.forEach((fieldName) => {
      delete formData[fieldName];
    });
  }

  if (!isAustria(formData.country)) {
    AT_RELATED_FIELDS.forEach((field) => delete formData[field]);
  }
  // !!! Sandbox setting !!!
  if (KYC_SANDBOX) {
    let firstName = formData.firstName.trim();
    let sandboxFirstName = 'X-MANUALTEST-HAPPYPATH';
    if (firstName.startsWith('X-')) {
      const testName = firstName.split(' ');
      if (testName.length > 1) {
        [firstName, sandboxFirstName] = testName;
      }
    }
    formData.firstName = firstName;
    formData.sandboxFirstName = sandboxFirstName;
  }
  return formData;
};

export const getKycFormData = (state: State) => state.forms.kycFormData;

export const getIsKycFormEmpty = (state: State) => {
  const { currentStep } = state.forms.kycMeta;
  return currentStep === 0 && isEqual(state.forms.kycFormData, initialKycFormData);
};

export const getTransactionToken = (state: State) => state.forms.kycMeta.transactionToken;

export const getIsKycStarted = (state: State) => state.forms.kycMeta.isKycStarted;

export const getKycFormMeta = (state: State) => state.forms.kycMeta;

export const getIsSecuritiesKycFailed = (state: State) => state.forms.stocksKycMeta.isFailed;

export const devicePairing2fa = (state: State) => state.auth.devicePairing2fa;

export const getTaxResidency = (state: State) => state.forms.taxResidency;

export const getIsTaxResidencyInitiallyFetched = (state: State) => state.forms?.isInitialTaxFetched;

export const getKnowledgeLevelQuestions = (state: State) => ({
  hasTradedSecurities: state.forms.stocksKycFormData.hasTradedSecurities,
  hasTradedCommonIndicesOrComparableEtfs:
    state.forms.stocksKycFormData.hasTradedCommonIndicesOrComparableEtfs,
  hasTradedNonCommonIndicesOrLeveragedEtfs:
    state.forms.stocksKycFormData.hasTradedNonCommonIndicesOrLeveragedEtfs,
});

export const getSecuritiesKycFormData = (state: State) => state.forms.stocksKycFormData;

export const getAddressConfirmData = (state: State) => {
  return {
    firstName: state.forms.kycFormData.firstName,
    lastName: state.forms.kycFormData.lastName,
    billStreetNumber: state.forms.kycFormData.billStreetNumber,
    billStreet: state.forms.kycFormData.billStreet,
    billPostNumber: state.forms.kycFormData.billPostNumber,
    billCity: state.forms.kycFormData.billCity,
    billCountry: state.forms.kycFormData.billCountry,
  };
};

export const getRenewKycFormData = (state: RootState) => state.forms.renewKycFormData;
export const getStocksKnowledgeAnswers = (state: RootState) => state.forms.stocksKnowledgeData;

export const getIsEIdKycMethod = (state: State) => state.forms.kycFormData.kycMethod === 'Eid';

export const getTopUpData = (state: State): InstantDepositDataType => state.forms.topUpData;
