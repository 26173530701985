import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, map, pluck, switchMap, tap } from 'rxjs/operators';
import { RootEpic } from 'types/common';
import { getAccessToken } from 'store/selectors/auth';
import { backoff } from 'common/utils/rxjs-operators';
import { handleAjaxError } from 'store/epics/auth';
import { IntentPatchActions, IntentPostActions, SpecificFlowIntentDetails } from 'store/types/moneyTransfer';
import { Observable } from 'rxjs';

export const getIntentPostEpic =
  ({ requestRoute, actions, errorMessage }: SpecificFlowIntentDetails<IntentPostActions>): RootEpic =>
  (action$, state$, { ajax, api }) =>
    action$.pipe(
      filter(actions.request.match),
      map(({ payload: { payload, onTravelRuleDiscard, onTravelRuleDemand, onFailure } }) => ({
        accessToken: getAccessToken(state$.value),
        payload,
        onTravelRuleDiscard,
        onTravelRuleDemand,
        onFailure,
      })),
      filter(({ accessToken }) => !!accessToken),
      switchMap(({ accessToken, payload, onTravelRuleDiscard, onTravelRuleDemand, onFailure }) =>
        ajax({
          url: api.getBaseUrlForTrading(accessToken ?? '')?.concat(requestRoute),
          method: 'POST',
          body: payload,
          withCredentials: true, // include cookies
          headers: {
            ...api.getCommonHeaders(),
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }).pipe(
          pluck<AjaxResponse, { intentId: string; isWalletVerificationRequired: boolean }>('response'),
          tap((data) => {
            if (data.isWalletVerificationRequired) {
              onTravelRuleDemand();
            } else {
              onTravelRuleDiscard();
            }
          }),
          map(actions.success),
          backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
          catchError((error: { response: { code: string } }, source: Observable<unknown>) => {
            if (onFailure) onFailure();
            return handleAjaxError(action$, actions.failure, {}, { message: errorMessage })(error, source);
          }),
        ),
      ),
    );
export const getIntentPatchEpic =
  ({ requestRoute, actions, errorMessage }: SpecificFlowIntentDetails<IntentPatchActions>): RootEpic =>
  (action$, state$, { ajax, api }) =>
    action$.pipe(
      filter(actions.request.match),
      map(({ payload: { onTravelRuleDemand, onTravelRuleDiscard, onFailure, ...payload } }) => ({
        accessToken: getAccessToken(state$.value),
        payload,
        onTravelRuleDemand,
        onTravelRuleDiscard,
        onFailure,
      })),
      filter(({ accessToken }) => !!accessToken),
      switchMap(({ accessToken, payload, onTravelRuleDemand, onTravelRuleDiscard, onFailure }) =>
        ajax({
          url: api.getBaseUrlForTrading(accessToken ?? '')?.concat(requestRoute),
          method: 'PATCH',
          body: payload,
          withCredentials: true, // include cookies
          headers: {
            ...api.getCommonHeaders(),
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }).pipe(
          pluck<AjaxResponse, { intentId: string; isProofOfOwnershipRequired: boolean }>('response'),
          tap(({ isProofOfOwnershipRequired }) => {
            if (isProofOfOwnershipRequired) {
              onTravelRuleDemand();
            } else {
              onTravelRuleDiscard();
            }
          }),
          map(actions.success),
          backoff(2, 1000, (error: AjaxError) => error.status === 0 || error.status >= 500),
          catchError((error: { response: { code: string } }, source: Observable<unknown>) => {
            if (onFailure) onFailure();
            return handleAjaxError(action$, actions.failure, {}, { message: errorMessage })(error, source);
          }),
        ),
      ),
    );
