import styled from 'styled-components';
import { Theme } from 'types/theme';
import { CheckboxTextWrapper } from 'common/components/Modal/Modal';
import { dimensions } from 'common/styles';
import InboxAttachment from 'common/components/InboxAttachment';

export const Title = styled.h2`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const Body = styled.div`
  padding-top: 24px;
`;

export const BodyText = styled.div.attrs({
  className: 'small-text',
})`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
  margin-bottom: 24px;
`;

export const CheckboxText = styled(CheckboxTextWrapper)`
  white-space: pre-line;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const DocumentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${dimensions.btnMargin}px;
  margin-top: 24px;

  & button {
    width: fit-content;
  }

  & span:nth-child(2) {
    max-width: 100%;
    text-overflow: clip;
    white-space: break-spaces;
    text-align: left;
  }
`;

export const DocumentAttachment = styled(InboxAttachment)`
  margin: 0;
  justify-content: flex-start;
`;

export const ButtonWrapper = styled.div`
  max-width: 343px;
  width: 100%;
`;
