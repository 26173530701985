import { useSelector } from 'react-redux';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { getCryptoTermsAndConditionsData } from 'store/selectors/termsAndConditions';
import { getUser } from 'store/selectors/auth';
import { CheckboxText, PopupData, SupportedLanguages, SupportedLanguagesAT } from 'types/termsAndConditions';

const getTncUpdateFallback = (t: TFunction<'translation', undefined, 'translation'>, termsUrl: string) => ({
  title: t('updatedTermsAndConditions.title'),
  text: `${t('updatedTermsAndConditions.header')}\n\n${t('updatedTermsAndConditions.mainText')}`,
  checkbox: [
    { text: t('updatedTermsAndConditions.fallbackTnCCheckbox.a') },
    { text: t('updatedTermsAndConditions.fallbackTnCCheckbox.b'), url: termsUrl },
    { text: t('updatedTermsAndConditions.fallbackTnCCheckbox.c') },
  ],
});

const getCheckboxTncFallback = (t: TFunction<'translation', undefined, 'translation'>, termsUrl: string) => ({
  checkbox: [
    { text: t('updatedTermsAndConditions.fallbackTnCCheckbox.a') },
    { text: t('updatedTermsAndConditions.fallbackTnCCheckbox.b'), url: termsUrl },
    { text: t('updatedTermsAndConditions.fallbackTnCCheckbox.c') },
  ],
});

const useGeneralTnCText = (
  dataKey: 'tncUpdate' | 'kyc' | 'staking',
): PopupData | { checkbox: CheckboxText[] } | null => {
  const { t } = useTranslation();
  const locale = t('l10n.lang');
  const { address } = useSelector(getUser);
  const termsAndConditionsData = useSelector(getCryptoTermsAndConditionsData);

  if (locale) {
    let key = `${locale}_DE` as SupportedLanguages;
    if (address?.country === 'AT') {
      key = `${locale}_AT` as SupportedLanguagesAT;
    }
    if (dataKey === 'tncUpdate') {
      return (
        termsAndConditionsData?.popupData?.tncUpdate?.[key] ??
        getTncUpdateFallback(
          t,
          termsAndConditionsData?.latestTerms?.termsUrl ?? 'https://bisonapp.com/dokumente/agb',
        )
      );
    }
    if (dataKey === 'kyc' || dataKey === 'staking') {
      return (
        termsAndConditionsData?.popupData?.[dataKey]?.[key] ??
        getCheckboxTncFallback(
          t,
          termsAndConditionsData?.latestTerms?.termsUrl ?? 'https://bisonapp.com/dokumente/agb',
        )
      );
    }
  }
  return null;
};

export default useGeneralTnCText;
