import * as formatting from 'common/utils/formatting';
import { fiat as toFiat } from 'common/utils/formatting';
import { PortfolioAsset } from 'types/portfolio';
import { Crypto } from 'types/assets';
import { AssetNextStakingReward, StakingCryptoInfo } from 'types/staking';
import { differenceInDays, format } from 'date-fns';
import { parseISOUTCStringToUTC } from 'common/utils/date';
import I18n from 'i18next';
import { Dictionary } from '@reduxjs/toolkit';

export const extractStakingOverviewItem = (
  { entity, stakeVolume, volume, stakeValue, displayName, availableVolume }: PortfolioAsset,
  decimalPlaces: number | undefined,
  stakingCryptoInfo: StakingCryptoInfo,
) => ({
  currency: entity,
  amount: `${formatting.crypto(volume, decimalPlaces)} ${entity.toUpperCase()}`,
  stakingAmount: `${formatting.crypto(stakeVolume, decimalPlaces, {
    strip_insignificant_zeros: true,
  })} ${entity.toUpperCase()}`,
  stakingValue: toFiat(stakeValue, { adaptivePrecision: true }),
  aprInfo: `${stakingCryptoInfo?.minApr}–${stakingCryptoInfo?.maxApr}%`,
  minStakeAmount: stakingCryptoInfo?.minStakeAmount,
  displayName,
  // checking if totalValue is equal to stakedValue may not take reserved values for LO for example
  isStakeOperationUnavailable: availableVolume < stakingCryptoInfo?.minStakeAmount || stakeVolume === volume,
});

export const extractStakingItemWithNextRewardData = (
  crypto: PortfolioAsset,
  cryptoAssets: Dictionary<Crypto>,
  cryptoAPR: StakingCryptoInfo,
  nextRewardInfo: AssetNextStakingReward,
) => {
  const additionalData: {
    expectedRewardVolume: string;
    date: string | null;
  } = {
    expectedRewardVolume: '0',
    date: null,
  };

  if (nextRewardInfo) {
    additionalData.expectedRewardVolume = `${formatting.crypto(
      nextRewardInfo.expectedRewardVolume,
      cryptoAssets[crypto.entity?.toLowerCase()]?.cryptoDetails?.decimalPlaces,
    )} ${crypto.entity.toUpperCase()}`;

    additionalData.date = nextRewardInfo.date
      ? format(parseISOUTCStringToUTC(nextRewardInfo.date), I18n.t('l10n.dateFormat'))
      : null;
  }

  return {
    ...extractStakingOverviewItem(
      crypto,
      cryptoAssets[crypto.entity?.toLowerCase()]?.cryptoDetails?.decimalPlaces,
      cryptoAPR,
    ),
    ...additionalData,
  };
};

export const extractStakingItemBasicInfo = (cryptoAsset: Crypto, cryptoAPR: StakingCryptoInfo) => ({
  currency: cryptoAsset.code,
  aprInfo: `${cryptoAPR?.minApr}–${cryptoAPR?.maxApr}%`,
  minStakeAmount: cryptoAPR?.minStakeAmount,
  displayName: cryptoAsset.displayName,
});

export const findAvailableOrBuyableStakingAssets = (
  stakingInfoAllCryptos: Record<string, StakingCryptoInfo>,
  cryptoInstruments: PortfolioAsset[],
  cryptoAssets: Dictionary<Crypto>,
) => {
  const availableForStakingAssets: ReturnType<typeof extractStakingOverviewItem>[] = [];
  const buyableForStakingAssets: ReturnType<typeof extractStakingItemBasicInfo>[] = [];
  Object.keys(stakingInfoAllCryptos).forEach((crypto) => {
    if (stakingInfoAllCryptos[crypto].isStakingEnabled) {
      const portfolioItem = cryptoInstruments.find(
        (item) => item.entity?.toLowerCase() === crypto.toLowerCase(),
      );
      if (portfolioItem && portfolioItem.availableVolume >= stakingInfoAllCryptos[crypto].minStakeAmount) {
        availableForStakingAssets.push(
          extractStakingOverviewItem(
            portfolioItem,
            cryptoAssets[portfolioItem.entity?.toLowerCase()]?.cryptoDetails?.decimalPlaces,
            stakingInfoAllCryptos[crypto.toLowerCase()],
          ),
        );
      } else {
        const cryptoAsset = cryptoAssets[crypto.toLowerCase()];
        if (cryptoAsset) {
          buyableForStakingAssets.push(
            extractStakingItemBasicInfo(cryptoAsset, stakingInfoAllCryptos[crypto.toLowerCase()]),
          );
        }
      }
    }
  });
  return { availableForStakingAssets, buyableForStakingAssets };
};

export const getUnstakePeriodValueText = (pendingUntil: Date) => {
  if (!pendingUntil) return null;

  const selectedItemDiffInDays = differenceInDays(pendingUntil, new Date());
  let unstakePeriodValueText = I18n.t('unstaking.infoModal.periodValueLessThanOneDay');

  if (!!selectedItemDiffInDays && selectedItemDiffInDays > 1) {
    unstakePeriodValueText = I18n.t('unstaking.infoModal.periodValue', { days: selectedItemDiffInDays });
  }
  return unstakePeriodValueText;
};
