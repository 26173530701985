import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CRYPTO_DEPOSIT_TRAVEL_RULE_SLICE_NAME } from 'common/const/slices';
import { ApiError } from 'types/error';
import { IntentResponse } from 'types/cryptoDepositTravelRule';
import initialState from './initialState';

const cryptoDepositTravelRuleSlice = createSlice({
  name: CRYPTO_DEPOSIT_TRAVEL_RULE_SLICE_NAME,
  initialState,
  reducers: {
    intentPostRequest: (
      state,
      _action: PayloadAction<{
        payload: { intentId: string };
        onTravelRuleDemand: () => void;
        onTravelRuleDiscard: () => void;
        onFailure?: () => void;
      }>,
    ) => {
      state.isIntentLoading = true;
    },
    intentPostSuccess: (state, action: PayloadAction<IntentResponse>) => {
      state.isIntentLoading = false;
      state.intent = action.payload;
    },
    intentPostFailure: (state, action: PayloadAction<ApiError>) => {
      state.isIntentLoading = false;
      state.error = action.payload;
    },
    intentPatchRequest: (
      state,
      _action: PayloadAction<{
        isSelfHostedWallet: boolean;
        vaspId: string | null;
        intentId: string;
        onTravelRuleDemand: () => void;
        onTravelRuleDiscard: () => void;
        onFailure?: () => void;
      }>,
    ) => {
      state.isIntentLoading = true;
    },
    intentPatchSuccess: (
      state,
      action: PayloadAction<{ intentId: string; IsProofOfOwnershipRequired: boolean }>,
    ) => {
      state.isIntentLoading = false;
      state.isProofOfOwnershipRequired = action.payload.IsProofOfOwnershipRequired;
    },
    intentPatchFailure: (state, action: PayloadAction<ApiError>) => {
      state.isIntentLoading = false;
      state.error = action.payload;
    },
    resetCryptoDepositTravelRule: () => initialState,
  },
});

export default cryptoDepositTravelRuleSlice;
