import { State } from 'store/types/store';
import { CRYPTO_WITHDRAWAL_SLICE_NAME } from 'common/const/slices';

const cryptoWithdrawalState = (state: State) => state[CRYPTO_WITHDRAWAL_SLICE_NAME];

export const getCryptoWithdrawOrder = (state: State) => cryptoWithdrawalState(state).cryptoWithdrawOrder;

export const getCryptoWithdrawInitPayload = (state: State) =>
  cryptoWithdrawalState(state).cryptoWithdrawInitPayload;

export const getCryptoWithdrawVaspId = (state: State) => cryptoWithdrawalState(state).vaspId;

export const getCryptoWithdrawIntentId = (state: State) => cryptoWithdrawalState(state).intentId;

export const getIsCryptoWithdrawIntentLoading = (state: State) =>
  cryptoWithdrawalState(state).isIntentLoading;

export const getCode2fa = (state: State) => cryptoWithdrawalState(state).code2fa;
