import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { errorMessages } from 'common/apiErrors';

type WindowTyped = {
  env?: {
    SENTRY_DSN: string;
  };
};

export default () => {
  const windowTyped = window as unknown as WindowTyped;

  if (!windowTyped?.env?.SENTRY_DSN) return;

  // remove for testing sentry locally
  if (window.location.hostname === 'localhost') return;

  Sentry.init({
    dsn: windowTyped.env.SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // In production we are sending 2% of errors cause of possible undesirable load for our production app
    // https://docs.sentry.io/platforms/javascript/guides/bun/configuration/sampling/
    tracesSampleRate: window.env.ENVIRONMENT === 'production' ? 0.02 : 1.0,
    environment: window.env.ENVIRONMENT,
    beforeBreadcrumb(breadcrumb) {
      return breadcrumb?.category === 'xhr' && breadcrumb?.data?.status_code === 0 ? null : breadcrumb;
    },
  });
};

export function responseInterceptor(response: AxiosResponse) {
  return response;
}

export function errorResponseInterceptor(error: AxiosError): Promise<never> {
  // Don't log cancel request and Check if there is an actual response (otherwise the request has been cancelled)
  if (!error.response) return Promise.reject(error);

  const { code } = error;

  if (code === 'ECONNABORTED') return Promise.reject(error);

  const { status } = error.response;

  // Skip status codes 0, 401 and 403 - we do not want to log that to Sentry.
  if (status === 0) return Promise.reject(error);

  if (status === 401) return Promise.reject(error);

  if (status === 403) return Promise.reject(error);

  const unknownCode = !errorMessages[code ?? ''];

  // Create message log to sentry if this is an unexpected error
  const requestInfo = {
    method: `${error.config?.method}`,
  };
  const requestHeaders = {
    ...error.response?.headers,
    authorization: undefined,
    nonce: undefined,
  };
  const responseInfo = {
    code: !unknownCode ? code : 'no code',
    status,
    data: error.response?.data,
  };

  Sentry.withScope((scope) => {
    scope.setExtras({
      request: JSON.stringify(requestInfo),
      requestHeaders: JSON.stringify(requestHeaders),
      response: JSON.stringify(responseInfo),
    });
    scope.setTag('status', status);
    Sentry.captureMessage(
      `Unexpected API error: ${(error.response?.request as unknown as { responseURL: string })?.responseURL ?? 'No response URL'}`,
    );
  });

  // Reject the promise to let the calling code handle the error
  return Promise.reject(error);
}

export const withSentryProfiler = (WrappedComponent: React.ComponentType) =>
  Sentry.withProfiler(WrappedComponent);
