import React from 'react';
import CookiePopup from 'common/components/Modal/CookiePopup/CookiePopup';
import BrazeProvider from 'providers/Braze';
import MaintenanceProvider from 'providers/Maintenance';
import ModalProvider from 'providers/Modal/ModalProvider';
import TopBarProvider from 'providers/TopBar/TopBarProvider';
import NotificationProvider from 'providers/Notification';
import ThemeProvider from 'providers/Theme';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Action, Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import AppWrapper from 'scenes/AppWrapper';
import { withSentryProfiler } from 'common/utils/sentryUtilities';

type Props = {
  store: Store<unknown, Action>;
  persistor: Persistor;
  isCookieConsent: boolean;
};

const App = ({ store, persistor, isCookieConsent }: Props) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <BrazeProvider>
          <ThemeProvider>
            <NotificationProvider>
              <MaintenanceProvider>
                <ModalProvider>
                  <TopBarProvider>
                    {!isCookieConsent && <CookiePopup />}
                    <AppWrapper />
                  </TopBarProvider>
                </ModalProvider>
              </MaintenanceProvider>
            </NotificationProvider>
          </ThemeProvider>
        </BrazeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default withSentryProfiler(App);
