import { take, put, call, select, fork, all, race, takeLatest, delay } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import { authFetchEntity } from 'store/sagas/auth';
import * as notificationBannerActions from 'store/actions/notificationBanner';
import * as api from 'common/api';
import * as actions from 'store/actions/orders';
import * as authActions from 'store/actions/auth';
import { invalidatePortfolio } from 'store/slices/portfolio/actions';
import { getReservation, getOrderTypeData, getCurrentAmount, getFocusData } from '../selectors/orders';

export const fetchTrade = authFetchEntity.bind(null, actions.initOrder, api.initOrder, false);
export const fetchPriceQuote = authFetchEntity.bind(null, actions.priceQuote, api.priceQuote, false);
export const reserveOrder = authFetchEntity.bind(null, actions.reserveOrder, api.reserveOrder, false);
export const submitOrder = authFetchEntity.bind(null, actions.submitOrder, api.submitOrder, true);

function* reserveUserOrder() {
  const { isBuyOrder, currency, entity } = yield select(getOrderTypeData);
  const { amount, amountType } = yield select(getCurrentAmount);
  const { error } = yield call(reserveOrder, {
    isBuyOrder,
    currency,
    entity,
    amount: amount[amountType],
    amountType,
  });
  
  if (error) {
    yield put(
      notificationBannerActions.notifyError({
        message: error.message,
      }),
    );
  }
}

function* submitUserOrder() {
  const { orderPreview } = yield select(getReservation);
  yield call(submitOrder, {
    reserveOrderData: orderPreview,
  });
}

/*
    Main buy and sell order saga
*/

function* initializeTrade(): Saga<void> {
  const { isBuyOrder, currency, entity } = yield select(getOrderTypeData);
  const { error } = yield call(fetchTrade, {
    isBuyOrder,
    currency,
    entity,
  });
  if (error) {
    yield put(
      notificationBannerActions.notifyError({
        message: error.message,
      }),
    );
  }
}

/*
    Keep the price quote updated once the order session has started
*/
function* priceQuoting(): Saga<void> {
  while (true) {
    const { userInput } = yield race({
      interval: delay(5 * 1000),
      userInput: take(actions.REFRESH_QUOTE),
    });
    // Check if we have new user inputs as amounts, otherwise just refresh the values from the store
    let { amount, amountType, validAmounts } = yield select(getCurrentAmount);
    const { isBuyOrder, entity, currency } = yield select(getOrderTypeData);
    const { fieldInFocus, lastEditedField } = yield select(getFocusData);

    if (userInput) {
      amount = userInput.amount;
      amountType = userInput.amountType;
      validAmounts = true;
    }

    if (validAmounts) {
      let definedAmountType;

      const keys = Object.keys(amount);

      if (lastEditedField) {
        definedAmountType = lastEditedField;
      }
      if (fieldInFocus) {
        definedAmountType = fieldInFocus;
      }

      if (!definedAmountType) {
        definedAmountType = amountType;
      }

      if (keys.length === 1) {
        definedAmountType = keys[0];
      }

      const resultAmountType = definedAmountType;
      const resultAmount = amount[definedAmountType];

      const { error, quote, cancel } = yield race({
        quote: call(fetchPriceQuote, {
          entity,
          isBuyOrder,
          currency,
          amount: resultAmount,
          amountType: resultAmountType,
        }),
        cancel: take('USER_INPUT_CHANGED'),
      });

      if (error) {
        yield put(
          notificationBannerActions.notifyError({
            message: error.message,
          }),
        );
      }
    }
  }
}

function* waitForTimeout(validForSeconds) {
  const { submit } = yield race({
    submit: take(actions.SUBMIT_USER_ORDER), // Submitting cancels the timeout
    timeout: delay(validForSeconds * 1000),
  });
  if (!submit) {
    yield put(actions.reservationTimeout());
  }
}

/*
    With a reserved order the user has a short amount of time to confirm or cancel
*/
function* orderSubmitProcess(): Saga<void> {
  yield take(actions.SUBMIT_USER_ORDER);
  yield fork(submitUserOrder);
  const { success } = yield race({
    success: take(actions.SUBMIT_ORDER.SUCCESS),
    fail: take(actions.SUBMIT_ORDER.FAILURE),
  });
  if (success) {
    yield put(invalidatePortfolio());
  }
}

/*
    With a valid price quote, the user can reserve an order
*/
function* orderEntryProcess(): Saga<void> {
  while (true) {
    const reserveAction = yield take(actions.RESERVE_USER_ORDER);
    yield fork(reserveUserOrder, reserveAction);
    const { success } = yield race({
      success: take(actions.RESERVE_ORDER.SUCCESS),
      error: take(actions.RESERVE_ORDER.FAILURE),
    });
    if (success) {
      const {
        auxInfo: { validForSeconds },
      } = yield select(getReservation);
      // At this stage the user either confirms the order, cancels or the time runs out
      yield fork(waitForTimeout, (validForSeconds as number) + 1); // 1 second buffer to compensate for potential animation delays and such
      yield race({
        confirm: call(orderSubmitProcess),
        cancelReservation: take(actions.CANCEL_USER_SUBMIT),
        timeout: take(actions.RESERVATION_TIMEOUT),
      });
    }
  }
}

function* buySellProcessInner(): Saga<void> {
  yield fork(initializeTrade);
  yield take(actions.INIT_ORDER.SUCCESS);
  yield fork(priceQuoting);
  yield call(orderEntryProcess);
}

function* buySellProcess(): Saga<void> {
  yield take(actions.START_ORDER_SESSION);
  while (true) {
    yield race({
      buySellProcessInner: call(buySellProcessInner),
      switchMode: take([actions.SWITCH_ORDER_MODE, authActions.INVALIDATE_ACCOUNT_DATA]),
    });
  }
}

function* watchOrders() {
  while (true) {
    yield race({
      buySellProcess: call(buySellProcess),
      endBuySellProcess: take(actions.END_ORDER_SESSION),
    });
  }
}

function* handleUserInput(action: any): Saga<void> {
  const { amount, amountType } = action;
  yield delay(300);
  yield put(actions.refreshQuote(amount, amountType));
  yield race({
    success: take(actions.PRICE_QUOTE.SUCCESS),
    failure: take(actions.PRICE_QUOTE.FAILURE),
  });
  yield put(actions.userInputChangeFinished());
}

function* watchUserInput(): Saga<void> {
  yield takeLatest(actions.USER_INPUT_CHANGED, handleUserInput);
}

export default function* root(): Saga<void> {
  yield all([fork(watchOrders), fork(watchUserInput)]);
}
