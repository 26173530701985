import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { AppReduxEpicMiddleware, State } from 'store/types/store';
import { isPresent } from 'safetypings';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  pluck,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { getAccessToken, getAccountType } from 'store/selectors/auth';
import { Observable, from, interval, of, zip } from 'rxjs';
import { PayloadAction } from '@reduxjs/toolkit';
import I18n from 'i18next';
import {
  createTradingRule,
  createTradingRuleError,
  createTradingRuleSuccess,
  deleteTradingRule,
  deleteTradingRuleError,
  deleteTradingRuleSuccess,
  subscribeToTradingRules,
  subscribeToTradingRulesSuccess,
  unsubscribeFromTradingRules,
} from 'store/slices/tradingRules/actions';
import {
  LimitOrderData,
  StopOrderData,
  TradingRuleCreateLimitModel,
  TradingRuleCreateStopModel,
} from 'types/tradingRules';
import { TRADING_RULES_FETCH_INTERVAL_IN_MS } from 'common/const/tradingRules';
import {
  TradingRuleDeleteAction,
  TradingRuleDeleteAction$,
  TradingRulesCreateAction,
  TradingRulesCreateAction$,
} from 'store/slices/tradingRules/action-types';
import { paths } from 'common/urls';
import { accountTypes } from 'common/const';
import * as api from '../../common/api';
import { handleAjaxError } from './auth';
import * as notificationBannerActions from '../actions/notificationBanner';

// GET ALL
const fetchTradingRulesIntervalEpic = (action$: Observable<PayloadAction>, state$: StateObservable<State>) =>
  action$.pipe(
    ofType(subscribeToTradingRules.type),
    exhaustMap(() =>
      interval(TRADING_RULES_FETCH_INTERVAL_IN_MS).pipe(
        startWith(0),
        switchMap(() =>
          state$.pipe(
            map((state: State) => ({
              accessToken: getAccessToken(state),
              accountType: getAccountType(state),
            })),
            filter(
              ({ accessToken, accountType }) => isPresent(accessToken) && accountType === accountTypes.real,
            ),
            take(1),
            switchMap(({ accessToken }) =>
              zip(
                from(api.fetchLimitOrder(accessToken ?? '')).pipe(
                  pluck('response'),
                  // If there is an error during the call we can just return empty array - we are not handling such errors for now.
                  catchError(() => of([])),
                ),
                from(api.fetchStopOrders(accessToken ?? '')).pipe(
                  pluck('response'),
                  // If there is an error during the call we can just return empty array - we are not handling such errors for now.
                  catchError(() => of([])),
                ),
              ).pipe(
                map(([limitTradingRules, stopTradingRules]: [LimitOrderData[], StopOrderData[]]) =>
                  subscribeToTradingRulesSuccess({ limit: limitTradingRules, stop: stopTradingRules }),
                ),
              ),
            ),
          ),
        ),
        takeUntil(action$.pipe(ofType(unsubscribeFromTradingRules.type))),
      ),
    ),
  );

// DELETE
export const deleteTradingRuleEpic = (action$: TradingRuleDeleteAction$, state$: StateObservable<State>) =>
  action$.pipe(
    ofType(deleteTradingRule.type),
    filter(({ payload }) => !!payload.id),
    exhaustMap(({ payload }: TradingRuleDeleteAction) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          payload.category === 'Limit'
            ? from(api.deleteLimitOrder(accessToken, payload.id)).pipe(
                map(() => deleteTradingRuleSuccess(payload)),
                catchError(handleAjaxError(action$, () => deleteTradingRuleError(payload), payload.id)),
              )
            : from(api.deleteStopOrder(accessToken, payload.id)).pipe(
                map(() => deleteTradingRuleSuccess(payload)),
                catchError(handleAjaxError(action$, () => deleteTradingRuleError(payload), payload.id)),
              ),
        ),
      ),
    ),
  );

export const deleteTradingRuleSuccessEpic = (
  action$: TradingRuleDeleteAction$,
  state$: StateObservable<State>,
  { navigate }: AppReduxEpicMiddleware,
) =>
  action$.pipe(
    ofType(deleteTradingRuleSuccess.type),
    mergeMap(({ payload }: TradingRuleDeleteAction) =>
      of(
        notificationBannerActions.notifySuccess({
          message: I18n.t(
            payload.category === 'Limit'
              ? 'automation.deletePriceLimitSuccess'
              : 'stopOrders.deleteStopOrderSuccess',
          ),
        }),
      ).pipe(
        tap(() => {
          navigate(paths.ORDERS);
        }),
      ),
    ),
  );

export const deleteTradingRuleErrorEpic = (
  action$: TradingRuleDeleteAction$,
  state$: StateObservable<State>,
  { navigate }: AppReduxEpicMiddleware,
) =>
  action$.pipe(
    ofType(deleteTradingRuleError.type),
    mergeMap(({ payload }: TradingRuleDeleteAction) =>
      of(
        notificationBannerActions.notifySuccess({
          message: I18n.t(
            payload.category === 'Limit' ? 'automation.deletePriceLimitError' : 'automation.errors.generic',
          ),
        }),
      ).pipe(
        tap(() => {
          navigate(paths.ORDERS);
        }),
      ),
    ),
  );

// CREATE
export const createTradingRuleEpic = (action$: TradingRulesCreateAction$, state$: StateObservable<State>) =>
  action$.pipe(
    ofType(createTradingRule.type),
    filter(({ payload }: TradingRulesCreateAction) => !!payload.model.entity),
    exhaustMap(({ payload }: TradingRulesCreateAction) =>
      state$.pipe(
        map(getAccessToken),
        filter(isPresent),
        take(1),
        switchMap((accessToken) =>
          payload.category === 'Limit'
            ? from(api.submitLimitOrder(accessToken, payload.model as TradingRuleCreateLimitModel)).pipe(
                map(() => {
                  if (payload.onSuccess) payload.onSuccess();

                  return createTradingRuleSuccess();
                }),
                catchError((error: any, source) => {
                  if (
                    error?.errorCode &&
                    error?.errorCode !== 'limitsubmit_err_limitreached' &&
                    payload.onFail
                  ) {
                    payload.onFail();
                  } else if (payload.onError) {
                    payload.onError();
                  }

                  return handleAjaxError(action$, () => createTradingRuleError())(error, source);
                }),
              )
            : from(api.submitStopOrder(accessToken, payload.model as TradingRuleCreateStopModel)).pipe(
                map(() => {
                  if (payload.onSuccess) payload.onSuccess();

                  return createTradingRuleSuccess();
                }),
                catchError((error: any, source) => {
                  if (
                    error?.errorCode &&
                    error?.errorCode !== 'stopsubmit_err_limitreached' &&
                    payload.onFail
                  ) {
                    payload.onFail();
                  } else if (payload.onError) {
                    payload.onError();
                  }

                  return handleAjaxError(action$, () => createTradingRuleError())(error, source);
                }),
              ),
        ),
      ),
    ),
  );

export default combineEpics(
  fetchTradingRulesIntervalEpic,
  deleteTradingRuleEpic,
  deleteTradingRuleSuccessEpic,
  deleteTradingRuleErrorEpic,
  createTradingRuleEpic,
);
