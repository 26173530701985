import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { devices, dimensions } from 'common/styles';
import { Theme } from 'types/theme';
import { ButtonWrapperProps } from './utils';

interface ButtonProps extends ButtonWrapperProps {
  theme: Theme;
  customColor?: string;
}

export const ButtonWrapper = styled.div.attrs<ButtonProps>(({ weight, fontSize }) => ({
  className: `${fontSize}-text ${weight}-weight`,
}))`
  padding: 0 12px;
  margin: ${dimensions.btnMargin}px 0;
  margin: ${dimensions.btnMargin}px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-width: ${({ theme }: ButtonProps) => theme.border.width.standard};
  border-style: solid;

  ${({ theme, isErrorType, inverted, disabled, size = 'standard', isFullWidth, customColor }: ButtonProps) =>
    `
   background-color: ${
     (isErrorType ? theme.color.background.critical : undefined) ??
     customColor ??
     (inverted ? theme.color.background.formAccentInverted : undefined) ??
     theme.color.background.formAccent // dark
   };
    border-color:  ${
      (isErrorType ? theme.color.border.critical : undefined) ??
      (inverted ? theme.color.border.formAccentInverted : undefined) ??
      theme.color.border.formAccent
    };

    color: ${
      (isErrorType ? theme.color.foreground.critical : undefined) ??
      (inverted ? theme.color.foreground.formAccentInverted : undefined) ??
      theme.color.foreground.formAccent
    };
    opacity: ${disabled ? theme.opacity.disabled : theme.opacity.none};
    border-radius: ${theme.border.radius.button};
    height: ${theme.assets.button[size].height};
    min-width: ${!isFullWidth ? theme.assets.button[size].width : ''};
    width: ${isFullWidth ? '100%' : ''};
    
    @media ${devices.maxMobileS} {
      min-width: ${(!isFullWidth && size !== 'modal' && theme.assets.button[size].width) || 'unset'};
    }

    &:hover {
      opacity: ${disabled ? theme.opacity.disabled : theme.opacity.hover};
    }
  `}
  a {
    text-decoration: none !important;
  }
`;

export const LinkWrapper = styled.div.attrs<ButtonProps>({
  className: 'large-text',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
  margin: ${dimensions.btnMargin}px 0;

  ${({ theme, disabled }: ButtonProps) => `
    color: ${theme.color.foreground.secondary};
    opacity: ${disabled ? theme.opacity.disabled : theme.opacity.none};
    cursor: ${disabled ? `not-allowed` : `pointer`};

    &:hover {
      opacity: ${(!disabled && theme.opacity.hover) || theme.opacity.disabled};
    }
  `}
`;

export const ForgotPasswordWrapper = styled(LinkWrapper)`
  padding-top: 13px;
  margin-bottom: -10px;
`;

export const Link = styled(RouterLink)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ theme }: { theme: Theme }) => `
    color: ${theme.color.foreground.formAccent};
  `}
`;

export const TagLink = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const TextContainer = styled.span<{ isErrorType?: boolean; inverted?: boolean }>`
  text-align: center;
  color: ${({ theme, isErrorType, inverted }: { theme: Theme; isErrorType?: boolean; inverted?: boolean }) =>
    `${
      (isErrorType ? theme.color.foreground.primaryInverted : undefined) ??
      (inverted ? theme.color.foreground.formAccentInverted : undefined) ??
      theme.color.foreground.formAccent
    };
  `};
`;
