import styled from 'styled-components';
import { Theme } from 'types/theme';
import { getBodyFontStyles, getHeaderFontStyles } from 'common/styles/global';
import { dimensions } from 'common/styles';
import { colors } from 'common/utils/theme/colors';
import { Link } from 'scenes/Welcome/commonStyles';
import { Modal } from '../../lib/semantic-ui';
import textStyles from '../../styles/text';

export const ModalWrapper = styled(Modal)`
  max-width: ${dimensions.maxCenteredContentWidth}px;
  background-color: ${({ theme }: { theme: Theme }) => theme.color.background.primary} !important;

  .actions {
    background: none !important;
    border: none !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 0 !important;
  }
`;

export const ModalContent = styled.div.attrs({ className: 'body-text' })`
  ${({ theme }: { theme: Theme }) => `
     
      padding: ${dimensions.mediumPadding}px;
      
      p {
        ${getBodyFontStyles(theme)};
        color: ${theme.color.foreground.primary};
      }
     
      
      h1 {
        ${getHeaderFontStyles('1', theme)};
        font-weight: ${theme.typography.heading.weight.regular};
      }

      h2 {
          ${getHeaderFontStyles('2', theme)}; 
          font-weight: ${theme.typography.heading.weight.regular};
      }

      h3 {
          ${getHeaderFontStyles('3', theme)}; 
          font-weight: ${theme.typography.heading.weight.regular};
      }

      h4 {
          ${getHeaderFontStyles('4', theme)}; 
          font-weight: ${theme.typography.heading.weight.regular};
      }
  `};

  img {
    max-width: 100%;
  }

  a {
    text-decoration: underline;
  }
`;

export const ModalContentCustom = styled(ModalContent)`
  padding: 0 100px;
`;

export const ModalWrapperCustom = styled(ModalWrapper)`
  padding: 43px 0 50px;
  position: relative;
`;

export const ModalFooter = styled(ModalContentCustom)`
  width: 100%;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 28px;
  top: 20px;
`;

export const CheckboxTextWrapper = styled.div`
  font-size: 14px;
  text-align: left;
  white-space: pre-line;

  ${({ theme, error }: { theme: Theme; error: boolean }) =>
    error &&
    `
      color: ${theme.color.foreground.critical} !important;
      & > * {
        color: ${theme.color.foreground.critical} !important;
      }
  `}
`;

export const CustomLink = styled(Link)`
  color: ${({ theme }: { theme: Theme }) => theme.color.foreground.primary};
`;

export const CheckboxLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
  align-items: center;
  padding-top: 12px;
  padding-left: 12px;
  ${textStyles.modalText}
`;

export const Header = styled.div.attrs({ className: 'primary-color-text' })`
  padding: 12px 0;
  border-bottom: 1px solid ${colors.gray4};
  ${textStyles.modalHeadline};
`;

export const MainText = styled.div`
  white-space: pre;
  text-wrap: auto;
  padding-top: 22px;
  ${textStyles.modalText}
`;
