import { paths } from 'common/urls';

export const CONSENT_MODELS = {
  UNSPECIFIED: 'Unspecified',
  EXPLICIT: 'Explicit',
  OPTOUT: 'OptOut',
  OPTIN: 'OptIn',
} as const;

export const CONSENT_STATES = {
  UNSPECIFIED: 'Unspecified',
  ACCEPTED: 'Accepted',
  DECLINED: 'Declined',
} as const;

export const CONSENT_TYPES = {
  TOP_UPS: 'topups',
  ACTIVE_STAKING: 'active_staking',
  PASSIVE_STAKING: 'passive_staking',
  BANK_MIGRATION: 'bank_migration',
} as const;

export const REDIRECT_AFTER_CONSENT_ACCEPTED: Record<string, string> = {
  [CONSENT_TYPES.TOP_UPS]: paths.DEPOSIT_INSTANT,
} as const;

export const FEATURE_SPECIFIC_ACTIVE_STAKING_CONSENTS__TEXT_VARIATIONS = {
  WITH_T_AND_C: 'withTandC',
  WITHOUT_T_AND_C: 'withoutTandC',
  WITH_DIRECTIVE: 'withDirective',
} as const;

export const requiredDocumentsForStakingConsent = {
  specialConditions: {
    title: 'stakingFeatureTermsAndConditions.documents.specialConditions',
    link: 'https://bisonapp.com/wp-content/static/documents/2024-07-09-BSDC-BISON-Staking-Sonderbedingungen-1.0.pdf',
  },
  general: {
    title: 'stakingFeatureTermsAndConditions.documents.general',
    link: 'https://bisonapp.com/wp-content/static/documents/2023-12-21-BISON_BSDC_AGB_v10.2_DE.pdf',
  },
};
