import React, { ReactElement, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from 'common/components/Button';
import CheckboxInput from 'common/components/CheckboxInput';
import { CheckboxTextWrapper, CustomLink, Header, MainText } from 'common/components/Modal/Modal';
import ModalTemplate from 'common/components/Modal/index';
import TncExitIntent from 'common/components/Modal/TermsAndConditions/ExitIntent';
import CheckboxTextRenderer from 'common/components/CheckboxTextRenderer';
import { Terms, TermsAndConditionsType, PopupData } from 'types/termsAndConditions';

type Props = {
  terms: Terms;
  type: TermsAndConditionsType;
  onAccept: (type: TermsAndConditionsType) => void;
  onCancel: (type: TermsAndConditionsType) => void;
  generalTnCText: PopupData | null;
};

const getValidationSchema = () =>
  Yup.object().shape({
    checkbox1: Yup.boolean().required().oneOf([true]),
  });

const TermsAndConditionsModal = ({
  terms,
  type,
  onAccept,
  onCancel,
  generalTnCText,
}: Props): ReactElement => {
  const { t } = useTranslation();

  // States for displaying tnc and exit modals.
  const [showTncModal, setShowTncModal] = useState(false);
  const [showExitIntent, setShowExitIntent] = useState(false);

  useEffect(() => {
    // Open modal on init
    setShowTncModal(true);
  }, []);

  return (
    <>
      <TncExitIntent
        isOpened={showExitIntent}
        onClose={() => {
          if (onCancel) onCancel(type);

          // Close modal
          setShowExitIntent(false);
        }}
        onBackToPopUp={() => {
          setShowTncModal(true);
          setShowExitIntent(false);
        }}
      />
      <ModalTemplate
        defaultOpen={showTncModal}
        onCancel={() => {
          setShowExitIntent(true);
          setShowTncModal(false);
        }}
        header={<Header>{generalTnCText?.title ?? t('updatedTermsAndConditions.title')}</Header>}
        actions={<></>}
      >
        {generalTnCText?.text ? (
          <MainText>{generalTnCText?.text}</MainText>
        ) : (
          <>
            <MainText>{t('updatedTermsAndConditions.header')}</MainText>
            <MainText>{t('updatedTermsAndConditions.mainText')}</MainText>
          </>
        )}
        <Formik
          initialValues={{ checkbox1: false, checkbox2: false }}
          validationSchema={getValidationSchema()}
          onSubmit={() => {
            // Will trigger only after accepting all checkboxes.
            if (onAccept) onAccept(type);

            // Close modal
            setShowTncModal(false);
          }}
        >
          {({ isValid, errors, handleSubmit }) => (
            <Form>
              <CheckboxInput name="checkbox1" hideError testId="checkbox.tnc">
                {generalTnCText?.checkbox ? (
                  <CheckboxTextWrapper error={errors.checkbox1}>
                    <CheckboxTextRenderer items={generalTnCText.checkbox} />
                  </CheckboxTextWrapper>
                ) : (
                  <CheckboxTextWrapper error={errors.checkbox1}>
                    {t('updatedTermsAndConditions.fallbackTnCCheckbox.a')} &nbsp;
                    <CustomLink target="_blank" href={terms.termsUrl}>
                      {t('updatedTermsAndConditions.fallbackTnCCheckbox.b')}
                    </CustomLink>
                    {t('updatedTermsAndConditions.fallbackTnCCheckbox.c')}
                  </CheckboxTextWrapper>
                )}
              </CheckboxInput>
              <Button
                disabled={!isValid}
                onClick={handleSubmit}
                title={t('updatedTermsAndConditions.agree')}
                isFullWidth
                size="modal"
              />
            </Form>
          )}
        </Formik>
      </ModalTemplate>
    </>
  );
};

export default TermsAndConditionsModal;
