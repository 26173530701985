import { CryptoWithdrawalState } from 'types/cryptoWithdrawal';

export const initialState: CryptoWithdrawalState = {
  cryptoWithdrawInitPayload: null,
  cryptoWithdrawOrder: null,
  initializingCryptoWithdrawal: false,
  error: null,
  code2fa: null,
  submittingCryptoWithdrawal: false,
  intentId: null,
  isIntentLoading: false,
  vaspId: null,
  isSelfHostedWallet: null,
  isWalletVerificationRequired: null,
  isProofOfOwnershipRequired: null,
};

export default initialState;
