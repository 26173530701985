import React, { useEffect } from 'react';
import AuditProofModals from 'common/components/Modal/AuditProofModals';
import { isAmlRequired } from 'common/utils/auditProof';
import { useModalProvider } from 'providers/Modal/useModalProvider';
import { useSelector } from 'react-redux';
import { getAml } from 'store/selectors/auth';
import { getIsSolarisDownErrorOccurred, getWasAuditProofModalClosed } from 'store/selectors/localSettings';
import TermsAndConditionsModal from 'common/components/Modal/TermsAndConditions/TermsAndConditionsModal';
import { TermsAndConditionsType } from 'types/termsAndConditions';
import InboxImportantMessages from 'common/components/Modal/InboxImportantMessages';
import { getIsDeviceMonitoringConsentOpen } from 'store/selectors/deviceMonitoring';
import DeviceMonitoringConsentModal from 'common/components/Modal/DeviceMonitoringConsentModal';
import { getIsWinnerLoserModalShown } from 'store/slices/experiments/winnersLosers/selectors';
import WinnersLosersModal from 'common/components/Modal/WinnersLosersModal';
import AddPhoneNumberFlow from 'common/components/Modal/AddPhoneNumberFlow';
import FeatureSpecificTncConsentModal from 'common/components/Modal/TermsAndConditions/FeatureSpecificTncConsentModal';
import {
  getIsCryptoTermsAccepted,
  getIsFeatureTermsAndConditionsShown,
} from 'store/selectors/termsAndConditions';
import StakingIntroductionModal from 'common/components/Modal/Staking/StakingIntroductionModal';
import { useStakingIntroModal } from 'common/hooks/useStakingIntroModal';
import StakingBlockedModal from 'common/components/Modal/Staking/StakingBlockedModal';
import { DEFAULT_MODAL_PROPS } from 'common/const/modals';
import { getStakingBlockedModalStatus } from 'store/slices/staking/selectors';
import { ModalType } from 'providers/Modal/types';
import { setIsImportantMessagesModalWasDisplayed } from 'common/utils/importantMessagesSessionStorage';
import StakingFeatureSpecificTncConsentModal from 'common/components/Modal/TermsAndConditions/StakingFeatureSpecificTncConsentModal';
import { CONSENT_TYPES } from 'common/const/consent';
import useCheckIfUserEnteredStakingWithoutConsentAndShowTncOrRedirectOnReject from 'common/hooks/useCheckIfUserEnteredStakingWithoutConsentAndShowTncOrRedirectOnReject';
import PhishingModal from 'common/components/Modal/PhishingModal';
import BankTransferTermsAndConditionsModal from 'common/components/Modal/TermsAndConditions/BankTransferModal';
import { usePhishingModalDisplay } from './usePhishingModalDisplay';
import useAddPhoneNumberFlowDisplay from './useAddPhoneNumberFlowDisplay';
import useImportantMessagesModal from './useImportantMessagesModal';
import { useTnCPopUpDisplay } from './useTnCPopUpDisplay';
import useBankMigrationConsent from './useBankMigrationConsent';

const PRIVATE_PAGE_MODAL_TYPE: ModalType = 'private';

/**
 * Hook that watches state and displays initial modals.
 */
function usePrivatePageInitialPopups(): void {
  const modalProvider = useModalProvider();
  const { displayCryptoTnc, displayStocksTnc } = useTnCPopUpDisplay();
  const isPhishingModalDisplayed = usePhishingModalDisplay();
  const isImportantMessagesModalDisplayed = useImportantMessagesModal();
  const isAddPhoneNumberModalDisplayed = useAddPhoneNumberFlowDisplay();

  const isWinnersAndLoosersModalDisplayed = useSelector(getIsWinnerLoserModalShown);
  const isDeviceMonitoringConsentOpen = useSelector(getIsDeviceMonitoringConsentOpen);
  const aml = useSelector(getAml);
  const wasAuditProofModalClosed = useSelector(getWasAuditProofModalClosed);
  const isTopUpsTermsAndConditionsShown = useSelector((state) =>
    getIsFeatureTermsAndConditionsShown(state, CONSENT_TYPES.TOP_UPS),
  );
  const isStakingTermsAndConditionsShown = useSelector((state) =>
    getIsFeatureTermsAndConditionsShown(state, CONSENT_TYPES.ACTIVE_STAKING),
  );
  const isSolarisDownErrorOccurred = useSelector(getIsSolarisDownErrorOccurred);
  const { isStakingIntroShown, onCloseStakingIntro } = useStakingIntroModal();
  const { isOpened: isStakingBlockedModalStatus } = useSelector(getStakingBlockedModalStatus);

  useCheckIfUserEnteredStakingWithoutConsentAndShowTncOrRedirectOnReject();

  const {
    hasAcceptedLatestTerms: hasAcceptedLatestTermsBankMigration,
    isInitiallyLoaded: isBankMigrationTermsAndConditionsInitiallyLoaded,
    popupData: bankMigrationPopupData,
    agreeToTerms,
    onClickAwayBankMigrationPopup,
    isBankMigrationPopupPersisted,
  } = useBankMigrationConsent();
  const isCryptoTermsAndConditionsAccepted = useSelector(getIsCryptoTermsAccepted);

  useEffect(() => {
    if (!isAmlRequired(aml.confirmationStatus) || isSolarisDownErrorOccurred || wasAuditProofModalClosed)
      return;

    modalProvider.addModal({
      component: <AuditProofModals {...DEFAULT_MODAL_PROPS}></AuditProofModals>,
      type: PRIVATE_PAGE_MODAL_TYPE,
      unblockable: true,
    });
  }, [aml.confirmationStatus, isSolarisDownErrorOccurred, wasAuditProofModalClosed]);

  useEffect(() => {
    if (displayCryptoTnc) {
      modalProvider.addModal({
        component: (
          <TermsAndConditionsModal
            type={TermsAndConditionsType.CRYPTO}
            {...DEFAULT_MODAL_PROPS}
          ></TermsAndConditionsModal>
        ),
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [displayCryptoTnc]);

  useEffect(() => {
    if (displayStocksTnc) {
      modalProvider.addModal({
        component: (
          <TermsAndConditionsModal
            type={TermsAndConditionsType.STOCKS}
            {...DEFAULT_MODAL_PROPS}
          ></TermsAndConditionsModal>
        ),
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [displayStocksTnc]);

  useEffect(() => {
    if (isPhishingModalDisplayed) {
      modalProvider.addModal({
        component: <PhishingModal {...DEFAULT_MODAL_PROPS}></PhishingModal>,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isPhishingModalDisplayed]);

  useEffect(() => {
    if (isImportantMessagesModalDisplayed) {
      modalProvider.addModal({
        component: <InboxImportantMessages {...DEFAULT_MODAL_PROPS}></InboxImportantMessages>,
        type: PRIVATE_PAGE_MODAL_TYPE,
        onCloseCallback: () => {
          setIsImportantMessagesModalWasDisplayed(true);
        },
      });
    }
  }, [isImportantMessagesModalDisplayed]);

  useEffect(() => {
    if (isDeviceMonitoringConsentOpen) {
      modalProvider.addModal({
        component: <DeviceMonitoringConsentModal {...DEFAULT_MODAL_PROPS}></DeviceMonitoringConsentModal>,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isDeviceMonitoringConsentOpen]);

  useEffect(() => {
    if (isWinnersAndLoosersModalDisplayed) {
      modalProvider.addModal({
        component: <WinnersLosersModal {...DEFAULT_MODAL_PROPS}></WinnersLosersModal>,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isWinnersAndLoosersModalDisplayed]);

  useEffect(() => {
    if (isAddPhoneNumberModalDisplayed) {
      modalProvider.addModal({
        component: <AddPhoneNumberFlow {...DEFAULT_MODAL_PROPS}></AddPhoneNumberFlow>,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isAddPhoneNumberModalDisplayed]);

  useEffect(() => {
    if (isTopUpsTermsAndConditionsShown) {
      modalProvider.addModal({
        component: <FeatureSpecificTncConsentModal {...DEFAULT_MODAL_PROPS} />,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isTopUpsTermsAndConditionsShown]);

  useEffect(() => {
    if (isStakingTermsAndConditionsShown) {
      modalProvider.addModal({
        component: <StakingFeatureSpecificTncConsentModal {...DEFAULT_MODAL_PROPS} />,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isStakingTermsAndConditionsShown]);

  useEffect(() => {
    if (isStakingIntroShown) {
      modalProvider.addModal({
        component: <StakingIntroductionModal {...DEFAULT_MODAL_PROPS} />,
        onCloseCallback: onCloseStakingIntro,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isStakingIntroShown]);

  useEffect(() => {
    if (isStakingBlockedModalStatus) {
      modalProvider.addModal({
        component: <StakingBlockedModal {...DEFAULT_MODAL_PROPS} />,
        onCloseCallback: onCloseStakingIntro,
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [isStakingBlockedModalStatus]);

  useEffect(() => {
    if (
      isCryptoTermsAndConditionsAccepted &&
      isBankMigrationTermsAndConditionsInitiallyLoaded &&
      !hasAcceptedLatestTermsBankMigration
    ) {
      modalProvider.addModal({
        component: (
          <BankTransferTermsAndConditionsModal
            agreeToTerms={agreeToTerms}
            popupData={bankMigrationPopupData}
            onClickAway={() => {
              onClickAwayBankMigrationPopup()
            }}
            isPersisted={isBankMigrationPopupPersisted}
            {...DEFAULT_MODAL_PROPS}
          />
        ),
        type: PRIVATE_PAGE_MODAL_TYPE,
      });
    }
  }, [
    isCryptoTermsAndConditionsAccepted,
    hasAcceptedLatestTermsBankMigration,
    isBankMigrationTermsAndConditionsInitiallyLoaded,
    onClickAwayBankMigrationPopup
  ]);
}

export default usePrivatePageInitialPopups;
