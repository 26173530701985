/* eslint-disable */
import { dimensions } from 'common/styles';
import I18n from 'i18next';
import { $Keys, $Values, ValuesType } from 'utility-types';
import { TradingRuleCategoryType } from 'types/tradingRules';
import { TimePeriod } from 'types/currency';
import { CryptoAssets } from 'types/assets';
import { API_USER_ROLES } from './user';
import { PaymentMethodType } from 'types/topUps';

const getDevice = (userAgent: string) => ({
  is: (device: string) => true,
  desktop: true,
});

export * from './countryData';

const statusBarStyles = Object.freeze({
  darkContent: 'dark-content',
  lightContent: 'light-content',
});

export type StatusBarStyle = ValuesType<typeof statusBarStyles>;

// eslint-disable-next-line
export const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;

export const minPassLength = 8;
export const maxPassLength = 40;
export const SCROLL_PAGE_SIZE = Math.round(window.innerHeight / dimensions.listElementMinHeight); // minimum height of one list element
export const halloEmail = 'hallo@bisonapp.com';
export const baseURL = 'https://bisonapp.com';
export const documentsURL = `${baseURL}/dokumente`;
export const externalDocumentsURL = `https://bisonapp.com/dokumente/AT`;
export const pagepaths = {
  website: 'https://bisonapp.com',
  agb: `${documentsURL}/agb`,
  agbAT: `${externalDocumentsURL}/agb`,
  datenschutz: `${documentsURL}/datenschutz`,
  datenschutzAT: `${externalDocumentsURL}/datenschutz`,
  impressum: `${documentsURL}/impressum`,
  sonderbedingungen: `${documentsURL}/sonderbedingungen`,
  risiko: `${documentsURL}`,
  risikoAT: `${externalDocumentsURL}/risikoinformationen`,
  faq: `${baseURL}/faq`,
  gutschein: `${baseURL}/gutschein`,
  betaDocsEuwax: `${baseURL}/dokumente/invite-only/`,
  betaDocsBlocknox: `${baseURL}/dokumente/invite-only/`,
  betaDocsSolaris: `${baseURL}/dokumente/invite-only/`,
  betaDocsDPEuwax: `${baseURL}/dokumente/invite-only/`,
  betaDocsDPBlocknox: `${baseURL}/dokumente/invite-only/`,
  phishingGerm: `${baseURL}/allgemeiner-sicherheitshinweis/`,
  phishingEng: `${baseURL}/en/general-security-information/`,
};

export const getPhishingLink = () => {
  return I18n.t('l10n.lang') === 'en' ? pagepaths.phishingEng : pagepaths.phishingGerm;
};

export const TERMS_AND_CONDITIONS_MAX_ATTEMPTS = 2;

export const getTermsAndConditionsPaths = (country: string) => {
  return I18n.t('l10n.lang') === 'en'
    ? getTermsAndConditionsPathsEn()
    : getTermsAndConditionsPathsDe(country);
};

const getTermsAndConditionsPathsEn = () => {
  return {
    agbKyc: `${baseURL}/en/documents/agb/`,
    dataPrivacyKyc: `${baseURL}/en/documents/data-privacy/`,
    betaDocsEuwax: `${baseURL}/en/documents/agb/`,
    betaDocsBlocknox: `${baseURL}/en/documents/agb/`,
    betaDocsSolaris: `${baseURL}/en/documents/agb/`,
    betaDocsDPEuwax: `${baseURL}/en/documents/data-privacy/`,
    betaDocsDPBlocknox: `${baseURL}/en/documents/data-privacy/`,
    agbSavings: `${baseURL}/en/documents/agb/`,
    riskInfoSavings: `${baseURL}/en/documents/risk-information/`,
    adventBanner: `${baseURL}/en/adventcalendar`,
  };
};

const getTermsAndConditionsPathsDe = (country: string) => {
  const countryUrlPart = country !== 'AT' ? '' : '/at';
  return {
    agbKyc: `${baseURL}/dokumente${countryUrlPart}/agb/`,
    dataPrivacyKyc: `${baseURL}/dokumente${countryUrlPart}/datenschutz/`,
    betaDocsEuwax: `${baseURL}/dokumente${countryUrlPart}/agb/`,
    betaDocsBlocknox: `${baseURL}/dokumente${countryUrlPart}/agb/`,
    betaDocsSolaris: `${baseURL}/dokumente${countryUrlPart}/agb/`,
    betaDocsDPEuwax: `${baseURL}/dokumente${countryUrlPart}/datenschutz/`,
    betaDocsDPBlocknox: `${baseURL}/dokumente${countryUrlPart}/datenschutz/`,
    agbSavings: `${baseURL}/dokumente${countryUrlPart}/agb/`,
    riskInfoSavings: `${baseURL}/dokumente${countryUrlPart}/risikoinformationen/`,
    adventBanner: `${baseURL}/adventskalender`,
  };
};

export const idNowStoreURL = {
  android: 'https://play.google.com/store/apps/details?id=de.idnow&hl=de',
  ios: 'https://apps.apple.com/us/app/idnow-online-ident/id918081242',
} as const;

export const statusValues = Object.freeze({
  ok: 'ok',
  down: 'down',
  warn: 'warn',
} as const);

export const themes = Object.freeze({
  light: 'light',
  dark: 'dark',
} as const);

export const fiats = {
  eur: 'eur',
} as const;
export type Fiats = typeof fiats;
export type Fiat = $Values<Fiats>;

export const timePeriods = Object.freeze({
  '3H': '3H',
  '24H': '24H',
  '7D': '7D',
  '30D': '30D',
  '12M': '12M',
  MAX: 'MAX',
} as const);

export type TimeKeys = $Keys<typeof timePeriods>;

export const timePeriodParam = {
  '3H': 0.125,
  '24H': 1,
  '7D': 7,
  '30D': 30,
  '12M': 365,
  MAX: 3654,
} as const;

// seconds between ticks
export const timePeriodToTimeIntervalMap = {
  '3H': 30,
  '24H': 5 * 60,
  '7D': 30 * 60,
  '30D': 120 * 60,
  '12M': 1440 * 60,
  MAX: 10080 * 60,
} as const;

export const timePeriodToPortfolioChartTime = {
  '3H': 'ThreeHours',
  '24H': 'OneDay',
  '7D': 'SevenDays',
  '30D': 'OneMonth',
  '12M': 'OneYear',
  MAX: 'AllTime',
} as const;

export type TimePeriodToPortfolioChartTimeValue = $Values<typeof timePeriodToPortfolioChartTime>;

export const timePeriodToStockEtfChartIncrement: Record<TimePeriod, number> = {
  '3H': 60,
  '24H': 300, // 5 min
  '7D': 60 * 30, // 30 min
  '30D': 60 * 60 * 24, // 1 day
  '12M': 60 * 60 * 24, // 1 day
  MAX: 60 * 60 * 24, // 1 day
} as const;

export const timePeriodToQueryParamCrypto = {
  '3H': '30s',
  '24H': '5m',
  '7D': '30m',
  '30D': '120m',
  '12M': '1440m',
  MAX: '1w',
};

export const InnerTimePeriodToStockEtfRequestParam = {
  '3H': '3h',
  '24H': '1d',
  '7D': '1w',
  '30D': '30d',
  '12M': '365d',
  MAX: '3654d',
} as const;

export const orderTypes = Object.freeze({
  Exchange: 'Exchange' as const,
  Stock: 'Stock' as const,
  Etf: 'Etf' as const,
  Money: 'Money' as const,
  Staking: 'Staking' as const,
} as const);

export const buyOrSellOrderTypes = Object.freeze({
  Buy: 'Buy',
  Sell: 'Sell',
} as const);

export const stakeOrUnstakeOrderTypes = Object.freeze({
  Stake: 'Stake',
  Unstake: 'Unstake',
} as const);

export const orderMoneySubtypes = Object.freeze({
  SEPA: 'Sepa',
  INSTANANT_SEPA: 'InstantSepa',
  TOPUP_CARD: 'TopUpCard',
  SUTOR_REFERENCE_SEPA: 'SutorReferenceSepa',
  CRYPTO_DEPOSIT: 'CryptoDeposit',
  AUTOMATIC_CRYPTO_WITHDRAW: 'AutomaticCryptoWithdraw',
  AUTOMATIC_CRYPTO_WITHDRAW_REVIEW: 'AutomaticCryptoWithdrawReview',
  MANUAL_CRYPTO_WITHDRAW: 'ManualCryptoWithdraw',
  MANUAL_CRYPTO_WITHDRAW_REVIEW: 'ManualCryptoWithdrawReview',
  COUPON_REWARD: 'CouponReward',
  COMPENSATION_REWARD: 'CompensationReward',
  REFERRAL_REWARD: 'ReferralReward',
  AFFILIATE_REWARD: 'AffiliateReward',
  LOTTERY_REWARD: 'LotteryReward',
  COOPERATION_REWARD: 'CooperationReward',
  PRIZE_REWARD: 'PrizeReward',
  STAKING_REWARD: 'StakingReward',
});

export type BuyOrSellOrderType = $Values<typeof buyOrSellOrderTypes>;

export type OrderOption = $Values<typeof orderTypes>;

export type OrderMoneySubtype = $Values<typeof orderMoneySubtypes>;

export const orderSubTypes = Object.freeze({
  ...buyOrSellOrderTypes,
  ...stakeOrUnstakeOrderTypes,
  ...orderMoneySubtypes,
  Deposit: 'Deposit' as const,
  Withdraw: 'Withdraw' as const,
  CorporateActionIncrease: 'CorporateActionIncrease' as const,
  CorporateActionDecrease: 'CorporateActionDecrease' as const,
  LimitBuy: 'LimitBuy' as const,
  LimitSell: 'LimitSell' as const,
} as const);

export type OrderSubtype = $Values<typeof orderSubTypes>;

export const orderStatuses = Object.freeze({
  UserCompleted_Ok: 'UserCompleted_Ok',
} as const);

export const TRANSACTION_HISTORY_ITEM_STATUS_CODES = Object.freeze({
  SUBMITTED: 'Submitted',
  PENDING: 'Pending',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  ACCOUNTED: 'Accounted',
  PENDING_WALLET_VERIFICATION: 'PendingWalletVerification',
} as const);

export type TransactionHistoryItemStatusCode = $Values<typeof TRANSACTION_HISTORY_ITEM_STATUS_CODES>;

export type OrderStatus = $Values<typeof orderStatuses>;

export const tickerDirections = Object.freeze({
  up: 'up',
  noChange: 'noChange',
  down: 'down',
} as const);

export type TickerDirection = $Values<typeof tickerDirections>;

export const accountTypes = Object.freeze({
  paper: 'Paper',
  real: 'Real',
  anonymous: 'Anonymous',
} as const);
export type AccountType = ValuesType<typeof accountTypes>;

export const secretTypes = Object.freeze({
  password: 'Password',
  clientKey: 'ClientKey',
  clientToken: 'ClientToken',
} as const);

export type SecretType = ValuesType<typeof secretTypes>;

export type Credentials = {
  username: string;
  secret: string;
  secretType: SecretType;
  displayWaitingScreen: boolean;
};

export type UserRole = ValuesType<typeof API_USER_ROLES>;

export const kycStatuses = Object.freeze({
  NotStarted: 'NotStarted',
  Submitted: 'Submitted',
  BankIdCreated: 'BankIdCreated',
  Pending: 'Pending',
  Confirmed: 'Confirmed',
  Invalid: 'Invalid',
} as const);
export type KycStatus = ValuesType<typeof kycStatuses>;

export const kycRenewStatuses = Object.freeze({
  NotStarted: 'NotStarted',
  IdentificationCreated: 'IdentificationCreated',
  Pending: 'Pending',
  Confirmed: 'Confirmed',
  Invalid: 'Invalid',
  Failed: 'Failed', // Not in BE enum. Added for FE purposes
} as const);
export type KycRenewStatus = ValuesType<typeof kycRenewStatuses>;

export const verificationDocuments = Object.freeze({
  ID: 'id',
  PASSPORT: 'passport',
} as const);

export const apiEnvironments = Object.freeze({
  PRODUCTION: 'Production',
  PREPRODUCTION: 'Preproduction',
  STAGING: 'Staging',
  DEVELOPMENT: 'Development',
} as const);

export type ApiEnvironment = ValuesType<typeof apiEnvironments>;

export const codePushEnvironments = Object.freeze({
  PRODUCTION: 'Production',
  STAGING: 'Staging',
} as const);

export type CodePushEnvironment = ValuesType<typeof codePushEnvironments>;

export const kycEnvironments = Object.freeze({
  PRODUCTION: 'Production',
  SANDBOX: 'Sandbox',
} as const);
export type KycEnvironment = ValuesType<typeof kycEnvironments>;

export const billTypes = (t: (stringToTranslate: string) => string) => [
  { value: 'BankAccountStatement', label: t('kyc.billStep.billTypes.bankAccountStatement') },
  { value: 'InternetBill', label: t('kyc.billStep.billTypes.internetBill') },
  { value: 'TelephoneBill', label: t('kyc.billStep.billTypes.telephoneBill') },
  { value: 'ElectricityBill', label: t('kyc.billStep.billTypes.electricityBill') },
  { value: 'GasBill', label: t('kyc.billStep.billTypes.gasBill') },
  { value: 'HeatingBill', label: t('kyc.billStep.billTypes.heatingBill') },
  { value: 'WaterBill', label: t('kyc.billStep.billTypes.waterBill') },
];

// Fields specific to KYC-ing with a bill + ID/Passport
export const billFormFields = ['billType', 'billDate'];

export const financialStatusFields = [
  'employmentStatus',
  'professionalField',
  'incomeSource',
  'netIncome',
  'netWealth',
  'investmentTarget',
  'cryptoDeposit',
  'cryptoDepositSource',
];

export const mediaQueries = {
  small: '(min-width: 800px)',
  medium: '(min-width: 1000px)',
};

export const deviceType = {
  desktop: 'desktop',
};

export const NOTIFICATION_EL_GLOBAL = 'global';

export const device = getDevice(navigator.userAgent);
device.desktop = device.is(deviceType.desktop);

export const channels = {
  email: 'email',
  sms: 'sms',
};

export const bisonTimezone = 'Europe/Berlin';

export const transactionHistoryYears = {
  all: 'allTime',
};

export type CryptoInfo = {
  name: string;
  symbol: string;
  mainColor: string;
  secondaryColor: string;
  icon: string;
  precision: number;
  integerCount?: number;
  minWithdrawal: number;
  pair: string;
  key: string;
};

export type FiatInfo = {
  name: string;
  symbol: string;
  mainColor: string;
  secondaryColor: string;
  icon: any;
  precision: number;
  key: string;
};

export type AssetInfo = {
  name: string;
  symbol: string;
  mainColor: string;
  secondaryColor: string;
  icon: any;
  key: string;
};

type Currencies = {
  fiatInfo: {
    [fiat_id: string]: FiatInfo;
  };
};

export const currencies: Currencies = Object.freeze({
  fiatInfo: {
    eur: {
      name: 'Euro',
      symbol: 'EUR',
      key: 'eur',
      mainColor: '#1D25E9',
      secondaryColor: 'white',
      icon: 'icon-euro-sign',
      precision: 2,
    },
  },
} as const);

export const destinationTagCurrencies: string[] = ['xrp', 'xlm'];

// export const cryptoKeys = Object.values(currencies.cryptoInfo).map(({ key }) => key) as const;
export const fiatKeys = Object.values(currencies.fiatInfo).map(({ key }) => key);

export const isCrypto = (entityName: string, cryptoAssets: CryptoAssets) => entityName in cryptoAssets;

export const isFiat = (entityName: string) => entityName.toLowerCase() in currencies.fiatInfo;

export const TRADING_RULES_TYPES: TradingRuleCategoryType = Object.freeze({
  limit: 'limit',
  stop: 'stop',
} as const);

export const BLOCKED_FEATURES_VALUES = {
  LO: 'LO',
  WEB: 'WEB',
  SP: 'SP',
  SO: 'SO',
  TRADING: 'TRADING',
  TU: 'TU', // Top-Ups
} as const;

export const LANGUAGES = {
  de: 'de',
  en: 'en',
} as const;

export const BUY_SELL_VALUES = {
  BUY: 'buy',
  SELL: 'sell',
} as const;

export type OrderType = ValuesType<typeof BUY_SELL_VALUES>;

export const MAX_TAX_EXEMPTION_VALUE = 1000;

export const DEPOSIT_FEES = {
  INSTANT: 1,
  PER_TRANSFER: 20000,
  MONTHLY: 200000,
};

export const TAX_EXEMPTIONS_STATUSES = {
  PENDING: 'PENDING',
  TRANSMITTED: 'TRANSMITTED',
  CONFIRMED: 'CONFIRMED',
  EXPIRED: 'EXPIRED',
} as const;

export const partners = {
  bison: 'bison',
  b2b: 'b2b',
} as const;

export const GERMAN_COUNTRY_CODE = '+49';

export const SUPPORTED_STOCKS_COUNTRY_CODES = [GERMAN_COUNTRY_CODE];

export const EMPTY_PAYMENT_METHOD = {
  paymentMethodId: '',
  cardBrand: '',
  cardNumberLast4: '',
} as PaymentMethodType;
export const REFRESH_VALID_FOR_SECONDS = 4;

export const ORDERS_TYPES = {
  OPEN_ORDERS: 'open-orders',
  ACTIVITY: 'activity',
} as const;

export const MAX_ORDER_PRICE_DEVIATION_PERCENTS = 5;
