import { ThemeName } from 'types/theme';
import { action } from './_common';
import { createAction } from '@reduxjs/toolkit';

export const CHANGE_THEME = 'CHANGE_THEME' as const;
export const SET_SELECTED_TECH_INDICATORS = 'SET_SELECTED_TECH_INDICATORS' as const;
export const ACCEPT_SAVINGS_PLAN_BONUS_TERMS = 'ACCEPT_SAVINGS_PLAN_BONUS_TERMS' as const;
export const STOCKS_SWITCH = 'STOCKS_SWITCH' as const;
export const CLOSE_STOCK_RAFFLE = 'CLOSE_STOCK_RAFFLE';
export const JOIN_STOCK_RAFFLE = 'JOIN_STOCK_RAFFLE';
export const SET_WINNER_LOSER_MODAL_VISITED = 'SET_WINNER_LOSER_MODAL_VISITED';
export const TOGGLE_STOCK_RAFFLE_VISIBILITY = 'TOGGLE_STOCK_RAFFLE_VISIBILITY';
export const SET_SEEN_TOP_UPS_DATA_PRIVACY_POPUP = 'SET_SEEN_TOP_UPS_DATA_PRIVACY_POPUP';
export const SET_CLOSED_BANK_MIGRATION_POPUP = 'SET_CLOSED_BANK_MIGRATION_POPUP';

export const changeTheme = (themeName: ThemeName) => ({ type: CHANGE_THEME, themeName });

// Comma separated values eq: "sma50,rsi30,rsi10"
export const setSelectedTechIndicators = (indicators: string) => ({
  type: SET_SELECTED_TECH_INDICATORS,
  payload: { indicators },
});

export const setAcceptSavingsPlanBonusTerms = (date: string) => ({
  type: ACCEPT_SAVINGS_PLAN_BONUS_TERMS,
  payload: date,
});

export const toggleStocksSwitch = () => action(STOCKS_SWITCH, {});

export const closeStockRaffle = () => {
  return { type: CLOSE_STOCK_RAFFLE };
};
export const joinStockRaffle = () => {
  return { type: JOIN_STOCK_RAFFLE };
};

export const setWinnerLoserExperimentModalVisited = () => {
  return { type: SET_WINNER_LOSER_MODAL_VISITED };
};

export const toggleStockRaffleVisibility = () => {
  return { type: TOGGLE_STOCK_RAFFLE_VISIBILITY };
};

export const setAcceptedKansasCityChiefsRaffleTermsForWeeks = createAction<string | null>(
  'setAcceptedKansasCityChiefsRaffleTermsForWeeks',
);

export const setRejectedKansasCityChiefsRaffleTermsForWeeks = createAction<string | null>(
  'setRejectedKansasCityChiefsRaffleTermsForWeeks',
);

export const setDoNotAskForKansasCityChiefsRaffleTerms = createAction<boolean>(
  'setDoNotAskForKansasCityChiefsRaffleTerms',
);

export const TERMS_AND_CONDITIONS_CLICKED_AWAY_TIMESTAMP = 'TERMS_AND_CONDITIONS_CLICKED_AWAY_TIMESTAMP';
export const setTermsAndConditionsClickedAwayTimestamp = (reset = false) => {
  return { type: TERMS_AND_CONDITIONS_CLICKED_AWAY_TIMESTAMP, reset };
};

export const RESET_CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY_TIMESTAMP =
  'RESET_CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY_TIMESTAMP';
export const RESET_STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY_TIMESTAMP =
  'RESET_STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY_TIMESTAMP';

export const resetCryptoTermsAndConditionsClickedAwayTimestamp = () => {
  return { type: RESET_CRYPTO_TERMS_AND_CONDITIONS_CLICKED_AWAY_TIMESTAMP };
};
export const resetStocksTermsAndConditionsClickedAwayTimestamp = () => {
  return { type: RESET_STOCKS_TERMS_AND_CONDITIONS_CLICKED_AWAY_TIMESTAMP };
};

export const USER_CLOSED_EID_PHONE_POPUP = 'USER_CLOSED_EID_PHONE_POPUP';

export const userClosedEidPhonePopup = () => {
  return { type: USER_CLOSED_EID_PHONE_POPUP };
};

export const setSeenTopUpsDataPrivacyPopup = () => {
  return { type: SET_SEEN_TOP_UPS_DATA_PRIVACY_POPUP };
};

export const setClosedBankMigrationPopup = () => {
  return { type: SET_CLOSED_BANK_MIGRATION_POPUP };
};
