import { PriceEntity } from 'store/types/prices';
import { TradingRuleEntity, TradingRuleListItem } from 'types/tradingRules';
import { calculateAdaptivePrecision, crypto, fiat } from './formatting';
import I18n from 'i18next';
import { Crypto } from 'types/assets';

export const getTradingRulesListItems = (
  tradingRules: TradingRuleEntity[],
  prices: PriceEntity[],
  cryptos: Crypto[],
): TradingRuleListItem[] =>
  tradingRules.map((tradingRule: TradingRuleEntity) => getTradingRuleListItem(tradingRule, prices, cryptos));

export const getTradingRuleListItem = (
  tradingRule: TradingRuleEntity,
  prices: PriceEntity[],
  cryptos: Crypto[],
): TradingRuleListItem => {
  const priceEntity = prices.find(
    (price) => price.baseEntity.toLowerCase() === tradingRule.entity.toLowerCase(),
  );

  const cryptoInfo = cryptos.find((val) => val.code.toLowerCase() === tradingRule.entity.toLowerCase());

  const price = tradingRule.type === 'Buy' ? (priceEntity?.buyPrice ?? 0) : (priceEntity?.sellPrice ?? 0);

  const currencyPrecision = calculateAdaptivePrecision(price);

  const volume =
    tradingRule.tradingRuleCategory === 'stop' && tradingRule.type === 'Buy'
      ? tradingRule.volume / tradingRule.price
      : tradingRule.volume;

  const spendEuroValue =
    tradingRule.tradingRuleCategory === 'stop' && tradingRule.type === 'Buy'
      ? tradingRule.volume
      : volume * tradingRule.price;

  let cutoffValue = parseFloat(spendEuroValue.toFixed(2));

  if (tradingRule.type === 'Buy') {
    if (spendEuroValue > cutoffValue) {
      cutoffValue += 10 ** -2;
    }
  } else if (spendEuroValue < cutoffValue) {
    cutoffValue -= 10 ** -2;
  }

  return {
    entity: tradingRule.entity,
    tradingRuleCategory: tradingRule.tradingRuleCategory,
    type: tradingRule.type,
    displayName: tradingRule.displayName,
    price,
    tradingRulePriceFormatted: `${fiat(tradingRule.price, {
      symbol: false,
      customPrecision: currencyPrecision ?? 2,
    })} ${I18n.t('l10n.currency')}`,
    cryptoColor: cryptoInfo?.color ?? '',
    uniqueId: tradingRule.uniqueId,
    volumeFormatted: crypto(volume, cryptoInfo?.cryptoDetails?.decimalPlaces),
    validUntil: tradingRule.validUntil,
    euroValueFormatted: fiat(cutoffValue),
  };
};
