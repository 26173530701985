import { CONSENT_TYPES } from 'common/const/consent';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClosedBankMigrationPopup } from 'store/actions/settings';
import { acceptFeatureTermsAndConditions } from 'store/actions/termsAndConditions';
import { getCollectBankMigrationConsent } from 'store/selectors/settings';
import {
  getFeatureSpecificTncConsent,
  getIsFeatureSpecificTncInitiallyLoaded,
} from 'store/selectors/termsAndConditions';
import { State } from 'store/types/store';
import useConsentPopupText from './useConsentPopupText';

const BANK_MIGRATION_CONSENT_TYPE = CONSENT_TYPES.BANK_MIGRATION;

const useBankMigrationConsent = () => {
  const dispatch = useDispatch();
  const collectBankMigrationConsent = useSelector(getCollectBankMigrationConsent);
  const isInitiallyLoaded = useSelector((state: State) =>
    getIsFeatureSpecificTncInitiallyLoaded(state, BANK_MIGRATION_CONSENT_TYPE),
  );
  const bankMigrationConsent = useSelector((state: State) =>
    getFeatureSpecificTncConsent(state, BANK_MIGRATION_CONSENT_TYPE),
  );
  const popupData = useConsentPopupText(bankMigrationConsent?.popupData, 'bankMigration');

  const onClickAwayBankMigrationPopup = useCallback(
    () => dispatch(setClosedBankMigrationPopup()),
    [setClosedBankMigrationPopup],
  );

  const agreeToTerms = useCallback(
    () => dispatch(acceptFeatureTermsAndConditions(BANK_MIGRATION_CONSENT_TYPE)),
    [acceptFeatureTermsAndConditions],
  );

  return {
    agreeToTerms,
    hasAcceptedLatestTerms: bankMigrationConsent?.consentState === 'Accepted',
    isInitiallyLoaded,
    popupData,
    onClickAwayBankMigrationPopup,
    isBankMigrationPopupPersisted: collectBankMigrationConsent === 'required',
  };
};

export default useBankMigrationConsent;
