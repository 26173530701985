import vaspsSearchSlice from '.';

export const {
  clearVasps,
  getVaspsSuccess,
  getVaspsRequest,
  getVaspsFailure,
  loadNextPageRequest,
  loadNextPageSuccess,
  loadNextPageFailure,
} = vaspsSearchSlice.actions;
