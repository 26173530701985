import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import Button from 'common/components/Button';
import CheckboxInput from 'common/components/CheckboxInput';
import CheckboxTextRenderer from 'common/components/CheckboxTextRenderer';
import LayoutNarrow from 'common/components/LayoutContainer/LayourNarrow/LayoutNarrow';
import { PopupData } from 'types/termsAndConditions';
import { Headline, Title, MainText, CheckboxTextWrapper } from './styles';

const getValidationSchema = () =>
  Yup.object().shape({
    bankMigration: Yup.boolean().required().oneOf([true]),
  });

type Props = {
  hasLayoutWrapper?: boolean;
  onAccept: () => void;
  popupData: PopupData | null;
};

const TermsAndConditions = ({ popupData, onAccept, hasLayoutWrapper = true }: Props) => {
  const { t } = useTranslation();

  const Wrapper = ({ children }: { children: JSX.Element[] }) =>
    hasLayoutWrapper ? <LayoutNarrow>{children}</LayoutNarrow> : children;

  return (
    <Wrapper>
      <Headline>
        <Title> {popupData?.title ?? 'bankMigrationConsent.title'}</Title>
      </Headline>
      <MainText>{popupData?.text ?? 'bankMigrationConsent.text'}</MainText>
      <Formik
        initialValues={{ bankMigration: false }}
        validationSchema={getValidationSchema()}
        onSubmit={() => {
          // Will trigger only after accepting all checkboxes.
          if (onAccept) onAccept();
        }}
      >
        {({ isValid, handleSubmit, errors }) => (
          <Form>
            <CheckboxInput name="bankMigration" hideError testId="checkbox.bankMigration">
              <CheckboxTextWrapper error={errors.bankMigration}>
                {popupData?.checkbox ? (
                  <CheckboxTextRenderer items={popupData.checkbox} />
                ) : (
                  t('stakingFeatureTermsAndConditions.checkboxText.a')
                )}
              </CheckboxTextWrapper>
            </CheckboxInput>

            <Button
              disabled={!isValid}
              onClick={handleSubmit}
              title={t('updatedTermsAndConditions.agree')}
              isFullWidth
              size="modal"
            />
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default TermsAndConditions;
