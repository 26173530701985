import delistedAssetList from "common/const/delistedAssets";
import { cryptoColor } from "common/utils/assets";
import { useSelector } from "react-redux";
import { getPortfolioCryptoAsset } from "store/slices/portfolio/selectors";
import { State } from "store/types/store";
import { AssetClass, AssetReturnType, AssetSecurityClass } from "types/assets";

const useDelistedAsset = <T extends AssetClass>(
  code: string,
  assetClass: T,
  securityClass: AssetSecurityClass | undefined,
): AssetReturnType<T> => {
  const assetFallbackOnPortfolioAsset = useSelector((state: State) => getPortfolioCryptoAsset(state, code));
  const isDelisted = delistedAssetList.includes(code.toLowerCase());
  
  return { // TODO: fix types
    isDelisted,
    code,
    category: securityClass,
    class: assetClass,
    categories: [],
    country: null,
    isin: null,
    wkn: null,
    displayName: assetFallbackOnPortfolioAsset?.displayName,
    displayShortName: null,
    cryptoDetails: assetFallbackOnPortfolioAsset?.cryptoDetails,
    color: cryptoColor(code.toLowerCase()),
    symbol: code.toUpperCase(),
    pair: `${code.toLowerCase()}eur`
  }; 
}

export default useDelistedAsset;