import I18n from 'i18next';
import { getIntentPatchEpic, getIntentPostEpic } from 'store/epics/moneyTransfer';
import {
  intentPatchFailure,
  intentPatchRequest,
  intentPatchSuccess,
  intentPostFailure,
  intentPostRequest,
  intentPostSuccess,
} from 'store/slices/cryptoDepositTravelRule/actions';
import getSatoshiTestEpic from 'store/epics/satoshiTest';
import { combineEpics } from 'redux-observable';
import { DEPOSIT_INTENT_ROUTE, IntentType } from 'common/const/moneyTransfer';

const cryptoDepositTravelRuleIntentPostEpic = getIntentPostEpic({
  requestRoute: DEPOSIT_INTENT_ROUTE,
  actions: {
    request: intentPostRequest,
    success: intentPostSuccess,
    failure: intentPostFailure,
  },
  errorMessage: I18n.t('error.genericNetwork'),
});

const cryptoDepositTravelRuleIntentPatchEpic = getIntentPatchEpic({
  requestRoute: DEPOSIT_INTENT_ROUTE,
  actions: {
    request: intentPatchRequest,
    success: intentPatchSuccess,
    failure: intentPatchFailure,
  },
  errorMessage: I18n.t('error.genericNetwork'),
});

const cryptoDepositTravelRuleSatoshiTestEpic = getSatoshiTestEpic({
  requestRoute: `${DEPOSIT_INTENT_ROUTE}/satoshi-test`,
  errorMessage: I18n.t('error.genericNetwork'),
  intentType: IntentType.DEPOSIT,
});

export default combineEpics(
  cryptoDepositTravelRuleIntentPostEpic,
  cryptoDepositTravelRuleIntentPatchEpic,
  cryptoDepositTravelRuleSatoshiTestEpic,
);
