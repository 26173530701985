import cryptoWithdrawalSlice from '.';

export const {
  initCryptoWithdrawFailure,
  initCryptoWithdrawSuccess,
  initCryptoWithdrawRequest,
  submitCryptoWithdrawFailure,
  submitCryptoWithdrawRequest,
  submitCryptoWithdrawSuccess,
  startCryptoWithdrawSession,
  resendCryptoWithdrawConfirmationEmail,
  resendTwoFaCode,
  endCryptoWithdrawSession,
  intentPatchRequest,
  intentPatchFailure,
  intentPatchSuccess,
  intentPostFailure,
  intentPostRequest,
  intentPostSuccess,
  setSelectedVaspId,
  setIsSelfHosted,
  resetVaspId,
} = cryptoWithdrawalSlice.actions;
