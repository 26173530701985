import React, { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import ModalTemplate from 'common/components/Modal/index';
import Button from 'common/components/Button';
import { StopOrderCreationContext } from 'scenes/StopOrderCreation/StopOrderCreationContext';
import {
  ActionsWrapper,
  ConfirmationHeader,
  ConfirmFooterWrapper,
} from 'common/components/TradingRulesCommonComponents/TradingRulesCreationStyles/confirmationStyles';
import StopOrderSuccessModal from 'common/components/Modal/StopOrderModals/StopOrderSuccessModal';
import StopOrderConfirm from 'common/components/StopOrderForm/StopOrderConfirm/StopOrderConfirm';
import * as formatting from 'common/utils/formatting';
import { daysRemaining } from 'common/utils/formatting';
import { getSelectedCryptoCurrency } from 'store/selectors/currency';
import { State } from 'store/types/store';
import ReactMarkdown from 'react-markdown';
import StopOrderFailModal from 'common/components/Modal/StopOrderModals/StopOrderFailModal';
import { paths } from 'common/urls';
import { useAsset } from 'common/hooks/useAsset';
import { getCryptoPrecision } from 'store/slices/assets/selectors';
import { WithRouterType } from 'types/withRouter';
import withRouter from 'common/hoc/WithRouter';
import { addDays } from 'common/utils/date';
import { format } from 'date-fns';
import { ASSET_CLASSES } from 'types/assets';
import { createTradingRule } from 'store/slices/tradingRules/actions';
import { isTradingRuleCreateInProgress } from 'store/slices/tradingRules/selectors';

type Props = {
  isConfirm: boolean;
  stopPrice: number;
  cryptoAmount: number;
  euroAmount: number;
  isBuy: boolean;
  selectedCrypto: string;
  currencyPrecision: number;
  cryptoPrecision: number | undefined;
};

const StopOrderConfirmModal = ({
  isConfirm,
  stopPrice,
  cryptoAmount,
  euroAmount,
  isBuy,
  selectedCrypto,
  currencyPrecision,
  navigate,
  cryptoPrecision,
}: Props & WithRouterType): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cryptoInfo = useAsset(selectedCrypto, ASSET_CLASSES.CRYPTO, undefined);

  const [isSuccessModalShowed, setIsSuccessModalShowed] = useState(false);
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [isErrorGeneric, setIsErrorGeneric] = useState(true);

  const isLoading = useSelector(isTradingRuleCreateInProgress);

  const { setIsConfirm } = useContext(StopOrderCreationContext);

  const price = `${formatting.fiat(stopPrice, {
    symbol: true,
    customPrecision: currencyPrecision,
  })}`;

  const amount = `${formatting.crypto(cryptoAmount, cryptoPrecision)} ${selectedCrypto.toUpperCase()}`;

  const formattedEuroAmount = `${formatting.fiat(euroAmount, { symbol: true })}`;
  const orderType = isBuy ? 'Buy' : 'Sell';

  const sendSubmit = () => {
    dispatch(
      createTradingRule({
        category: 'Stop',
        model: {
          currency: 'eur',
          orderType,
          entity: selectedCrypto,
          volume: isBuy ? euroAmount : cryptoAmount,
          price: stopPrice,
        },
        onSuccess: () => {
          setIsSuccessModalShowed(true);
          setIsConfirm(false);
        },
        onFail: () => {
          setIsWarning(true);
          setIsConfirm(false);
          setIsErrorGeneric(false);
        },
        onError: () => {
          setIsWarning(true);
          setIsConfirm(false);
        },
      }),
    );
  };

  const translationText = `stopOrders.stopOrderDescription.${orderType.toLowerCase()}`;
  const date = new Date();
  const validUntil = addDays(date, 90);

  return (
    <>
      <ModalTemplate
        defaultOpen={isConfirm}
        onCancel={() => setIsConfirm(false)}
        actions={
          <ConfirmFooterWrapper>
            <ActionsWrapper>
              <Button
                title={t('common.cancel')}
                size="large"
                inverted
                onClick={() => {
                  setIsConfirm(false);
                }}
              />
              <Button
                title={t(`stopOrders.confirmStopOrderBtnLabel.${orderType.toLowerCase()}`)}
                size="large"
                onClick={() => sendSubmit()}
                inProgress={isLoading}
              />
            </ActionsWrapper>
          </ConfirmFooterWrapper>
        }
        header={
          <ConfirmationHeader>
            {t(`stopOrders.confirmationTitle.${orderType.toLocaleLowerCase()}`, {
              assetName: cryptoInfo?.displayName,
            })}
          </ConfirmationHeader>
        }
      >
        <StopOrderConfirm
          stopPrice={stopPrice}
          cryptoAmount={cryptoAmount}
          euroAmount={euroAmount}
          validUntil={validUntil}
          isBuy={isBuy}
          isWarning={isWarning}
          setIsWarning={setIsWarning}
          currencyPrecision={currencyPrecision}
        />
      </ModalTemplate>
      <StopOrderSuccessModal
        infoText={
          <ReactMarkdown renderers={{ root: React.Fragment, paragraph: React.Fragment }}>
            {`${t(`${translationText}.a`)}
          **${price}**
          ${t(`${translationText}.b`)}
          **${amount}**
          ${t(`${translationText}.c`)}
          **${formattedEuroAmount}**
          ${t(`${translationText}.d`, {
            date: `${format(date, 'dd.MM.yyyy')} ${t('stopOrders.detail.validInDays', {
              days: daysRemaining(addDays(date, 89)),
            })}`,
          })}
          `}
          </ReactMarkdown>
        }
        headerText={t(`stopOrders.successTitle.${orderType.toLowerCase()}`)}
        isOpen={isSuccessModalShowed}
        isBuy={isBuy}
      />
      <StopOrderFailModal
        isOpen={isWarning}
        actions={
          <Button
            title={t('stopOrders.tradingManager')}
            onClick={() => navigate(paths.ORDERS)}
            size="modal"
          />
        }
        infoText={
          isErrorGeneric
            ? t('stopOrders.errors.generic')
            : t('stopOrders.errors.limitReached', {
                type: orderType,
                assetName: cryptoInfo?.displayName,
                stopPrice: formattedEuroAmount,
              })
        }
        headerText={t(`stopOrders.failTitle.${orderType.toLowerCase()}`)}
      />

      {/*  onClose={() => setIsWarning(false)}  */}
    </>
  );
};

const mapStateToProps = (state: State, ownProps: WithRouterType) => {
  const selectedCrypto = getSelectedCryptoCurrency(state, ownProps.params);
  return {
    selectedCrypto,
    cryptoPrecision: getCryptoPrecision(state, selectedCrypto),
  };
};

export default withRouter(connect(mapStateToProps)(StopOrderConfirmModal));
